import React from 'react'
import ReactDOM from 'react-dom/client'
import 'dayjs/locale/es'
import dayjs from 'dayjs'
import './index.css'
import "./App.css"
import {Route,Routes,BrowserRouter} from "react-router-dom";
import SecurityProvider from './Context/Security';
import Login from './Pages/Login';
import Main from './Pages/Main';
import TamizajeRegistro from "./Pages/Coordinador/TamizajeRegistro"
import Pacientes from "./Pages/Coordinador/Pacientes"
import TamizajeList from "./Pages/Coordinador/Tamizaje/List"
import Sedes from "./Pages/Coordinador/Sedes/List"


/*
import CasosTratamiento from "./Pages/CasosTratamiento"
import Configuracion from "./Pages/Configuracion"
import HistAtenciones from "./Pages/HistAtenciones"
import Intervenciones from "./Pages/Coordinador/Intervenciones"
import CasosTratCoordinador from "./Pages/Coordinador/CasosTratCoordinador"
import ConfigTecnico from "./Pages/Tecnico/ConfigTecnico"
import HistAtenTecnico from "./Pages/Tecnico/HistAtenTecnico"
import TamizajeTecnico from "./Pages/Tecnico/TamizajeTecnico"
import ClinicaIntervenciones from "./Pages/Clinica/ClinicaIntervenciones"
import ClinicaPacientes from "./Pages/Clinica/ClinicaPacientes"
import ClinicaRegisTamj from "./Pages/Clinica/ClinicaRegisTamj"
import ClinicaSedes from "./Pages/Clinica/ClinicaSedes"
import ClinicaTamizaje from "./Pages/Clinica/ClinicaTamizaje"
import ClinicaUsuarios from "./Pages/Clinica/ClinicaUsuarios"*/

/*import ClinicaCasosTrat from "./Pages/Clinica/ClinicaCasosTrat"
import Equipo from "./Pages/Clinica/Equipo"
import DetailsTratamiento from './Pages/CasosTratamiento/DetailsTratamiento';
import DetailsAtenciones from './Pages/HistAtenciones/DetailsAtenciones';
import ControlPaciente from './Pages/HistAtenciones/ControlPaciente';*/


/*
import DetailsTecnico from './Pages/Tecnico/TamizajeDetail/index';
import DetailsHisTecnico from "./Pages/Tecnico/HistAtenTecnico/DetailsHisTecnico"
import ControlPacTecnico from './Pages/Tecnico/HistAtenTecnico/ControlPacTecnico';
import DoctorTamizajeList from './Pages/Doctor/Tamizaje/List'
import DoctorTamizajeDetail from './Pages/Doctor/Tamizaje/Detail'
import { CrearSede } from './Pages/Coordinador/Sedes/CrearSede'
import { ActualizarSede } from './Pages/Coordinador/Sedes/ActualizarSede'
import ControlPacCoordinador from './Pages/Coordinador/Pacientes/ControlPacCoordinador';
import DetailsCasosTratCoord from './Pages/Coordinador/CasosTratCoordinador/DetailsCasosTratCoord';
import DetailsCasosTratClinica from './Pages/Clinica/ClinicaCasosTrat/DetailsCasosTratClinica';
import DetailsClinicaPacientes from './Pages/Clinica/ClinicaPacientes/DetailsClinicaPacientes';
import ControlPacClinica from './Pages/Clinica/ClinicaPacientes/ControlPacClinica';
import DetailsClinica from './Pages/Clinica/ClinicaTamizaje/DetailsClinica';
import AgregarPacienteClinica from './Pages/Clinica/ClinicaPacientes/AgregarPacienteClinica';
import ActualizarEquipo from "./Pages/Clinica/ActualizarEquipo"
*/

/*

                  <Route path='' index element={<CasosTratamiento/>}/>
                  <Route path='tamizaje' index element={<TamizajeList/>}/>
                  <Route path='Main' index element={<Main/>}/>
                  <Route path='CasosTratamiento' index element={<CasosTratamiento/>}/>
                  <Route path='Configuracion' index element={<Configuracion/>}/>
                  <Route path='HistAtenciones' index element={<HistAtenciones/>}/>
                
                  
                
                  <Route path='Intervenciones' index element={<Intervenciones/>}/>
                  
                  <Route path='CasosTratCoordinador' index element={<CasosTratCoordinador/>}/>
                  <Route path='ConfigTecnico' index element={<ConfigTecnico/>}/>
                  <Route path='HistAtenTecnico' index element={<HistAtenTecnico/>}/>
                  
                  <Route path='/technical/tamizajes' index element={<TamizajeList/>}/>
                  
                  <Route path='ClinicaIntervenciones' index element={<ClinicaIntervenciones/>}/>
                  <Route path='ClinicaPacientes' index element={<ClinicaPacientes/>}/>
                  <Route path='ClinicaRegisTamj' index element={<ClinicaRegisTamj/>}/>
                  <Route path='ClinicaSedes' index element={<ClinicaSedes/>}/>
                  <Route path='ClinicaTamizaje' index element={<ClinicaTamizaje/>}/>
          
                  <Route path='ClinicaCasosTrat' index element={<ClinicaCasosTrat/>}/>
                  <Route path='Equipo' index element={<Equipo/>}/>
                  <Route path='DetailsTratamiento' element={<DetailsTratamiento/>}/>
                  <Route path='DetailsAtenciones' element={<DetailsAtenciones/>}/>
                  <Route path='ControlPaciente' element={<ControlPaciente/>}/>

                  <Route path='technical/tamizaje/:id/detail' element={<DetailsTecnico/>}/>
                  <Route path='doctor/tamizaje' element={<TamizajeList/>}/>
                  <Route path='doctor/tamizaje/:id/detail' element={<DoctorTamizajeDetail/>}/>

                  <Route path='DetailsHisTecnico' element={<DetailsHisTecnico/>}/>
                  <Route path='ControlPacTecnico' element={<ControlPacTecnico/>}/>
                  <Route path='ControlPacCoordinador/:id' element={<ControlPacCoordinador/>}/>
                  <Route path='DetailsCasosTratCoord' element={<DetailsCasosTratCoord/>}/>
                  <Route path='DetailsCasosTratClinica' element={<DetailsCasosTratClinica/>}/>
                  <Route path='DetailsClinicaPacientes' element={<DetailsClinicaPacientes/>}/>
                  <Route path='ControlPacClinica' element={<ControlPacClinica/>}/>
                  <Route path='DetailsClinica' element={<DetailsClinica/>}/>
                    <Route path='AgregarPacienteClinica' element={<AgregarPacienteClinica/>}/>

                    <Route path='ActualizarEquipo' element={<ActualizarEquipo/>}/>
                  <Route path='/coordinador/crear-sede/' element={<CrearSede/>}/>
                  <Route path='/coordinador/actualizar-sede/' element={<ActualizarSede/>}/>
                  */

import DetailsPacientes from './Pages/Coordinador/Pacientes/DetailsPacientes';
import DetailsCoordinador from './Pages/Coordinador/Tamizaje/Detail';
import AgregarPacienteCoord from './Pages/Coordinador/Pacientes/AgregarPacienteCoord';
import Clinicas from "./Pages/Admin/Clinicas"
import AgregarClinica from "./Pages/Admin/AgregarClinica"
import ActualizarClinica from "./Pages/Admin/ActualizarClinica"
import { TablasMaestrasProvider } from './Context/TablasMaestras';
import TamizajeProvider from './Context/Tamizaje';
import ClinicUsers from './Pages/Admin/User/List'
import ReactModal from "react-modal"
import TeamList from './Pages/Coordinador/Team/List'
import CrearUsuario from './Pages/Coordinador/Team/Save'
import Ui from './Context/Ui'
import Agenda from './Pages/Agenda'
import Rop from './Pages/Admin/Rop/List'
import Risk from './Pages/Admin/Risk/List'
import MedicalPlanList from './Pages/Admin/MedicalPlan/List'
import ModalTamizaje from './Components/ModalTamizaje'
import ChangePassword from 'Pages/ChangePassword'
import Dashboard from 'Pages/Dashboard'
import Roles from 'Pages/Admin/Roles/List'
import RoleSave from 'Pages/Admin/Roles/Save'
import Country from './Pages/Admin/Country'

ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(255,255,255,.4)';

dayjs.locale('es')

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Ui>
        <SecurityProvider>
          <TamizajeProvider>
            <TablasMaestrasProvider>
              <Routes>
                <Route path='login' element={<Login/>}/>
                <Route path='/' element={<Main/>}>
                  <Route path='/dashboard' element={<Dashboard/>}/>
                  <Route path='/admin/regional-settings' element={<Country/>}/>
                  <Route path='/admin/patient' element={<Pacientes/>}/>
                  <Route path='/admin/patient/:id/detail' element={<DetailsPacientes/>}/>
                  <Route path='/admin/clinics' element={<Clinicas/>}/>
                  <Route path='/admin/clinics/add' element={<AgregarClinica/>}/>
                  <Route path='/admin/clinics/:id/edit' element={<AgregarClinica/>}/>
                  <Route path='/admin/clinics/:id/users' element={<ClinicUsers/>}/>
                  <Route path='/admin/rop' element={<Rop/>}/>
                  <Route path='/admin/risk' element={<Risk/>}/>
                  <Route path='/admin/medical-plan' element={<MedicalPlanList/>}/>
                  <Route path='/admin/roles' element={<Roles/>} />
                  <Route path='/admin/roles/:id' element={<RoleSave/>} />
                  <Route path='/admin/roles/create' element={<RoleSave/>} />
                  <Route path='/admin/tamizajes/:id/detail' element={<DetailsCoordinador/>}/>
                  <Route path='/admin/patient/:idpaciente/tamizajes/:id/detail' element={<DetailsCoordinador/>}/>

                  <Route path='/manager/tamizaje/create' index element={<TamizajeRegistro/>}/>
                  <Route path='/manager/team' index element={<TeamList/>}/>
                  <Route path='/manager/team/:id/update' element={<CrearUsuario/>}/>
                  <Route path='/manager/team/create' element={<CrearUsuario/>}/>
                  <Route path='/manager/tamizajes/:id/detail' element={<DetailsCoordinador/>}/>
                  <Route path='/manager/patient/:idpaciente/tamizajes/:id/detail' element={<DetailsCoordinador/>}/>
                  <Route path='/manager/tamizajes' element={<TamizajeList/>}/>
                  <Route path='/manager/patient' index element={<Pacientes/>}/>
                  <Route path='/manager/patient/:id/detail' element={<DetailsPacientes/>}/>
                  <Route path='/manager/patient/create' element={<AgregarPacienteCoord/>}/>
                  <Route path='/manager/patient/:id/update' element={<AgregarPacienteCoord/>}/>
                  <Route path='/manager/agenda' element={<Agenda/>}/>
                  <Route path='/manager/sites' index element={<Sedes/>}/>

                  <Route path='/change-password' element={<ChangePassword/>}/>
                </Route>
                <Route path='/tamizajes/:id/detail' element={<ModalTamizaje/>}/>
              </Routes>
            </TablasMaestrasProvider>
          </TamizajeProvider>
        </SecurityProvider>
      </Ui>
    </BrowserRouter>
  </React.StrictMode>
)
