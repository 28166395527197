import React, { useContext, useEffect } from "react";
import Pagination from "Components/Pagination";
import { AiFillCalendar } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { Link, useAsyncError, useNavigate } from "react-router-dom";
import { API } from "API";
import { useState } from "react";
import { SecurityCtx } from "Context/Security";
import { TamizajeCtx } from "Context/Tamizaje";
import dayjs from "dayjs";
import { UiCtx } from "Context/Ui";


const Export=()=>{

    const {showAlert} = useContext(UiCtx)
    const {params } =useContext(TamizajeCtx);

    const _export = async () => {
        try {
          const {patient,tecnico,medico,...f}=params;
    
          const {data} = await API.get('reporte_csv/',{params:f,responseType:"blob"})
    
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download","reporte.csv")
            link.click();
          
        } catch (error) {
          showAlert({icon:'error','title':'Error al exportar',html:error?.response?.data?.detail || error.toString()});
        }
    }

    return <div className="p-[4px] px-2 w-full block text-center bg-teal-500 rounded-md cursor-pointer hover:bg-teal-500/90" onClick={_export}>Exportar</div>
}

export default Export