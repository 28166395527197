import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../../API"
import { SecurityCtx } from "../Security"

export const TablasMaestrasContext = createContext(null);

export const TablasMaestrasProvider=({children})=>{
  
  const {user} = useContext(SecurityCtx)
  const [especialidades,setEspecialidades] = useState([]);
  const [grupos,setGrupos] = useState([]);


    const getEspecialidad = async () => {
    try {
      const response = await API.get(`/configuracion/especialidad/`);
      setEspecialidades(response.data);
    } catch (error) {

    };
    }

   

    const getGrupos = async () => {
    try {
      const response = await API.get("/configuracion/grupos/");
      setGrupos(response.data);
    } catch (error) {
    };
    }


 

    useEffect(()=>{
        if(user){
          getGrupos()
          getEspecialidad()
        }
    },[user])


  
    
    return (
        <TablasMaestrasContext.Provider 
        value={{
            especialidades,
            grupos
            }}>
            {children}
        </TablasMaestrasContext.Provider>
    )
}
