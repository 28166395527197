import Loading from "Components/Loading";
import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { API } from "../../API";
import { BASE_URL, GROUP_ADMIN, GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN, STATES_BY_ROLE, TOKEN } from "../../Contants";

export const SecurityCtx = createContext(null);

const SecurityProvider = ({ children }) => {
  const [loading_session, setLoadingSession] = useState(true);
  const [user, setUser] = useState(null);
  const [permissions,setPermissions]=useState([])
  const location = useLocation();
  const navigation = useNavigate()
  const redirect_to_login = !user && location.pathname != "/login";
  const redirect_to_home = (user && (location.pathname == "/login" || location.pathname=='/') ) || (user && user?.actualizar_clave && location.pathname!='/change-password');

  const [page_customer,setPageCustomer]=useState({
    page:1,
    query:''
  })
  const [page_patient,setPagePatient]=useState({
    page:1,
    historia_clinica:{value:'',label:''},
    cliente_id:'',
    nombre:'',fecha_desde:'',fecha_hasta:'',clasificacion_riesgo:[]
  })
  const [page_clinic_user,setPageClinicUser]=useState({
    page:1,
    clinica_id:'',
    nombre:''
  })


  const initUser = async (token,data) => {
    try {
      
        /*setUser({ 
        id:data.id,
        username:data.username,
        correoElectronico:data.correoElectronico,
        fullname:data.fullname,
        grupo:data.grupo,
        grupo_id:data.grupo_id
    });*/
    } catch (error) {
      console.log(error);
    }
  };

  const storeToken=(token)=>{
    localStorage.setItem(TOKEN, token);
    init()
  }

  const hasRole=(role)=>{
    if(!user){
      return false
    }
    if(!user?.groups){
      return false
    }

    if(typeof role=='object'){
      let valid=false

      role.forEach(r=>{
        const v = user.groups.some(g=>g.codigo==""+r)
        if(v){
          valid=true
        }
      })

      return valid
    }

    return user.groups.some(g=>g.codigo==""+role)
  }

  const getTamizajeStates=()=>{
    if(!user){
      return []
    }
    if(hasRole(GROUP_CLINIC)){
      return STATES_BY_ROLE[GROUP_CLINIC]
    }
    if(hasRole(GROUP_DOCTOR)){
      return STATES_BY_ROLE[GROUP_DOCTOR]
    }
    if(hasRole(GROUP_TECHNICIAN)){
      return STATES_BY_ROLE[GROUP_TECHNICIAN]
    }
    if(hasRole(GROUP_MANAGER)){
      return STATES_BY_ROLE[GROUP_MANAGER]
    }
    return STATES_BY_ROLE['100']
  }

  const reset=()=>{
    setPageClinicUser({
      page:1,
      clinica_id:'',
      nombre:''
    })

    setPagePatient({
      page:1,
      historia_clinica:'',
      nombre:''
    })
    setPageCustomer({
      page:1,
      query:''
    })
  }

  const logout = () => {
    reset()
    localStorage.removeItem(TOKEN);
    setUser(null);
  };

  const loadUserInfo=async ()=>{
    try{
      
      setLoadingSession(true);
      const {data} = await API.get('/cliente/usuario/detalle/')
      setUser(data.detail)
      
      setLoadingSession(false);
    }catch(error){
      localStorage.removeItem(TOKEN)
      setUser(null)
      setLoadingSession(false);

    }
  }

  const init=()=>{
    reset()
    const token = localStorage.getItem(TOKEN);
    if(token){
      loadUserInfo()
      return
    }
    setLoadingSession(false);
  }

  useEffect(()=>{
    init()
  },[])

  useEffect(()=>{
    const _permissions=[]
    if(user){
      user.groups.forEach(g=>{
        g.permissions.forEach(p=>{
          _permissions.push(p.codename)
        })
      })

      user.user_permissions.forEach(p=>{
        _permissions.push(p.codename)
      })
    }
    setPermissions(_permissions)
  },[user])


 

  if (loading_session) {
    return <Loading message="Cargando información del usuario"/>;
  }

  let home=''
  /*if(hasRole(GROUP_TECHNICIAN)){
    home='/technical/tamizajes'
  }
  if(hasRole(GROUP_DOCTOR)){
    home='doctor/tamizaje'
  }
  if(hasRole(GROUP_MANAGER)){
    home='manager/tamizajes'
  }
  if(hasRole(GROUP_ADMIN)){
    home='/admin/clinics'
  }
  if(hasRole(GROUP_CLINIC)){
    home="/manager/team"
  }*/
  
  if(user && user.actualizar_clave){
    home='/change-password'
  }
  if(home==''){
    home='/dashboard'
  }

  
  
  return (
    <SecurityCtx.Provider value={{permissions,hasRole,setUser,user, getTamizajeStates,initUser, logout,storeToken,loadUserInfo,page_customer,setPageCustomer,page_patient,setPagePatient,page_clinic_user,setPageClinicUser }}>
      {redirect_to_login && <Navigate to="/login" replace />}
      {redirect_to_home && <Navigate to={home} replace />}
      {children}
    </SecurityCtx.Provider>
  );
};

export default SecurityProvider;
