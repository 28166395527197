import dayjs from "dayjs"
import { useState } from "react"
import { useEffect } from "react"
import { API } from "API"
import EyeChoice from "Components/EyeChoice"
import TipoPlanSelect from "Components/TipoPlanSelect"
import Policy from "Components/Policy"
import { PERMISO_ELIMINAR_PLAN } from "Contants"

const Plan=({dado_de_alta,permissions,id_impresion,read_only=false,index,id,observacion,ojo,fecha,cantidad,fk_tipo,setPlanes})=>{

    const [loading,setLoading]=useState(false)

    const removeConfirmed=()=>{
        setPlanes(prev=>{
            const temp=[...prev]
            temp.splice(index,1)
            return temp
        })
    }

    const remove=async ()=>{
        if(id){
            if(window.confirm("Se borrara el plan")){
                setLoading(true)
                try{
                    await API.delete('gestion_medico/impresion/borrar-plan',{data:{id_plan:parseInt(id)}})
                    removeConfirmed()
                }catch(error){

                }finally{
                    setLoading(false)
                }
            }
        }else{
            removeConfirmed()
        }
    }
    

    const setProp=(prop,value)=>{
        setPlanes(prev=>prev.map((h,i)=>{
            if(i==index){
                return {...h,[prop]:value}
            }
            return h
        }))
    }

    const setObs=(value)=>{
        setProp('observacion',value)
    }
    const setTipo=(value)=>{
        setProp('fk_tipo',value)
    }
    const setEye=(value)=>{
        setProp('ojo',value)
    }
    const setFecha=(value)=>{
        setProp('fecha',value)
    }
  
    const setCantidad=(value)=>{
        setProp('cantidad',value)
    }

    const puede_eliminar=!dado_de_alta && (!id_impresion || (id_impresion && permissions.includes(PERMISO_ELIMINAR_PLAN)))
    

    if(loading){
        return <h1>Cargando...</h1>
    }
   

    return <div className="mt-[32px]">
        <div className="flex mb-2 lg:flex-row sm:flex-col sm:mb-4">
            <div className="mr-2 flex-1 sm:mb-4">
                <EyeChoice read_only={read_only} label="" initial_value={ojo} onChange={setEye}/>
            </div>
            <div className="flex-1">
                <TipoPlanSelect register={{disabled:read_only}} show_label={false} initial_value={fk_tipo} onChange={setTipo}/>
            </div>
        </div>
        <div className="flex lg:flex-row sm:flex-col sm:mb-4">
            <div className="flex-1 lg:mr-2 sm:mb-4">
                <p className="hidden print:block text-slate-700">Fecha {dayjs(fecha).format('YYYY/MM/DD H:mm')}</p>
                <input type="datetime-local" disabled={read_only} value={fecha} onChange={(e)=>setFecha(e.target.value)}   className="print:hidden w-full px-2 rounded-md bg-slate-500/60"/>
            </div>
            <div className="flex-1">
                <p className="hidden print:block text-slate-700">Cant. {cantidad}</p>
                <input type="number" disabled={read_only} placeholder="Cantidad" value={cantidad} onChange={(e)=>setCantidad(e.target.value)} className="print:hidden w-full px-2 rounded-md bg-slate-500/60"/>
            </div>
        </div>
        <div className="flex-1 items-stretch">
            <p className="hidden print:block text-slate-700">Observaciones: {observacion}</p>
                <textarea disabled={read_only} className="print:hidden w-full h-full bg-slate-500/30 text-sm rounded-md p-2" placeholder="Observaciones" value={observacion} onChange={(e)=>setObs(e.target.value)} ></textarea>
            
                    {puede_eliminar && <div className="flex justify-end">
                        <button onClick={remove} className="flex items-center hover:bg-slate-500/30 rounded-full p-[4px]">
                            <span className="bg-slate-100 text-slate-500 rounded-[50%] h-[16px] w-[16px] flex items-center justify-center mr-[2px]">
                                -
                            </span>
                            <span className="text-sm">Eliminar</span>
                        </button>
                    </div>}
                
            </div>
    </div>
}

export default Plan