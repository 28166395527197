import { TAMIZAJE_PRIORIDAD } from "Contants"

const TamizajePrioridad=({children})=>{
    const t = TAMIZAJE_PRIORIDAD.find(t=>t.value==children)
    if(!t){
        return "Sin definir"
    }

    return <span title={t.label} className={`${t.bg} shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] w-[32px] h-[32px] flex items-center justify-center rounded-full capitalize`}>{t.label.slice(0,1)}</span>
}

export default TamizajePrioridad