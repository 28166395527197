import SelectClinic from "Components/SelectClinic";
import { GROUP_ADMIN } from "Contants";
import { SecurityCtx } from "Context/Security";
import { useContext } from "react";
import { useForm,Controller } from "react-hook-form";

const Filters=({onFilter})=>{
    const {hasRole} = useContext(SecurityCtx)
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
      } = useForm();

    const filter=(data)=>{
      const {clinica_id,...rest} = data

      onFilter({...rest,cliente:clinica_id?.value})
    }

    return   <form className="flex-1 border-salte-400/20" onSubmit={handleSubmit(filter)}>
                <div className="flex lg:flex-row md:flex-col">
                    {hasRole(GROUP_ADMIN) && <div className="flex-1 lg:mr-2 md:mb-4">
                      <label className="text-md mb-2">Cliente</label>
                      <Controller name="clinica_id" control={control} render={({field})=>(<SelectClinic isClearable={true} register={field} placeholder="Buscar cliente" />)}/>
                     
                    </div>}
                    <div className="flex-1 lg:mr-2 md:mb-4">
                      <label className="text-md mb-2">Nombre</label>
                      <input type="text"
                      className='rounded-md bg-slate-400/40 py-[5px] px-2 w-full'
                      {...register("nombre")}
                      />
                    </div>
                    
                </div>
                  
                <button
                    type='submit'
                    className="bg-transparent hover:bg-[#2f3855] text-slate-300 font-semibold hover:text-white py-2 px-4 border border-slate-300 hover:border-transparent rounded"
                    
                    >
                    Buscar
                    </button>

                    
  
  </form>
}

export default Filters