import Modal from "react-modal"
import Card from "Components/Card"
import Input from "Components/Input"
import {ImSpinner9} from "react-icons/im"
import {MdClose} from "react-icons/md"
import { useContext, useEffect, useState } from "react"
import { API } from "../../API"
import { UiCtx } from "../../Context/Ui"


const PasswordRecovery=({visible,setVisible})=>{
    const {showAlert} = useContext(UiCtx)
    const [email,setEmail] = useState('')
    const [token,setToken] = useState('')
    const [loading,setLoading] = useState(false)
    const [step,setStep]=useState(1)
    const [password,setPassword] = useState('')
    const [password2,setPassword2] = useState('')

    const onChange=(e)=>{
        setEmail(e.target.value)
    }
    const onChange2=(e)=>{
        setToken(e.target.value)
    }
    const onChange3=(e)=>{
        setPassword(e.target.value)
    }
    const onChange4=(e)=>{
        setPassword2(e.target.value)
    }
    const reset=()=>{
        setStep(1)
        setVisible(false)
        setPassword('')
        setPassword2('')
        setLoading(false)
        setToken('')
        setEmail('')
    }

    const requestToken=async ()=>{
        try{
            setLoading(true)
            const {data} = await API.post('clinica/usuario/envio_token/',{email})
            showAlert({
                icon:'success',
                html:data.detail
            })
            setStep(2)
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    const validate= async ()=>{
        try{
            setLoading(true)
            const {data} = await API.post('clinica/usuario/validacion_token/',{token})
            showAlert({
                icon:'success',
                html:data.detail
            })
            setStep(3)
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    const update=async ()=>{
       
        try{
            if(password=='' || password2==''){
                throw('Ingrese su nueva contraseña')
            }
            if(password!=password2){
                throw('Las contraseña no coinciden')
            }
            setLoading(true)
            const {data} = await API.post('clinica/usuario/cambio_contrasena/',{password,password2,token})
            showAlert({
                icon:'success',
                html:data.detail
            })
            reset()

            showAlert({
                icon:'success',
                html:data.detail
            })
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    const renderStep=()=>{
        if(step==1){
            return (<>
            <Input desactivar={loading} value={email} type='email' register={{onChange,name:'email',autocomplete:'off',key:1}} descripcion="Correo electrónico"/>
            <button type="button" onClick={requestToken} disabled={loading} className="bg-slate-400/40 hover:bg-slate-300/40 p-2 rounded-md flex items-center w-full justify-center my-4">Solicitar código de restauración {loading && <ImSpinner9 className="ml-2 animate-spin"/>}</button>
            </>)
        }
        if(step==2){
            return (<>
                <Input desactivar={loading} value={token} register={{onChange:onChange2,name:'codigo',autocomplete:'off',key:2}} descripcion="Código" placeholder="Escriba el código recibido" />
                <button type="button" onClick={validate} disabled={loading} className="bg-slate-400/40 hover:bg-slate-300/40 p-2 rounded-md flex items-center w-full justify-center my-4">Validar código {loading && <ImSpinner9 className="ml-2 animate-spin"/>}</button>
            </>)
        }
        if(step==3){
            return (<>
                <Input desactivar={loading} value={password} register={{onChange:onChange3,name:'pass',autocomplete:'off',key:3}} type="password" descripcion="Contraseña" placeholder="" />
                <Input desactivar={loading} value={password2} register={{onChange:onChange4,name:'pass2',autocomplete:'off'}} type="password" descripcion="Confirmar contraseña" placeholder="" />
                <button type="button" onClick={update} disabled={loading} className="bg-slate-400/40 hover:bg-slate-300/40 p-2 rounded-md flex items-center w-full justify-center my-4">Cambiar contraseña {loading && <ImSpinner9 className="ml-2 animate-spin"/>}</button>
            </>)
        }
    }

    useEffect(()=>{
        if(!visible){
            reset()
        }
    },[visible])

    return <Modal isOpen={visible}  style={{content:{background:'transparent',border:"none",overflow:'hidden'}}}>
         <div className='sm:max-w-[60%] md:max-w-[60%] lg:max-w-[30%] m-auto mt-40'>
            <Card content_style="">
                <form autocomplete="off">
                    <div className='flex justify-between mb-6'>
                        <h2 className='text-lg'>Recuperar contraseña</h2>
                        <button type="button" onClick={()=>!loading && setVisible(false)}  className="rounded-full hover:bg-slate-400/40 p-2">
                        <MdClose />
                        </button>
                    </div>
                    {renderStep()}
                </form>

            </Card>
        </div>
    </Modal>
}

export default PasswordRecovery