import React, { createRef, useEffect, useRef, useState } from 'react'
import Input from "../../../Components/Input"
import { useForm,Controller  } from 'react-hook-form'
import { API } from '../../../API'
import SelectSedes from '../../../Components/SelectSedes'
import { GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN, REACT_SELECT_STYLES, TAMIZAJE_PRIORIDAD } from '../../../Contants'
import { SecurityCtx } from '../../../Context/Security'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext } from 'react'
import FormError from '../../../Components/FormError'
import { TamizajeCtx } from '../../../Context/Tamizaje'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { UiCtx } from '../../../Context/Ui'
import Agenda from '../../Agenda'
import dayjs from 'dayjs'
import Loading from 'Components/Loading'

const Index = () => {
     const navigate=useNavigate()
     const { register, handleSubmit, watch,setValue, control,formState: { errors } } = useForm();
     const {filter} = useContext(TamizajeCtx)
     const [encargados,setEncargados] = useState([])
     const [loading,setLoading]=useState(false)
     const {showAlert} = useContext(UiCtx)

     const {user,hasRole} = useContext(SecurityCtx)
     const {id_clinica:clinica_id} = user || {}
     const {id:id_observacion} = useParams()
     const [detail,setDetail]=useState(null)
     const [error,setError]=useState(null)
     const [loadingAvailability,setLoadAvailability]=useState(false)
     const [cupo,setCupo] = useState(0)
    

     const fecha_atencion=watch('fecha_atencion')
     const tecnico=watch('id_tecnico')
     const adicional = watch('adicional')
     const sede=watch('id_sede')
     const id_paciente = watch('id_paciente')

    const [can_disabled_aditonal,setDisabledAditional] = useState(true)
   
    
     const getProfesional =async()=>{
          try {
            const {data} = await API.get(`/clinica/usuario/?clinica_id=${user.id_clinica}&sede=${sede || ""}`)
            setEncargados(data)
    
          }catch(error) {
               showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
          }
     }

     const load=async ()=>{
          setError(null)
          setLoading(true)
          try{
               console.log(encargados)
               const {data} = await API(`coordinador/tamizaje/${id_observacion}/detalle/?clinica_id=${user.id_clinica}`)
               setDetail(data.detail)
          }catch(error){
               setError(error?.response?.data?.detail || error.toString())
               showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
          }finally{
               setLoading(false)
          }
     }

     const tecnicoSugerido =async()=>{
          try {
               const fecha_inicial = dayjs(fecha_atencion).startOf('day').toDate()
               const fecha_final = dayjs(fecha_atencion).endOf('day').toDate()

            const {data} = await API.get(`/coordinador/tamizaje/tecnico-sugerido/`,{params:{fecha_inicial,fecha_final,sede}})
               if(data?.tecnico){
                    setValue('id_tecnico',{value:data.tecnico.id,label:data.tecnico.fk_user.first_name+' '+data.tecnico.fk_user.last_name+' '+data.tecnico.cupos_por_dia})
               }
    
          }catch(error) {
               showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
          }
     }
    

     const sendTamizaje =async(data)=>{
        console.log({data})
          try{
               setError(null)
               setLoading(true)
              const response = await API.post("/coordinador/tamizaje/crear/",{
                    autorizado_por:data.autorizado_por,
                    justificacion:data.justificacion,
                    encargado_paciente:data.encargado_paciente,
                    id_paciente:data?.id_paciente?.value || null,
                    fecha_atencion:data.fecha_atencion,
                    id_tecnico:data?.id_tecnico?.value || null,
                    id_medico:data?.id_medico?.value || null,
                    id_sede:data.id_sede,
                    adicional:data.adicional || false,
                    prioridad:data.prioridad
              })
              
              showAlert({html:"Se ha creado correctamente",icon:'success'})
              filter({t:Date.now()})
              navigate("/manager/tamizajes")
  
  
          }catch(error){
               showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
          }finally{
               setLoading(false)
          }
  
      }      

     const availability=async ()=>{
          try{
               setDisabledAditional(true)
               setValue('adicional','')
               setError(null)
               setLoadAvailability(true)
               const start = dayjs(fecha_atencion).startOf('day').toDate()
               const end = dayjs(fecha_atencion).endOf('day').toDate()
               const {data} = await API(`coordinador/tamizaje/disponibilidad/`,{params:{id_tecnico:tecnico.value,fecha_1:start,fecha_2:end}})
               
               setCupo(parseInt(data?.detail))

          }catch(error){
               setError(error?.response?.data?.detail || error.toString())
          }finally{
               setLoadAvailability(false)
          }
     }

     const loadPatient= async ()=>{
          try{
               const {data} = await API(`coordinador/paciente/${id_paciente.value}`)
               setValue("id_sede",data?.sede?.id)
               setValue("encargado_paciente",data?.encargado_paciente)
          }catch(error){

          }finally{

          }
     }

     useEffect(()=>{
          if(tecnico?.value){
               const t = encargados.find(e=>e.id==tecnico.value)
               
               if(!t?.cupos_por_dia || t?.cupos_por_dia<=cupo){
                    showAlert({
                         icon:'info',
                         title:'Cupo adicional',
                         html:`Técnico <b>${t?.fk_user?.first_name} ${t?.fk_user?.last_name}</b> no tiene cupos diponibles. Este tamizaje se guardara como <b>cupo adicional</b>`
                    })
                    setValue('adicional',true)
                    setDisabledAditional(hasRole([GROUP_CLINIC,GROUP_MANAGER]))
               }
          }
     },[cupo])

     useEffect(()=>{
          if(id_observacion){
               load()
          }
     },[id_observacion])

     useEffect(()=>{
          if(user){
               getProfesional()
          }
     },[user,sede])

     useEffect(()=>{
          setValue('id_tecnico','')
          setValue('id_medico','')
     },[sede])

     useEffect(()=>{
          if(sede && fecha_atencion){
               tecnicoSugerido()
          }
     },[sede,fecha_atencion])

     useEffect(()=>{
          if(tecnico && fecha_atencion){
               availability()
          }
     },[tecnico,fecha_atencion])

     useEffect(()=>{
          if(id_paciente){
               loadPatient()
          }
     },[id_paciente])


     //const medicos = encargados?.filter((med)=>parseInt(med.fk_user.groups[0].codigo) === GROUP_DOCTOR) || []
     const tecnicos = encargados?.filter((tec)=>parseInt(tec.fk_user.groups[0].codigo) === GROUP_TECHNICIAN) || []

     const loadOptions=async (query)=>{
          console.log(query)
          try{
               const {data} = await API('/coordinador/paciente/?nombre='+query+'&clinica_id='+clinica_id)
               return data.results.map(p=>({value:p.id,label:"Acudiente: "+p.numero_documento+" - "+p.primer_nombre_paciente+' '+p.primer_apellido_paciente}))
          }catch(error){
               return Promise.resolve([])
          }
          
     }

 

  

     return (
          <div className='flex lg:flex-row md:flex-col'>
               <div className='flex-1'>
                    <Agenda initialView='dayGridWeek' initialDate={fecha_atencion} sede={sede}/>
               </div>
               <div className='p-6 px-16 flex-1'>
          <form onSubmit={handleSubmit(sendTamizaje)}> 


               <div className='flex-1 mb-4'>
                    <label className='text-sm block mb-2'>Paciente</label>
                    <Controller name="id_paciente" control={control} render={({field})=>(<AsyncSelect {...field} placeholder="Escriba el nombre del paciente"  styles={REACT_SELECT_STYLES}   loadOptions={loadOptions} />)}/>
                    <FormError errors={errors} name='id_paciente' />
               </div>

              
               <div className='flex lg:flex-row md:flex-col sm:flex-col mb-2'>
               
                    <div className='flex-1 lg:mr-2'>
                         <label className='text-sm block mb-2'>Encargado del paciente *</label>
                         <select className='w-full bg-slate-400/40  rounded-md p-2' {...register('encargado_paciente',{required:true})}>
                              <option value="" className='text-slate-900'>Seleccione</option>
                              <option value="UCI" className='text-slate-900'>UCI</option>
                              <option value="padres" className='text-slate-900'>Padres</option>
                         </select>
                         <FormError errors={errors} name='encargado_paciente' />
                    </div>
                    <div className='flex-1 lg:mr-2'>
                         <SelectSedes
                              register={register("id_sede",{required:true})}
                         />
                         <FormError errors={errors} name='id_sede' />
                    </div>
                    <div className='flex-1 lg:mr-2'>
                         <label className='text-sm block mb-2'>Prioridad *</label>
                         <select className='w-full bg-slate-400/40  rounded-md p-2' {...register('prioridad',{required:true})}>
                              <option value="" className='text-slate-900'>Seleccione</option>
                              {TAMIZAJE_PRIORIDAD.map((t,i)=>(<option value={t.value} className={t.color}>{t.label}</option>))}
                         </select>
                         <FormError errors={errors} name='prioridad' />
                    </div>
                    
               </div>

               {/*<div className='flex lg:flex-row md:flex-col sm:flex-col mb-6'>
                    
                    <div className='flex-1 lg:mb-2'>
                              <label className='text-sm block mb-2'>Médico asignado</label>
                              <Controller name="id_medico" control={control} render={({field})=>(<Select  styles={REACT_SELECT_STYLES}
          {...field}  options={medicos.map(e=>({value:e.id,label:e.fk_user.first_name+' '+e.fk_user.last_name}))} />)} />
                    </div>
               </div>*/}

  
               <div className='flex lg:flex-row md:flex-col sm:flex-col mb-2 border-[1px] border-slate-400/40 p-2 rounded-md'>
                    <div className='flex-1 lg:mr-2'>
                         <Input type="datetime-local" descripcion="Fecha atención *" register={register("fecha_atencion",{required:true})}/>
                         <FormError errors={errors} name='fecha_atencion' />
                    </div>
                    <div className='flex-1'>
                         <label className='text-sm block mb-2'>Técnico asignado *</label>
                         <Controller name="id_tecnico" control={control} rules={{required:true}}  render={({field})=>(<Select {...field} styles={REACT_SELECT_STYLES} options={tecnicos.map(e=>({value:e.id,label:e.fk_user.first_name+' '+e.fk_user.last_name+' '+e.cupos_por_dia}))} />)} />
                         <FormError errors={errors} name='id_tecnico' />
                    </div>
               </div>
               {loadingAvailability && <Loading message='Consultando disponibilidad'/>}


               <div className='flex flex-col mb-2 border-[1px] border-slate-400/40 p-2 rounded-md'>
                    <div className=''>
                         <Input type="checkbox" descripcion="Cupo adicional" desactivar={!can_disabled_aditonal} register={register("adicional")}/>
                         
                    </div>
                    {adicional && hasRole([GROUP_MANAGER,GROUP_CLINIC]) && <div class="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                         <span class="font-medium">Infomacion!</span> Este tamizaje quedara como <b>Pendiente de Aprobación</b> posteriormente debe ser aprobado por un <b>coordinador</b>
                    </div>}
                    
               </div>


               {error && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>}

               <div className='flex-row flex justify-end my-8'>

                    <button disabled={loading || loadingAvailability} className='rounded-md border-white border-[1px] bg-[#2f3855] hover:bg-slate-300 text-white font-bold py-2 px-4 rounded'
                    type="submit"
                    >Agregar tamizaje</button>
               </div>
                        
                   

                    
               
          </form>

          </div>
          </div>
       
     )
}

export default Index

