import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import DetailsCoordinador from 'Pages/Coordinador/Tamizaje/Detail';

const ModalTamizaje=()=>{
    return <SimpleBar style={{ maxHeight: '100vh' }}>
        <DetailsCoordinador/>
    </SimpleBar>
}

export default ModalTamizaje