
import { API } from "API"
import Loading from "Components/Loading"
import { useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import {IoSyncOutline} from "react-icons/io5" 

const TipoPlanSelect=({register,onChange,show_label=true,initial_value})=>{
   

    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const [error,setError] = useState(null)
    const label='Plan'

    const [value,setValue]=useState('')

    const onChangeValue=(e)=>{
        setValue(e.target.value)
        onChange(e.target.value)
    }

    const load=async ()=>{
        try{
            setError(null)
            setLoading(true)
            const {data} = await API('/configuracion/tipo/')
            setItems(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

    useEffect(()=>{
        if(value!=initial_value){
            setValue(initial_value)
        }
    },[initial_value])

    if(loading){
        return <Loading/>
    }
    if(error){
        return <p className='print:hidden bg-red-500 text-slate-200 p-2 rounded-md'>{label}: {error}. <button onClick={load} className="cursor-pointer bg-red-800 p-2 rounded-md">Recargar</button></p>
    }

    const item = items.find(i=>""+i.id==register?.value)
    return (    
        <>
        <p className="hidden print:block text-slate-700">Plan: {item?.tipo}</p>
            <div className='w-full print:hidden'>
            {show_label && <label className='text-sm'>Tipo plan</label>}
        <div className='flex  items-center '>
            <select className="rounded-md border-[#2f3855] bg-[#2f3855] p-[2px] flex-1 "
            onChange={onChangeValue}
            {...register} value={value}
            >
                <option value="">Seleccione plan</option>
            {
            items.map((item)=>(

                    <option key={item.id} value={item.id}>{item.tipo}</option>
            
            ))

        }
            </select>

            <button onClick={load} className="text-lg ml-2"
            title='Recargar planes'
            >
                <IoSyncOutline/>
            </button>
        
        </div>
            </div>
        </>
    )
}

export default TipoPlanSelect