import Modal from 'react-modal';
import Card from 'Components/Card';
import {MdClose} from "react-icons/md"
import {  GROUP_ADMIN } from 'Contants';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { API } from 'API';
import { useContext } from 'react';
import { TablasMaestrasContext } from 'Context/TablasMaestras';
import {ImSpinner9} from "react-icons/im"

const AdminUserSave=({record,setRecord,reload,clinica_id})=>{
    const {grupos}  = useContext(TablasMaestrasContext)
    const [rol,setRol]=useState('')
    const [loading,setLoading] = useState(false)
   
    const [last_name,setLastName]=useState('')
    const [first_name,setFirstname]=useState('')

    const [email,setEmail]=useState('')
    const [error,setError]=useState(null)


    const edit = record?.id ? true : false   

    
    const update=async ()=>{
    
        setLoading(true)
            let payload={
                first_name,
                last_name,
                email,
                group:rol
            }
            try{
                await API.patch('/clinica/usuario/'+record.id+'/actualizar/?clinica_id='+clinica_id,payload)
                setRecord(null)
                reload()
            }catch(error){
                setError(error?.response?.data?.detail || error.toString())
            }finally{
                setLoading(false)
            }
    }

    const create= async ()=>{
        setLoading(true)
            let payload={
                first_name,
                last_name,
                email,
                group:rol,
                id_clinica:clinica_id
            }
            try{
                await API.post('/clinica/usuario/crear/?clinica_id='+clinica_id,payload)
                setRecord(null)
                reload()
            }catch(error){
                setError(error?.response?.data?.detail || error.toString())
            }finally{
                setLoading(false)
            }
    }

    const save=async ()=>{
        setError(null)
        if(!last_name || !email){
            setError("Complete todos los campos por favor")
            return
        }


        if(edit){
            update()
            return
        }
        create()

    }

    useEffect(()=>{
        if(record?.id){
            console.log(record)
            setRol(record?.fk_user?.groups[0].id)
            setFirstname(record?.fk_user?.first_name)
            setLastName(record?.fk_user?.last_name)
            setEmail(record?.fk_user?.email)
          
        }
        if(!record){
            setRol("")
            setFirstname("")
            setLastName("")
            setEmail("")
        }
    },[record])

    return <Modal isOpen={record} onRequestClose={()=>setRecord(null)} style={{content:{background:'transparent',border:"none",overflow:'hidden'}}}>
        <div className='max-w-[60%] m-auto'>
        <Card>
            <div className='flex justify-between mb-6'>
                <h2 className='text-lg'>{edit ? "Actualizar" :"Registrar"} cuenta de usuario</h2>
                <button onClick={()=>setRecord(null)} className="rounded-full hover:bg-slate-400/40 p-2">
                <MdClose />
                </button>
            </div>

            <SimpleBar className='overflow-x-hidden' style={{ maxHeight: 'calc(60vh)' }}>
            

            <div className='flex sm:flex-col md:flex-col lg:flex-row'>
                <div className='flex-1 lg:mr-2 mb-4'>
                    <label className='block'>Rol</label>
                    <select disabled={loading} value={rol} onChange={(e)=>setRol(e.target.value)} className='bg-slate-400/40  p-2 rounded-md w-full'>
                        <option className='text-slate-800' value="">Seleccione</option>
                        {grupos?.filter(g=>g.codigo!=GROUP_ADMIN)?.map((group,key)=>(<option className='text-slate-800' key={key} value={group.id}>{group.name}</option>))}
                     
                    </select>
                </div>
                <div className='flex-1 lg:mr-2'>
                    <label className='block'>Email</label>
                    <input disabled={loading} value={email} type="email" onChange={(e)=>setEmail(e.target.value)} className='bg-slate-400/40 p-2 rounded-md w-full'/>
                </div>
            </div>
                                    
            <div className='flex md:flex-col lg:flex-row mb-4'>
                <div className='flex-1 lg:mr-2 md:mb-2'>
                    <label className='block'>Nombres</label>
                    <input disabled={loading} value={first_name} onChange={(e)=>setFirstname(e.target.value)} className='bg-slate-400/40 p-2 rounded-md w-full'/>
                </div>
                <div className='flex-1 lg:ml-2'>
                    <label className='block'>Apellidos</label>
                    <input disabled={loading} value={last_name} onChange={(e)=>setLastName(e.target.value)} className='bg-slate-400/40 p-2 rounded-md w-full'/>
                </div>
            </div>


            

            {error && <div className='w-full'><div className="flex  items-center justify-center flex-col">
            <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>
          
          </div></div>}
            </SimpleBar>

            <div className='flex lg:justify-end mt-4'>
                <button disabled={loading} onClick={save} className="p-2 bg-slate-600/40 hover:bg-slate-600 hover:scale-110 rounded-md flex items-center">{loading && <ImSpinner9 className='animate-spin mr-[2px]'/>} Guardar</button>
            </div>
        </Card>
        </div>
    </Modal>
}

export default AdminUserSave