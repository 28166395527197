import SelectClinic from 'Components/SelectClinic';
import SelectFactoresRiesgo from 'Components/SelectFactoresRiesgo';
import { useEffect } from 'react';
import { Controller} from 'react-hook-form'

const PatientFilters=({clinica_id,register,control,setValue,handleSubmit,modo_admin,onFilter,page_patient})=>{

 
      useEffect(()=>{
        setValue('clinica_id',page_patient.clinica_id)
        setValue('nombre',page_patient.nombre)
        setValue('historia_clinica',page_patient.historia_clinica)
        setValue('clasificacion_riesgo',page_patient.clasificacion_riesgo)
        setValue('fecha_desde',page_patient.fecha_desde)
        setValue('fecha_hasta',page_patient.fecha_hasta)

      },[page_patient])

    return (<form onSubmit={handleSubmit(onFilter)} className="flex-1">
      <div className='border-[1px] border-slate-400/40 p-2  rounded-md mb-2'>
        {modo_admin && (
          <div className='flex-1 mb-4'>
            <label className='block lg:mr-2 text-md mb-2'>Cliente</label>
            <Controller name="clinica_id" control={control} render={({field})=>(<SelectClinic isClearable register={field} placeholder="Buscar cliente" />)}/>
          </div>
          
        )}
        <div className='flex lg:flex-row md:flex-col sm:flex-col sm:mb-4 md:mb-4'>
          <div className='flex-1 lg:mr-2'>
            <label className='block  text-md mb-2'>HC</label>
            <input type="text"
                className='rounded-md border-[#2f3855] bg-[#2f3855] py-[5px] px-2 w-full'
                {...register("historia_clinica")}
                />
          </div>
          <div className='flex-1 lg:mr-2 md:mb-2 sm:mb-2'>
          <label className='block lg:mr-2 text-md mb-2'>Nombre</label>
          <input type="text"
            className='rounded-md border-[#2f3855] bg-[#2f3855] py-[5px] px-2 w-full'
            {...register("nombre")}
            />
          </div>
        </div>
        <div className='flex lg:flex-row md:flex-col sm:flex-col sm:mb-4 md:mb-4'>
          <div className='flex-1 lg:mr-2 md:mb-2 sm:mb-2' title='Fecha creación'>
            <label className='block lg:mr-2 text-md mb-2'>Fecha inicio</label>
            <input type="date"
              className='rounded-md border-[#2f3855] bg-[#2f3855] py-[5px] px-2 w-full'
              {...register("fecha_desde")}
              />
          </div>
          <div className='flex-1 lg:mr-2 md:mb-2 sm:mb-2'  title='Fecha creación'>
            <label className='block lg:mr-2 text-md mb-2'>Fecha hasta</label>
            <input type="date"
              className='rounded-md border-[#2f3855] bg-[#2f3855] py-[5px] px-2 w-full'
              {...register("fecha_hasta")}
              />
          </div>
        </div>

        <div className='flex-1 lg:mr-2 md:mb-2 sm:mb-2'>
          <SelectFactoresRiesgo register={register("clasificacion_riesgo")}/>
        </div>
      </div>
      <button
        type='submit'
        className="bg-transparent sm:w-full lg:w-auto hover:bg-[#2f3855] text-slate-300 font-semibold hover:text-white py-2 px-4 border border-slate-300 hover:border-transparent rounded"
        >
          Buscar
          </button>
    </form>)
}

export default PatientFilters