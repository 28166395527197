import { API } from "API"
import FormError from "Components/FormError"
import Loading from "Components/Loading"
import { GROUP_CLINIC, GROUP_MANAGER, TAMIZAJE_PRIORIDAD } from "Contants"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"
import { useForm  } from 'react-hook-form'

const EnviarLectura=({estado,fk_tecnico,onReload,tamizaje_id})=>{
    const {user,hasRole} = useContext(SecurityCtx)
    const { register, handleSubmit, watch,setValue, control,formState: { errors } } = useForm();
    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading] = useState(false)

    if(estado!='2'){
        return null
    }

    if(!fk_tecnico && !hasRole([GROUP_CLINIC,GROUP_MANAGER])){
        return null
    }

    const save=async (payload)=>{
        try{
            setLoading(true)
            await API.patch(`coordinador/tamizaje/${tamizaje_id}/enviar-a-lectura/`,payload)
            onReload()
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    if(loading){
        return <Loading message="Actualizando tamizaje"/>
    }

    if(user?.usuario_clinica==fk_tecnico?.id || hasRole([GROUP_CLINIC,GROUP_MANAGER])){
        return <div className="print:hidden border-b-[1px] border-slate-400/40">
                <div className="p-4">
                    <form onSubmit={handleSubmit(save)}>
                    <div className="flex lg:flex-row sm:flex-col">
                        <div className='flex-1 lg:mr-2'>
                            <label className='text-sm block mb-2'>Prioridad *</label>
                            <select className='w-full bg-slate-400/40  rounded-md p-2' {...register('prioridad',{required:true})}>
                                <option value="" className='text-slate-900'>Seleccione</option>
                                {TAMIZAJE_PRIORIDAD.map((t,i)=>(<option value={t.value} className={t.color}>{t.label}</option>))}
                            </select>
                            <FormError errors={errors} name='prioridad' />
                        </div>
                        <div className='flex-1 lg:mr-2'>
                            <label className='text-sm block mb-2'>Observaciones *</label>
                            <textarea {...register('observaciones_tecnico',{required:'Escriba sus observaciones'})} rows={4} className="w-full bg-slate-400/40 p-2 rounded-md"></textarea>
                            <FormError errors={errors} name='observaciones_tecnico'/>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                    <button className="bg-teal-400 border-[1px] border-salte-400 p-2 rounded-md">Enviar a lectura</button>
                    </div>
                </form>
            </div>
        </div>
    }

   
}

export default EnviarLectura