import dayjs from "dayjs"

const EstadoAprobacion=({estado,fk_aprobador,fecha_aprobacion,justificacion})=>{

    if(!fecha_aprobacion){
        return null
    }
    if(estado=='-1'){
        return <>
            <label className="text-slate-400">Rechazado por</label>
            <p className="text-sm mb-2">{fk_aprobador?.fk_user?.first_name} {fk_aprobador?.fk_user?.last_name}</p>
            <p className="text-sm">{justificacion}</p>
            <small className="text-slate-400">El {dayjs(fecha_aprobacion).format("YYYY/MM/DD")}</small>
        </>
    }

    return <>
        <label className="text-slate-400">Aprobado por</label>
        <p className="text-sm mb-2">{fk_aprobador?.fk_user?.first_name} {fk_aprobador?.fk_user?.last_name}</p>
        <label className="text-slate-400 block">Justificación</label>
        <p className="text-sm">{justificacion}</p>
        <small className="text-slate-400">El {dayjs(fecha_aprobacion).format("YYYY/MM/DD")}</small>
    </>
}

export default EstadoAprobacion