import { useContext, useEffect } from "react"
import { SecurityCtx } from "Context/Security"
import { PERMISO_AGREGAR_PLAN, PERMISO_MODIFICAR_PLAN } from "Contants"
import Policy from "Components/Policy"
import Orden from "./Orden"

const Ordenamientos=({dado_de_alta,id_impresion,impresion,ordenamientos,setOrdenamientos})=>{

    const {permissions} = useContext(SecurityCtx)
    let read_only = false;
    
    if(id_impresion && !permissions.includes(PERMISO_MODIFICAR_PLAN)){
        read_only=true
    }else if(!id_impresion && !permissions.includes(PERMISO_AGREGAR_PLAN)){
        read_only=true
    }
    if(dado_de_alta){
        read_only=true
    }

    const add=()=>{
        setOrdenamientos(prev=>([...prev,{
            ojo:'',
            cantidad:1,
            via:'',
            semanas:'',
            medicamento:'',
            observaciones:'',
            durante:''
        }]))
    }

    const can_add = !dado_de_alta && ((!id_impresion && permissions.includes(PERMISO_AGREGAR_PLAN)) || (id_impresion && permissions.includes(PERMISO_MODIFICAR_PLAN)))
    console.log({ordenamientos})

    return <div className='my-4'>
        <h3 className="text-lg mb-4 print:text-slate-700">Ordenamientos</h3>
        {ordenamientos.map((o,i)=>(<Orden dado_de_alta={dado_de_alta} permissions={permissions} id_impresion={id_impresion} read_only={read_only} index={i} key={i} {...o} setOrdenamientos={setOrdenamientos}/>))}
        {can_add && <div className="flex justify-start items-center my-5 print:hidden" onClick={add}><button className="text-center hover:bg-slate-500/30 rounded-full px-[16px] py-[8px]">Agregar ordenamiento</button></div>}
            
        
    </div>
}

export default Ordenamientos