import { useContext, useEffect, useState } from "react"
import Hallazgos from "../Hallazgos";
import dayjs from "dayjs";
import ImpresionMedica from "../Impresion";
import Planes from "../Planes";

import { SecurityCtx } from "Context/Security";
import { API } from "API";
import Card from "Components/Card";
import { UiCtx } from "Context/Ui";
import { useNavigate } from "react-router-dom";
import Policy from "Components/Policy";
import { PERMISO_AGREGAR_HALLAZGOS, PERMISO_AGREGAR_IMPRESION_MEDICA, PERMISO_AGREGAR_PLAN, PERMISO_MODIFICAR_HALLAZGOS, PERMISO_MODIFICAR_IMPRESION_MEDICA, PERMISO_MODIFICAR_PLAN, PERMISO_VER_HALLAZGOS, PERMISO_VER_IMPRESION_MEDICA, PERMISO_VER_PLAN } from "Contants";
import Ordenamientos from "../Ordenamiento";
import DarAlta from "../DarAlta";

const TamizajeDoctor=({tamizaje_id,detail,onReload})=>{
    const {user,permissions} = useContext(SecurityCtx)
    const {estado,fk_paciente} = detail
    const {dado_de_alta} = fk_paciente

    const [id_impresion,setIdImpresion]=useState(null)
    const [impresion,setImpresion]=useState({hemorragia_vitrea :'',dilatacion_vasos_iris :'',enfermedad_plus :''})
    const [hallazgos,setHallazgos]=useState([])
    const [planes,setPlanes]=useState([])
    const [ordenamientos,setOrdenamientos]=useState([])
    const [saving,setSaving]=useState(false)
    const {showAlert} = useContext(UiCtx)
    const navigate=useNavigate()

    const save=async ()=>{
        try{
          for(let i=0;i<hallazgos.length;i++){
            const h=hallazgos[i]
            if(h.ojo=='' || h.fk_zona=='' || h.fk_rop=='' || h.observacion=='' || !h.observacion){
              console.log({h})
              throw('Ingrese la información requerida en hallazgos')
            }
          }
          for(let i=0;i<planes.length;i++){
            const p=planes[i]
            if(p.ojo=='' || p.fk_tipo=='' || p.fecha=='' || p.cantidad=='' || !p.fecha || !p.cantidad){
              throw('Ingrese la información requerida en planes')
            }
          }
          for(let i=0;i<ordenamientos.length;i++){
            const p=ordenamientos[i]
            console.log(p)
            if(p.ojo=='' || p.fecha=='' || p.cantidad=='' || p.durante=='' || p.via=='' || p.observaciones==''){
              throw('Ingrese la información requerida en ordenamientos')
            }
          }
          
          const payload={
            id_medico:user.usuario_clinica,
            id_tamizaje:parseInt(tamizaje_id),
            ...impresion,
            hallazgos:hallazgos.map((h)=>({...h,fk_zona:parseInt(h.fk_zona),fk_rop:parseInt(h.fk_rop),progreso:'inmaduro'})),
            planes:planes.map((p)=>({...p,fk_tipo:parseInt(p.fk_tipo),fk_servicio:1})),
            ordenamientos
          }
          setSaving(true)
          if(id_impresion){
            await API.patch(`gestion_medico/impresion/${id_impresion}/actualizar/`,payload)
          }else{
            const {data} = await API.post('gestion_medico/impresion/crear/',payload)

            setIdImpresion(data.id)
            navigate(-1)
          }
        

          showAlert({
            icon:'success',
            html:"Impresión médica guardada correctamente"
          })
        
        }catch(error){
          showAlert({
            icon:'error',
            html:error?.response?.data?.detail || error.toString()
          })
          
        }finally{
          setSaving(false)
        }
    }

  

    useEffect(()=>{
        if(detail){
            if(detail?.impresion){
                setImpresion(detail?.impresion)
                setIdImpresion(detail.impresion.id_impresion)
            }
            if(detail?.hallazgo){
                setHallazgos(detail?.hallazgo?.map(h=>({id:h.id,observacion:h.observacion,ojo:h.ojo,fk_rop:h.fk_rop,fk_zona:h.fk_zona})))
            }
            if(detail?.planes){
                setPlanes(detail.planes.map(p=>({id:p.id,ojo:p.ojo,fk_tipo:p.fk_tipo,fecha:dayjs(p.fecha).format('YYYY-MM-DDTHH:MM'),observacion:p.observacion,cantidad:p.cantidad})))
            }
            if(detail?.ordenamientos){
              setOrdenamientos(detail?.ordenamientos)
            }
        }
    },[detail])

    if(estado!='3' && estado!='4'){
      return null
    }

    const can_save = (!id_impresion && (permissions.includes(PERMISO_AGREGAR_PLAN) || permissions.includes(PERMISO_AGREGAR_HALLAZGOS) || permissions.includes(PERMISO_AGREGAR_IMPRESION_MEDICA))) || (id_impresion && (permissions.includes(PERMISO_MODIFICAR_PLAN) || permissions.includes(PERMISO_MODIFICAR_HALLAZGOS) || permissions.includes(PERMISO_MODIFICAR_IMPRESION_MEDICA)))

    return (<div content_style="my-4">
      <Policy show_error={false} policies={[PERMISO_VER_IMPRESION_MEDICA]}>
        <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <ImpresionMedica dado_de_alta={dado_de_alta} id_impresion={id_impresion} impresion={impresion} setImpresion={setImpresion}/>
          </div>
      </Policy>

      <Policy show_error={false} policies={[PERMISO_VER_HALLAZGOS]}>
        <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <Hallazgos dado_de_alta={dado_de_alta} id_impresion={id_impresion} impresion={impresion} hallazgos={hallazgos} setHallazgos={setHallazgos}/>
          </div>
      </Policy>

      <Policy show_error={false} policies={[PERMISO_VER_PLAN]}>
        <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <Planes dado_de_alta={dado_de_alta} id_impresion={id_impresion} impresion={impresion} planes={planes} setPlanes={setPlanes}/>
        </div>
      </Policy>
      <Policy show_error={false} policies={[PERMISO_VER_PLAN]}>
        <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <Ordenamientos dado_de_alta={dado_de_alta} id_impresion={id_impresion} impresion={impresion} ordenamientos={ordenamientos} setOrdenamientos={setOrdenamientos}/>
        </div>
      </Policy>
       
        
        
        
        {can_save && <div className="flex p-4 items-center justify-between">
          <DarAlta dado_de_alta={dado_de_alta} paciente={fk_paciente.id} id_impresion={id_impresion} onReload={onReload}/>
          {!dado_de_alta && <button className="print:hidden p-2 py-4 rounded-md bg-teal-400 hover:bg-slate-200/40" onClick={save}>Guardar Lectura</button>}
        </div>}
    </div>)
}

export default TamizajeDoctor