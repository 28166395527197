import React,{ useContext } from "react"
import {ImSpinner9} from "react-icons/im"
import { SecurityCtx } from "../../Context/Security"





const Policy=({children,policies=[],show_error=true})=>{

    const {user,permissions} = useContext(SecurityCtx)

 

    const showError=()=>{
        if(show_error){
            return <div className="m-4 p-2 bg-red-500 border-[1px] border-red-700 rounded-md">Acceso denegado</div>
        }else{
            return null
        }
    }

    if(!user){
        return <ImSpinner9 classNames='animate-spin'/>
    }

    if(!permissions.length && policies.length){
        return showError()
    }
    

    let could=false

    for(let i=0;i<policies.length;i++){
        if(permissions.includes(policies[i])){
            could=true
            break
        }
    }

    if(!could){
        return showError()
    }

    return children
}

export default Policy