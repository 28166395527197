import React from "react"

export const TOKEN=process.env.VITE_TOKEN
export const BASE_URL="https://admin.vart-core.ttncompany.com"
// export const BASE_URL="http://3.15.139.180:82"

export const GROUP_CLINIC = 4
export const GROUP_MANAGER = 1
export const GROUP_DOCTOR = 2
export const GROUP_TECHNICIAN = 3
export const GROUP_ADMIN = 5

export const STEP_VALID_TOKEN=1
export const STEP_VALID=2

export const TIPO_SOCIEDAD=[
    {
        label:'Sociedad Limitada',
        value:'1'
    },
    {
        label:'Sociedad Anónima',
        value:'2'
    },
    {
        label:'Empresa Unipersonal',
        value:'3'
    },
    {
        label:'Sociedad Colectiva',
        value:'4'
    }
    ,
    {
        label:'Empresa Asociativa de Trabajo',
        value:'5'
    },
    {
        label:'Sociedad por Acciones Simplificada S.A.S',
        value:'6'
    }
]

export const TIPO_PERSONA=[
    {
        label:'Natural',
        value:'Natural'
    },
    {
        label:'Jurídica',
        value:'Jurídica'
    }
]

export const REACT_SELECT_STYLES={
    control: styles => ({ ...styles, backgroundColor: 'rgb(148 163 184/ 0.4)',color:'white' }),
    singleValue:(styles)=>({...styles,color:"white"}),
    option:(styles)=>({...styles,color:'rgb(82 82 82)',active:{color:"white"}}
    )}

export const TAMIZAJE_PRIORIDAD=[
    {
        value:1,label:"Alta",color:"text-red-500",bg:'bg-red-500'
    },
    {
        value:2,label:"Baja",color:"text-teal-500",bg:'bg-teal-500'
    }
]

export const STATES_BY_ROLE={
    [GROUP_TECHNICIAN]:[
        {value:-1,label:'Rechazado',color:'bg-red-400'},
        {value:0,label:'Pendiente aprobación',color:'bg-red-400'},
        {value:1,label:'Por tamizar',color:'bg-red-400'},
        {value:2,label:'Tamizado',color:'bg-orange-400'},
        {value:3,label:'Lec. pendiente',color:'bg-orange-400'},
        {value:4,label:'Leído',color:'bg-green-400'},
    ],
    [GROUP_DOCTOR]:[
        {value:3,label:'Lec. pendiente',color:'bg-orange-400'},
        {value:4,label:'Leído',color:'bg-green-400'},
    ],
    [GROUP_MANAGER]:[
        {value:-1,label:'Rechazado',color:'bg-red-400'},
        {value:0,label:'Pendiente aprobación',color:'bg-red-400'},
        {value:1,label:'Por tamizar',color:'bg-red-400'},
        {value:2,label:'Tamizado',color:'bg-orange-400'},
        {value:3,label:'Lec. pendiente',color:'bg-orange-400'},
        {value:4,label:'Leído',color:'bg-green-400'},
    ],
    [GROUP_CLINIC]:[
        {value:-1,label:'Rechazado',color:'bg-red-400'},
        {value:0,label:'Pendiente aprobación',color:'bg-red-400'},
        {value:1,label:'Por tamizar',color:'bg-red-400'},
        {value:2,label:'Tamizado',color:'bg-orange-400'},
        {value:3,label:'Lec. pendiente',color:'bg-orange-400'},
        {value:4,label:'Leído',color:'bg-green-400'},
    ],
    [GROUP_ADMIN]:[
        {value:-1,label:'Rechazado',color:'bg-red-400'},
        {value:0,label:'Pendiente aprobación',color:'bg-red-400'},
        {value:1,label:'Por tamizar',color:'bg-red-400'},
        {value:2,label:'Tamizado',color:'bg-orange-400'},
        {value:3,label:'Lec. pendiente',color:'bg-orange-400'},
        {value:4,label:'Leído',color:'bg-green-400'},
    ]
}

export const PERMISO_VER_TAMIZAJES='view_tamizage'
export const PERMISO_AGREGAR_TAMIZAJE='add_tamizage'
export const PERMISO_MODIFICAR_TAMIZAJE='change_tamizage'

export const PERMISO_VER_USUARIO_CLINICA='view_usuarioclinica'
export const PERMISO_MODIFICAR_USUARIO_CLINICA='change_usuarioclinica'
export const PERMISO_AGREGAR_USUARIO_CLINICA='add_usuarioclinica'
export const PERMISO_ELIMINAR_USUARIO_CLINICA='toggle_state'

export const PERMISO_VER_PACIENTE='view_paciente'
export const PERMISO_MODIFICAR_PACIENTE='change_paciente'
export const PERMISO_AGREGAR_PACIENTE='add_paciente'
export const PERMISO_ELIMINAR_PACIENTE='delete_paciente'

export const PERMISO_VER_SEDES='view_sede'
export const PERMISO_MODIFICAR_SEDES='change_sede'
export const PERMISO_AGREGAR_SEDES='add_sede'
export const PERMISO_ELIMINAR_SEDES='delete_sede'

export const PERMISO_VER_CLIENTES='view_cliente'
export const PERMISO_MODIFICAR_CLIENTES='change_cliente'
export const PERMISO_AGREGAR_CLIENTES='add_cliente'
export const PERMISO_ELIMINAR_CLIENTES='delete_cliente'

export const PERMISO_VER_ROP='view_rop_estadio'
export const PERMISO_MODIFICAR_ROP='change_rop_estadio'
export const PERMISO_AGREGAR_ROP='add_rop_estadio'
export const PERMISO_ELIMINAR_ROP='delete_rop_estadio'

export const PERMISO_VER_RISK='view_tiporiesgos'
export const PERMISO_MODIFICAR_RISK='change_tiporiesgos'
export const PERMISO_AGREGAR_RISK='add_tiporiesgos'
export const PERMISO_ELIMINAR_RISK='delete_tiporiesgos'

export const PERMISO_VER_MEDICAL_PLAN='view_plan'
export const PERMISO_MODIFICAR_MEDICAL_PLAN='change_plan'
export const PERMISO_AGREGAR_MEDICAL_PLAN='add_plan'
export const PERMISO_ELIMINAR_MEDICAL_PLAN='delete_plan'

export const PERMISO_VER_GRUPOS='view_group'
export const PERMISO_AGREGAR_GRUPOS='add_group'
export const PERMISO_MODIFICAR_GRUPOS='change_group'
export const PERMISO_ELIMINAR_GRUPOS='delete_group'


export const PERMISO_VER_IMPRESION_MEDICA='view_imprension_medica' 
export const PERMISO_AGREGAR_IMPRESION_MEDICA='add_imprension_medica'
export const PERMISO_MODIFICAR_IMPRESION_MEDICA='change_imprension_medica'
export const PERMISO_ELIMINAR_IMPRESION_MEDICA='delete_imprension_medica'

export const PERMISO_VER_HALLAZGOS='view_hallazgo' 
export const PERMISO_AGREGAR_HALLAZGOS='add_hallazgo'
export const PERMISO_MODIFICAR_HALLAZGOS='change_hallazgo'
export const PERMISO_ELIMINAR_HALLAZGOS='delete_hallazgo'

export const PERMISO_VER_PLAN='view_plan' 
export const PERMISO_AGREGAR_PLAN='add_plan'
export const PERMISO_MODIFICAR_PLAN='change_plan'
export const PERMISO_ELIMINAR_PLAN='delete_plan'

export const PERMISO_VER_FOTO='view_foto' 
export const PERMISO_AGREGAR_FOTO='add_foto'
export const PERMISO_MODIFICAR_FOTO='change_foto'
export const PERMISO_ELIMINAR_FOTO='delete_foto'

export const PERMISO_VER_CONFIGURACION_REGIONAL='view_pais' 
export const PERMISO_AGREGAR_CONFIGURACION_REGIONAL='add_pais'
export const PERMISO_MODIFICAR_CONFIGURACION_REGIONAL='change_pais'
export const PERMISO_ELIMINAR_CONFIGURACION_REGIONAL='delete_pais'

export const PERMISO_VER_ORDENAMIENTO='view_ordenamientomodel'
export const PERMISO_AGREGAR_ORDENAMIENTO='add_ordenamientomodel'
export const PERMISO_MODIFICAR_ORDENAMIENTO='change_ordenamientomodel'
export const PERMISO_ELIMINAR_ORDENAMIENTO='delete_ordenamientomodel'