import { useContext, useEffect, useState } from "react";
import { TamizajeCtx } from "Context/Tamizaje";
import dayjs from "dayjs";
import { SecurityCtx } from "Context/Security";
import { GROUP_DOCTOR, GROUP_TECHNICIAN, TAMIZAJE_PRIORIDAD } from "Contants";
import SelectPacientes from "Components/SelectPacientes";
import SelectUsuarioClinica from "Components/SelectUsuarioClinica";

const Filters=({})=>{
    const {getTamizajeStates,user,hasRole} = useContext(SecurityCtx)
    const { records, count, paginate, error, filter, loading,params } =useContext(TamizajeCtx);
    const [fecha_desde, setStart] = useState('');
    const [fecha_hasta, setEnd] = useState('');
    const [state,setState] = useState('');
    const [prioridad,setPrioridad] = useState('');
    const [adicional,setAdicional] = useState('');
    const [patient,setPatient]=useState({value:'',label:''})
    const [tecnico,setTecnico]=useState({value:'',label:''})
    const [medico,setMedico]=useState({value:'',label:''})

    const states = getTamizajeStates()
    

    const filterNow = () => {
        filter({
          fecha_desde: fecha_desde ? dayjs(fecha_desde).startOf("day").toDate() : '',
          fecha_hasta: fecha_hasta ? dayjs(fecha_hasta).endOf("day").toDate() : '',
          estado:state,
          prioridad,
          adicional,
          id_paciente:patient.value,
          id_tecnico:tecnico.value,
          id_medico:medico.value,
          patient:patient,
          tecnico:tecnico,
          medico:medico
        });
      };

      const regiterPatient={
        onChange:(p)=>{
          setPatient(p)
        },
        value:patient
      }

      const registerTecnico={
        isDisabled:hasRole(GROUP_TECHNICIAN),
        isClearable:!hasRole(GROUP_TECHNICIAN),
        onChange:(p)=>{
          
          setTecnico(p || {value:'',label:''})
        },
        value:tecnico
      }
      const registerMedico={
        isDisabled:hasRole(GROUP_DOCTOR),
        isClearable:!hasRole(GROUP_DOCTOR),
        onChange:(p)=>{
          setMedico(p || {value:'',label:''})
        },
        value:medico
      }

    useEffect(()=>{
      if(user){
        if(hasRole(GROUP_DOCTOR)){
          setMedico({
            value:user.usuario_clinica,
            label:user?.first_name+' '+user?.last_name
          })
        }
        if(hasRole(GROUP_TECHNICIAN)){
          setTecnico({
            value:user.usuario_clinica,
            label:user?.first_name+' '+user?.last_name
          })
        }
      }
    },[user])
    

    useEffect(()=>{
        if(params){
          if(params.fecha_desde){
            setStart(dayjs(params.fecha_desde).format('YYYY-MM-DD'))
            setEnd(dayjs(params.fecha_hasta).format('YYYY-MM-DD'))
            setState(params.estado)
          }
          if(params.patient){
            setPatient(params.patient)
          }
          if(params.tecnico){
            setTecnico(params.tecnico)
          }
          if(params.medico){
            setMedico(params.medico)
          }
          if(params.prioridad){
            setPrioridad(params.prioridad)
          }
          if(params.estado){
            setState(params.estado)
          }
          if(params.adicional){
            setAdicional(params.adicional)
          }
        }
      },[params])

    return (<div className="flex-1 border-slate-400/40 border-[1px] p-2">
      

        <div className="flex flex-1 lg:flex-row md:flex-col sm:flex-col sm:mb-4 md:mb-4">

          <div className="flex-1 lg:mr-2 sm:mb-4 md:mb-4">
            <label className="mb-2 text-md block">Fecha inicio</label>
            <input
                type="date"
                placeholder="Fecha desde"
                className="rounded-md border-[#2f3855] bg-[#2f3855] p-2 w-full"
                disabled={loading} 
                value={fecha_desde}
                onChange={(e) => setStart(e.target.value)}
              />
          </div>

          <div className="flex-1 lg:mr-2 sm:mb-4 md:mb-4">
          <label className="mb-2 text-md block">Fecha fin</label>
          <input
                type="date"
                placeholder="Fecha hasta"
                className="rounded-md border-[#2f3855] bg-[#2f3855] p-2 w-full"
                value={fecha_hasta}
                disabled={loading}
                onChange={(e) => setEnd(e.target.value)}
              />
          </div>
          
          <div className="flex-1 lg:mr-2 sm:mb-4 md:mb-4">
              <label className="mb-2 text-md block">Estado</label>
              <select value={state} onChange={(e)=>setState(e.target.value)} className="bg-slate-400/40 p-2 mr-2 rounded-md w-full">
                  <option value="">Todos los estados</option>
                  {states.map((s)=>(<option className=" text-slate-800" value={s.value} key={s.value}>{s.label}</option>))}
              </select>
          </div>
          
          <div className="flex-1 lg:mr-2 sm:mb-4 md:mb-4">
          <label className="mb-2 text-md block">Prioridad</label>
          <select value={prioridad} onChange={(e)=>setPrioridad(e.target.value)} className="bg-slate-400/40 p-2 mr-2 rounded-md w-full">
                  <option value="">Todos las prioridades</option>
                  {TAMIZAJE_PRIORIDAD.map((t,i)=>(<option  className=" text-slate-800" key={i} value={t.value}>{t.label}</option>))}
              </select>
          </div>

          <div className="flex-1 lg:mr-2 sm:mb-4 md:mb-4">
            <label className="mb-2 text-md block">Cupo adicional</label>
            <select value={adicional} onChange={(e)=>setAdicional(e.target.value)} className="bg-slate-400/40 p-2 mr-2 rounded-md w-full">
                  <option value="">Todos</option>
                <option value={true} className=" text-slate-800">Sí</option>
                <option value={false} className=" text-slate-800">No</option>
              </select>
          </div>

      </div>
   
      <div className="flex flex-1 lg:flex-row md:flex-col sm:flex-col sm:mb-4 md:mb-4">
          <div className="flex-1 lg:mr-2 sm:mb-2">
            <label className="mb-2 text-md block">Paciente</label>
            <SelectPacientes register={regiterPatient}/>
          </div>
        
          <div className="flex-1 lg:mr-2 sm:mb-2">
            <label className="mb-2 text-md block">Técnico</label>
            <SelectUsuarioClinica register={registerTecnico}/>
          </div>

          <div className="flex-1 lg:mr-2 sm:mb-2">
            <label className="mb-2 text-md block">Médico</label>
            <SelectUsuarioClinica register={registerMedico} rol={GROUP_DOCTOR}/>
          </div>

      </div>
    <button
    disabled={loading}
    onClick={filterNow}
    className=" hover:bg-[#2f3855] text-slate-300 font-semibold hover:text-white py-2 px-4 border border-slate-300  rounded-md md:w-full sm:w-full lg:w-auto"
  >
    {loading ? "Filtrando..." : "Filtrar"}
  </button></div>)
}

export default Filters