import { API } from "API"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"

const Export=()=>{
    const {showALert} = useContext(UiCtx)
    const {user,page_patient}=useContext(SecurityCtx)
    const [loading,setLoading] = useState(false)


    const _export=async ()=>{
        try{
            setLoading(true)

            const {clinica_id:_id_clinica,...params} = page_patient
            const id_clinica = _id_clinica?.value || user?.id_clinica || null

            const {data} = await API.get(`/coordinador/paciente/export/`,{params:{clinica_id:id_clinica,...params}})
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download","pacientes.csv")
            link.click();
        }catch(error){
            showALert({
                icon:'error',
                title:'Error exportando',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
        
    }
    return <button onClick={_export} disabled={loading} className='bg-teal-500 p-[2px] px-[4px] rounded-md w-full'>{!loading ? "Exportar" :"Exportando..."}</button>
}

export default Export