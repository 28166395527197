import classNames from "classnames"
import { useEffect } from "react"
import { useState } from "react"

const EyeChoice=({label,read_only=false,initial_value,onChange})=>{
    const [value,setValue] = useState('')

    useEffect(()=>{
        setValue(initial_value)
    },[initial_value])

    

    const clase_od=classNames({
        'flex-1 border-slate-500 border-[1px] rounded-tl-md rounded-bl-md p-[4px] cursor-pointer':true,
        'print:text-slate-400':value!='OD',
        'print:text-slate-900 bg-slate-400/60':value=='OD'
    })
    const clase_oi=classNames({
        'flex-1 border-slate-500 p-[4px] cursor-pointer border-t-[1px] border-b-[1px]':true,
        'print:text-slate-400':value!='OI',
        'print:text-slate-900 bg-slate-400/60':value=='OI'
    })
    const clase_ao=classNames({
        'flex-1 border-slate-500 border-[1px] p-[4px] cursor-pointer rounded-tr-md rounded-br-md':true,
        'print:text-slate-400':value!='AO',
        'print:text-slate-900 bg-slate-400/60':value=='AO'
    })

    const toggle=(side)=>{
        if(read_only){
            return
        }
        const v=value == side ? '' : side
        setValue(v)
        if(typeof onChange=='function'){
            onChange(v)
        }
    }

    return (<div className="flex-1" title={read_only ? "Solo lectura":""}>
    <label className="text-sm mb-2 print:text-slate-700">{label}</label>
    <div className="flex  rounded-md  text-center overflow-hidden">
        <div className={clase_od} onClick={()=>toggle('OD')}>OD</div>
        <div className={clase_oi} onClick={()=>toggle('OI')}>OI</div>
        <div className={clase_ao} onClick={()=>toggle('AO')}>AO</div>
    </div>
</div>)
}

export default EyeChoice