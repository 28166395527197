import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import "./style.css"
import { AiTwotoneCalendar,AiOutlineUserAdd } from "react-icons/ai";
import {BsFillGearFill} from "react-icons/bs"
import {BiLogOut} from "react-icons/bi"
import { SecurityCtx } from '../../Context/Security';
import {FiUserPlus,FiUserCheck} from "react-icons/fi"
import {BiUser} from "react-icons/bi"
import {ImLocation} from "react-icons/im"
import {AiFillCalendar} from "react-icons/ai"


const index = ({log}) => {

  
    
    return (
    <div className="Nav">

        
        <div className='flex items-center mb-2 w-full p-4'>
        <img className="mr-1 w-[30%]" src='/assets/icon.png'/>
        <img className=" w-[70%]" src='/assets/logo.png'/>
        
        </div>
        <hr/>
        <div className='list mt-5'>

<div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[3px] pb-[3px] ml-[35px] mr-[35px] rounded-[20px]'>
 <div className='ml-1 mt-3 2xl:ml-7'>
 <div className='titulos ml-8'>
 <FiUserPlus className='icons'/>
 <Link to="/TamizajeRegistro">Registrar Tamizaje</Link>
 </div>
 </div>
 </div>

 <div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[10px] pb-[10px] pl-[15px] pr-[15px] rounded-[20px]'>

 <div className='ml-2 pl-2 pr-2 mt-2'>

 <div className='titulos mr-2'>
 <BiUser className='icons'/>
 <Link to="/Pacientes">Pacientes</Link>
 </div>
 </div>
 </div>


 <div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[15px] pb-[15px] pl-[20px] pr-[20px] rounded-[20px]'>

 <div className='ml-3 mt-2 2xl:mr-6'>

 <div className='titulos mr-2'>
 <FiUserCheck className='icons'/>
 <Link to="/TamizajeCoord">Tamizaje</Link>
 </div>
 </div>
 </div>
 
 <div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[15px] pb-[15px] pl-[20px] pr-[20px] rounded-[20px]'>

 <div className='ml-3 mt-2 2xl:mr-6'>

 <div className='titulos mr-8'>
 <ImLocation className='icons'/>
 <Link to="/Sedes">Sedes</Link>
 </div>
 </div>
 </div>

 <div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[15px] pb-[15px] ml-[20px] mr-[20px] rounded-[20px] pr-2'>

 <div className='ml-4 mt-2 2xl:mr-6'>
   
 <div className='titulos ml-4'>
 <AiOutlineUserAdd className='icons'/>
 <Link to="/Intervenciones">Intervenciones</Link>
 </div>
 </div>
 </div>

  <div className='hover:bg-slate-200 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 pt-[3px] pb-[3px] ml-[30px] mr-[30px] rounded-[20px]'>

 <div className='ml-6 2xl:ml-5 mt-2'>

 <div className='titulos ml-1'>
 <AiFillCalendar className='icons'/>
 <Link to="/CasosTratCoordinador">Casos en tratamiento</Link>
 </div>
 </div>
 </div>

 </div>

        <div className='logout'>
          <div className='titulos'>
      <BiLogOut className='icons'/>
       <a onClick={log}>Cerrar sesión</a>
          </div>
        </div>
      
    </div>
  )
}

export default index
