import React, { useState } from "react";
import { useContext } from "react";
import { SecurityCtx } from "../../Context/Security";
import "./style.css"
import "./responsive.css"
import Card from "../../Components/Card";
import {useForm} from "react-hook-form"
import { API } from "../../API";
import { UiCtx } from "../../Context/Ui";
import PasswordRecovery from "../PasswordRecovery";
import {ImSpinner9} from "react-icons/im"

const Login=()=>{
    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading]=useState(false)
    const [role,setRole] = useState()
    const {initUser,storeToken} = useContext(SecurityCtx)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mail:"",
        password:"",
       
    });
    const [moda_restaurar,setModalRestaurar]=useState(false)
  
    const login=async(data,token)=>{
        try{
            setLoading(true)
            const response = await API.post(`/clinica/usuario/login/`,{email:data.mail, password:data.password})
            storeToken(response.data.detail.acces_token)
        }catch(error){
            showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
        }finally{
            setLoading(false)
        }
    }

    return  (
        <>
        <div className="flex md:flex-col flex-col-reverse lg:flex-row justify-center items-center">
            <img src="/assets/background1.png" class="max-w-[50%]"/>
            <div className="">
                <Card>
                    <form onSubmit={handleSubmit(login)} className="flex-col flex items-center">
                        <div className="d flex-row flex items-center mb-2">
                            <img className="h-[50px] w-[50px]" src="/assets/icon.png"/>
                            <img className="h-[30px] w-[100px] ml-2" src="/assets/logo.png"/>
                        </div>
                        <p  className="text-white mb-4">Valoración de retina por telemedicina</p>
                        <input type="email" className='rounded-md bg-navy/[0.4] py-2 outline-none mb-2 w-[260px] p-2' placeholder='Correo electrónico' {...register("mail",{required:"El email es requerido con este se inicia sesión",pattern:{value:/\S+@\S+\.\S+/,message:'Ingrese un email valido'}})}/>
                        <input type="password" className='rounded-md bg-navy/[0.4] py-2 outline-none mb-2 w-[260px] p-2' placeholder='Contraseña' {...register("password")}/>
                        <label className="cursor-pointer" onClick={()=>setModalRestaurar(true)} style={{color:"white", marginRight:80}}>¿Olvidaste tu contraseña?</label>
                        <button disabled={loading} 
                        type="submit"
                        className="bg-slate-100 hover:bg-gray-400 text-black font-bold py-2 px-20 border-b-4 border-gray-400 hover:border-white-500 rounded mt-4 flex items-center">{loading && <ImSpinner9 className="mr-2 animate-spin"/>} Iniciar sesión</button>
                    </form>
                </Card>
            </div>    
            
        </div>
        <PasswordRecovery visible={moda_restaurar} setVisible={setModalRestaurar}/>
        </>
    )

}


export default Login