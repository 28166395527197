import FullName from "Components/FullName"
import TamizajeStatus from "Components/TamizajeStatus"
import EstadoAprobacion from "../EstadoAprobacion"

const PatientDetail=({detail})=>{
    return (
    <>
    <h3 className="text-md mb-6">Información del paciente</h3>
    <label className="text-slate-400">Entidad</label>
    <p className="text-sm mb-2">{detail?.fk_paciente?.entidad?.nombre_entidad}</p>
    <label className="text-slate-400">Sede</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_sede?.nombre || "No especificada"}</p>
    <label className="text-slate-400">Historia clínica UCIN</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.historia_clinica}</p>
    <label className="text-slate-400">Fecha nacimiento</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.fecha_nacimiento}</p>
    <label className="text-slate-400">Edad gestacional</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.edada_gestional} semanas</p>
    <label className="text-slate-400">Edad gestacional corregida</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.edada_gestional_corregida} semanas</p>
    <label className="text-slate-400">Peso de nacimiento</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.peso_nacimiento} gramos</p>
    <label className="text-slate-400">Sexo</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.sexo_paciente}</p>
    <label className="text-slate-400">Estado</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.encargado_paciente}</p>
    <label className="text-slate-400 mb-8">Factores de riesgo</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.factores_riesgo?.map(fr=><span className="mr-2 bg-red-400 rounded-full p-[2px] px-[4px]">{fr.factor_riesgo}</span>)}</p>
    <div className="my-4"></div>
    <label className="text-slate-400">ID. del acudiente</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.id_acudiente}</p>
    <label className="text-slate-400">Nombre del acudiente</label>
    <p className="text-sm mb-2"><FullName names={[detail?.fk_paciente?.primer_nombre_acudiente,detail?.fk_paciente?.segundo_nombre_acudiente,detail?.fk_paciente?.primer_apellido_acudiente,detail?.fk_paciente?.segundo_apellido_paciente]}/></p>
    <label className="text-slate-400">Teléfono</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.telefono_acudiente}</p>
    <label className="text-slate-400">Correo electrónico</label>
    <p className="text-sm mb-2 print:text-slate-500">{detail?.fk_paciente?.gmail_acudiente}</p>
    <div className="my-4"></div>
    

    <div className="mb-4">
        <label className="text-slate-400 mr-2 block">Estado</label>
        <TamizajeStatus>{detail?.estado}</TamizajeStatus>
    </div>

    <EstadoAprobacion {...detail}/>
    </>)
}

export default PatientDetail