import React, { useContext, useEffect } from "react";
import Input from "Components/Input";
import {  useNavigate, useParams } from "react-router-dom";
import { useForm,Controller } from "react-hook-form";
import { API } from "API";
import { useState } from "react";
import FormError from "Components/FormError";
import SelectSedes from "Components/SelectSedes";
import SelectDepartament from "Components/SelectDepartament";
import SelectCity from "Components/SelectCity";
import SelectCountry from "Components/SelectCountry";
import { SecurityCtx } from "Context/Security";
import { UiCtx } from "Context/Ui";
import { PERMISO_MODIFICAR_PACIENTE,PERMISO_AGREGAR_PACIENTE, GROUP_ADMIN } from "Contants";
import Policy from "Components/Policy";
import SelectAseguradora from "Components/SelectAseguradoras";
import SelectFactoresRiesgo from "Components/SelectFactoresRiesgo";
import SelectTipoDocumento from "Components/SelectTipoDocumento";
import SelectEncargado from "Components/SelectEncargado";
import SelectEntidad from "Components/SelectEntidad";
import SelectClinic from "Components/SelectClinic";

const AgregarPacienteCoordProtected = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();


  const [loading,setLoading]=useState(false)

  const navigation = useNavigate()
  const {user,hasRole} = useContext(SecurityCtx)
  const {showAlert} = useContext(UiCtx)

 
  const id_clinica = user.id_clinica
  const clinica_seleccionada = watch('clinica_id')

  const {id:paciente_id} = useParams()

  const dep = watch('id_departamento')
  const pais = watch('id_pais')


  const addPaciente = async ({clinica_id,..._payload}) => {
    const payload = {..._payload,clinica_id:clinica_id?.value || id_clinica}
  
    try {
      if(paciente_id){
        const {data} = await API.patch("/coordinador/paciente/"+paciente_id+"/actualizar/", {...payload,boolean_tamizaje:parseInt(payload.boolean_tamizaje)});
        showAlert({html:data?.detail,icon:'success'})
        navigation("/manager/patient/")
        return
      }
      const {data} = await API.post("/coordinador/paciente/crear/", {
        ...payload,
        boolean_tamizaje:parseInt(payload.boolean_tamizaje)
      });
      reset()
      showAlert({html:data?.detail,icon:'success'})
      navigation("/manager/patient/")
      
    } catch (error) {
      showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
    }
  };

  const loadPatient= async ()=>{
    try{
      setLoading(true)
      const {data}=await API(`/coordinador/paciente/${paciente_id}/`)
      Object.keys(data).forEach((key)=>{
        if(!['id','fk_clinica','fk_pais',"edada_gestional","edada_gestional_corregida","historia_clinica",'registro_civil','factores_riesgo',"gmail_acudiente","telefono_acudiente",'sexo_paciente',"Bolean_atencion",'entidad','estado_paciente','aseguradora','factores_riesgo','municipio','sede','tipo_documento','departamento'].includes(key)){
          setValue(key,data[key])
        }else{
          switch(key){
            case 'fk_pais':
              setValue('id_pais',data.fk_pais)
            break
            case 'entidad':
              setValue('id_entidad',data?.entidad?.id)
            break
            case 'departamento':
              setValue('id_departamento',data?.departamento?.id)
            break
            case 'municipio':
              setValue('id_municipio',data?.municipio?.id)
            break
            case "aseguradora":
              setValue('id_aseguradora',data?.aseguradora?.id)
            break
            case "sexo_paciente":
              setValue('sexo',data.sexo_paciente)
            break
            case "estado_paciente":
              //setValue('id_estado',data.estado_paciente.id)
            break
            case "tipo_documento":
              setValue('id_documento',data?.tipo_documento?.id)
            break
            case "municipio":
              setValue('id_municipio',data?.municipio?.id)
            break
            case "Bolean_atencion":
              setValue('boolean_tamizaje',data.Bolean_atencion ? "1" : "0")
            break
          
          case "telefono_acudiente":
            setValue('telefono',data.telefono_acudiente)
            break
            case "gmail_acudiente":
            setValue('correo',data.gmail_acudiente)
            break
            case 'factores_riesgo':
              const factores_riesgo = data?.factores_riesgo?.reduce((a,b)=>a+','+b.id,'').split(",").filter(f=>f!='')
              setValue('id_factores_riesgo',factores_riesgo)
            break
            case 'registro_civil':
              setValue('registro',data.registro_civil)
            break
            case 'historia_clinica':
              setValue('historia',data.historia_clinica)
            break
            case 'edada_gestional':
              setValue('edad_gestacional',data.edada_gestional)
            break
            case 'edada_gestional_corregida':
              setValue('edad_gestacional_corregida',data.edada_gestional_corregida)
            break
            case 'sede':
              setValue('id_sede',data?.sede?.id)
            break
            case 'fk_clinica':
              if(hasRole(GROUP_ADMIN)){
                setValue('clinica_id',{label:data?.fk_clinica?.razon_social,value:data?.fk_clinica?.id})
              }
            break
          }
        }
        
      })
    }catch(error){
      showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(paciente_id){
      loadPatient()
    }
  },[paciente_id])


  return (
    <>
    <form onSubmit={handleSubmit(addPaciente)}>

      <div className="my-16 p-4">

          {hasRole(GROUP_ADMIN) && <div className="mb-4">
            <label className="text-md mb-2">Cliente *</label>
            <Controller name="clinica_id" control={control} render={({field})=>(<SelectClinic isClearable={false} isDisabled={paciente_id} register={field} placeholder="Buscar cliente" />)}/>
            <FormError errors={errors} name='clinica_id'/>
          </div>}
        

          <h1 className="text-2xl mb-6">Información del recién nacido</h1>

          
          <div className="flex lg:flex-row sm:flex-col mb-2">
            <div className="flex-1 lg:mr-2">
                <Input
                  descripcion="Primer nombre *"
                  placeholder=""
                  register={register("primer_nombre_paciente",{ required:"Ingrese el nombre del paciente" })}
                />
                <FormError errors={errors} name='primer_nombre_paciente'/>
            </div>
            <div className="flex-1 lg:mr-2">
              <Input
                  descripcion="Segundo nombre"
                  placeholder=""
                  register={register("segundo_nombre_paciente")}
                />
            </div>
            <div className="flex-1 lg:mr-2">
            <Input
                descripcion="Primer apellido *"
                placeholder=""
                register={register("primer_apellido_paciente",{ required: "Ingrese el apellido del paciente" })}
              />
              <FormError errors={errors} name='primer_apellido_paciente'/>
            </div>
            <div className="flex-1 ">
            <Input
                descripcion="Segundo apellido *"
                placeholder=""
                register={register("segundo_apellido_paciente",{ required: true })}

              />
              <FormError errors={errors} name="segundo_apellido_paciente"/>
            </div>
          </div>

      
          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-2">
            <div className="flex-1 lg:mr-2">
              <Input 
                  descripcion="Fecha de nacimiento *"
                  placeholder=""
                  size={45}
                  register={register("fecha_nacimiento",{ required: true })} type="date"
                />
                <FormError errors={errors} name="fecha_nacimiento"/>
            </div>
            <div className="flex-1 lg:mr-2">
              <label className="mb-2 text-sm block">Sexo *</label>
              <select 
                className=" w-full rounded-md border-[#2f3855] bg-[#2f3855] pl-2 py-2"
                {...register("sexo",{required:true})}
                >
                  <option value="">Seleccione</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                </select>
                <FormError errors={errors} name="sexo"/>
            </div>

            <div className="flex-1 lg:mr-2">
              <Input
                  descripcion="Registro Civil"
                  placeholder=""
                  size={45}
                  register={register("registro")}
                />
            </div>
            <div className="flex-1 ">
              <Input
                  descripcion="Historia clínica"
                  placeholder=""
                  register={register("historia")}
                />
            </div>
            
          </div>

        

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-2">
            <div className='flex-1 lg:mr-2'>
                  <label className='text-sm block mb-2'>Encargado del paciente *</label>
                  <select className='w-full bg-slate-400/40  rounded-md p-2' {...register('encargado_paciente',{required:true})}>
                      <option value="" className='text-slate-900'>Seleccione</option>
                      <option value="UCI" className='text-slate-900'>UCI</option>
                      <option value="padres" className='text-slate-900'>Padres</option>
                  </select>
                  <FormError errors={errors} name='encargado_paciente' />
            </div>
            <div className="flex-1 lg:mr-2">
              <Input
                  descripcion="Edad gestacional"
                  placeholder=""
                  register={register("edad_gestacional")}
                />
            </div>
            <div className="flex-1 lg:mr-2">
              <Input
                  descripcion="Edad gestacional corregida"
                  placeholder=""
                  register={register("edad_gestacional_corregida")}
                />
            </div>
            <div className="flex-1">
            <Input
                descripcion="Peso de nacimiento"
                placeholder=""
                size={45}
                register={register("peso_nacimiento")}
              />
            </div>
          </div>

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-4">

            <div className="flex-1 lg:mr-2">
                <SelectEntidad register={register("id_entidad",{required:true})}/>
                <FormError errors={errors} name="id_entidad"/>
            </div>
            <div className="flex-1 lg:mr-2">
                  <SelectAseguradora register={register('id_aseguradora',{required:true})}/>
                  <FormError errors={errors} name="id_aseguradora"/>
              </div>

              <div className="flex-1">
                <SelectSedes cliente={id_clinica || clinica_seleccionada?.value} register={register('id_sede',{required:true})}/>
                <FormError errors={errors} name="id_sede"/>
            </div>
          </div>


          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-2">
           
            <div className="flex-1 lg:mr-2">
              <SelectFactoresRiesgo register={register("id_factores_riesgo")}/>
            </div>
           
          </div>


     
        <div className="mt-8">
          <h1 className="text-2xl mb-6">Información del Acudiente</h1>

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-4">
            <div className="flex-1 lg:mr-2">
                <SelectTipoDocumento register={register("id_documento",{ required: true })}/>
                <FormError errors={errors} name="id_documento"/>
            </div>
            <div className="flex-1">
              <Input
                descripcion="Identificación *"
                placeholder=""
                register={register("numero_documento",{required:true})}
              />
              <FormError errors={errors} name="numero_documento"/>
            </div>
          </div>

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-4">
            <div className="flex-1 lg:mr-2">
            <Input
                descripcion="Primer nombre *"
                register={register("primer_nombre_acudiente",{required:true})}
              />
              <FormError errors={errors} name="primer_nombre_acudiente"/>
            </div>
            <div className="flex-1 lg:mr-2">
            <Input
                descripcion="Segundo nombre"
                placeholder=""
                size={45}
                register={register("segundo_nombre_acudiente")}
              />
            </div>
            <div className="flex-1 lg:mr-2">
              <Input
                descripcion="Primer apellido *"
                register={register("primer_apellido_acudiente",{required:true})}
              />
              <FormError errors={errors} name="primer_apellido_acudiente"/>
            </div>
            <div className="flex-1">
              <Input
                  descripcion="Segundo apellido *"
                  register={register("segundo_apellido_acudiente",{required:true})}
                />
                <FormError errors={errors} name="segundo_apellido_acudiente"/>
            </div>
          </div>

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-4">
            <div className="flex-1 lg:mr-2">
                <SelectCountry register={register("id_pais",{required:true})}/>
                <FormError errors={errors} name="id_pais"/>
              </div>
              <div className="flex-1 lg:mr-2">
                <SelectDepartament pais={pais} register={register("id_departamento",{required:true})}/>
                <FormError errors={errors} name="id_departamento"/>
              </div>
              <div className="flex-1 lg:mr-2">
                <SelectCity dep={dep} register={register("id_municipio",{required:true})}/>
                <FormError errors={errors} name="id_municipio"/>
              </div>
          
          </div>

          <div className="flex lg:flex-row md:flex-col sm:flex-col mb-4">
            <div className="flex-1 lg:mr-2">
              <Input
                  descripcion="Teléfono *"
                  register={register("telefono",{required:true})}
                />
                <FormError errors={errors} name="telefono"/>
              </div>
              <div className="flex-1">
                <Input
                    descripcion="Correo electrónico *"
                    register={register("correo",{required:true})}
                  />
                  <FormError errors={errors} name="correo"/>
              </div>
          </div>

        </div>
        <hr className="mt-6 border-dashed" />
        <div className="mt-2">
          <text className="text-[20px]">Tamizaje</text>
          <div className="flex-row flex">
            <text>Requiere tamizaje:</text>
            <div className="flex-1" />
            <div className="mr-6">
              <label className="mr-1">Sí</label>
              <input
                type="radio" value={1} 
               {...register("boolean_tamizaje")}
              />
            </div>
            <div className="mr-4">
              <label className="mr-1">No</label>
              <input
                type="radio" value={0}  
                {...register("boolean_tamizaje")}
              />
            </div>
           
          </div>
         
        </div>
        <div className="flex-row flex justify-end mt-8 mb-6">
          <button
            className="rounded-md border-white border-[1px] bg-[#2f3855] hover:bg-slate-300 text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
    </>
  );
};

const AgregarPacienteCoord=()=>{
  const {id} = useParams()

  if(id && id!=''){
    return <Policy policies={[PERMISO_MODIFICAR_PACIENTE]}>
      <AgregarPacienteCoordProtected/>
    </Policy>
  }

  return <Policy policies={[PERMISO_AGREGAR_PACIENTE]}>
    <AgregarPacienteCoordProtected/>
  </Policy>

}

export default AgregarPacienteCoord;
