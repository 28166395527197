import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { API } from "API";
import {BsFileEarmarkPdf} from "react-icons/bs"
import TamizajeDetailPhotos from "Components/TamizajeDetail/Photos";
import { GROUP_DOCTOR, GROUP_TECHNICIAN ,GROUP_MANAGER, PERMISO_VER_TAMIZAJES,PERMISO_VER_IMPRESION_MEDICA,PERMISO_VER_HALLAZGOS,PERMISO_VER_PLAN} from "Contants";
import { SecurityCtx } from "Context/Security";
import Loading from "Components/Loading";
import PatientDetail from "Components/TamizajeDetail/Patient";
import TamizajeDetailTeam from "./Components/Team";
import TamizajeDoctor from "./Components/Doctor";
import { GROUP_CLINIC } from "Contants";
import dayjs from "dayjs";
import Policy from "Components/Policy";
import TamizajeAprobar from "./Components/Aprobar";
import EnviarLectura from "./Components/EnviarLectura";
import DarPorTamizado from "./Components/DarPorTamizado";
import ReactToPrint from 'react-to-print';

const TamizajeDetailProtected = () => {

    const component_to_print=useRef(null)
    const {user,hasRole} = useContext(SecurityCtx)
    const {id:tamizaje_id} = useParams()
    
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState(null)
    const [detail,setDetail]=useState(null)
   
    const load=async ()=>{
      setError(null)
      setLoading(true)
      try{
        const {data} = await API(`coordinador/tamizaje/${tamizaje_id}/detalle/?clinica_id=${user.id_clinica}`)
        
        setDetail(data.detail)
      }catch(error){
        setError(error?.response?.data?.detail || error.toString())
      }finally{
        setLoading(false)
      }
    }

  
    useEffect(()=>{
      if(user){
        load()
      }
    },[user])



    if(loading){
      return <Loading/>
    }

    if(error){
      return <div className="flex w-full h-[40%] items-center justify-center flex-col">
        <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>
        <button onClick={load}>Volver ha cargar</button>
        </div>
    }

    if(!detail){
      return <div className="flex w-full h-[40%] items-center justify-center flex-col">
        <div className='bg-red-400 rounded-md p-2 my-2'><p className='text-white-500'>Tamizaje no encontrado</p></div>
        <button onClick={load}>Volver ha cargar</button>
        </div>
    }

    if(detail.estado=='-1'){
      return <>
        <div className="flex-row flex">
          <div className="min-h-[100vh] w-[30%]  justify-center px-8 py-8 border-r-[1px] border-r-slate-800">
            <PatientDetail detail={detail}/>
          </div>
          <div className="flex-1 py-10">
            <div className="p-8">
            <div class="p-4 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300" role="alert">
              <span class="font-medium">Tamizaje rechazado!</span>
            </div>
            </div>
          </div>
        </div>
      </>
    }
   
    if(detail.estado=='0'){
      return <>
        <div className="flex-row flex">
          <div className="min-h-[100vh] lg:w-[30%] md:w-[40%] sm:w-[50%]  justify-center px-8 py-8 border-r-[1px] border-r-slate-800">
            <PatientDetail detail={detail}/>
          </div>
          <div className="flex-1 py-10">
            <div className="p-8">
              <div class="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                <span class="font-medium">Tamizaje pendiente de aprobación!</span> por favor espere ha ser aprobado, o pongase en contacto con un <b>Coordinador</b> para acelerar el proceso.
              </div>
              <TamizajeAprobar onReload={load} tamizaje_id={tamizaje_id} />
            </div>
          </div>
        </div>
      </>
    }

  

  return (
    <div ref={component_to_print}>
      
      <div className="flex-row flex">
        <div className="min-h-[100vh] lg:w-[30%] md:w-[40%] sm:w-[50%]  justify-center px-8 py-8 border-r-[1px] print:border-none border-r-slate-800">
          <PatientDetail detail={detail}/>
        </div>
    
        <div className="flex-1 py-10">
          <div className="flex justify-end px-4">
            <ReactToPrint trigger={()=>{return <button className="print:hidden"><BsFileEarmarkPdf/></button>}} content={()=>component_to_print?.current}/>
            
          </div>
          <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <div className="border-l-[2px] pl-2  border-l-slate-400">
              <p className="text-md text-slate-300">Tamizado por: {detail?.fk_tecnico?.fk_user?.first_name} {detail?.fk_tecnico?.fk_user?.last_name}</p>
              <p className="text-md text-slate-400">{detail?.observaciones_tecnico}</p>
              <p className="text-sm text-slate-400">{detail.fecha_tamizado ? dayjs(detail?.fecha_tamizado).format('DD/MM/YYYY') : ''}</p>

            </div>
          </div>
          <div className="px-8 py-4 border-b-[1px] border-slate-400/10">
            <TamizajeDetailPhotos id_tamizaje={tamizaje_id} reload={load} photos={detail?.fotos}/>
            <DarPorTamizado {...detail} tamizaje_id={tamizaje_id} onReload={load}/>
          </div>

          <EnviarLectura {...detail} tamizaje_id={tamizaje_id} onReload={load}/>
          
          

          {hasRole([GROUP_MANAGER,GROUP_CLINIC]) && <TamizajeDetailTeam tamizaje_id={tamizaje_id} clinica_id={user?.id_clinica} id_tecnico={detail?.fk_tecnico?.id} id_medico={detail?.fk_medico?.id}/>}
         
         <Policy show_error={false} policies={[PERMISO_VER_IMPRESION_MEDICA]}>
          <TamizajeDoctor onReload={load} tamizaje_id={tamizaje_id} detail={detail}/>
         </Policy>
         
        </div>
      </div>
      
    </div>
  );
};

const TamizajeDetail=()=>{
  return <Policy policies={[PERMISO_VER_TAMIZAJES]}>
      <TamizajeDetailProtected/>
    </Policy>
}

export default TamizajeDetail;
