import React, { useEffect } from 'react'
import {IoSyncOutline} from "react-icons/io5" 
import { useState } from 'react'
import { API } from '../../API'

const SelectCity = ({dep=null,register={}}) => {

    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)

    const load= async ()=>{
        if(!dep){
            return
        }
        try{
            setError(null)
            setLoading(true)
            const {data} = await API(`/configuracion/ciudad/?id_departamento=${dep}`)
            setItems(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(dep){
            load()
        }else{
            setItems([])
        }
    },[dep])
   
    if(loading){
        return <span className='text-center'>Cargando municipios...</span>
    }
    if(error){
        return <p className='bg-red-500 text-slate-200 p-2 rounded-md'>Municipios no cargados: {error}. <button type='button' onClick={load} className="cursor-pointer bg-red-800 p-2 rounded-md">Recargar</button></p>
    }


  return (
    <div>
        <label className='text-sm block mb-2 px-[8px]'>Municipio</label>
    <div className='flex  items-center '>
        <select className="rounded-md border-[#2f3855] bg-[#2f3855] pb-[5px]t-[5px] p p-2 flex-1 px-[8px]"
        {...register}
        >
            <option value="">Seleccione</option>
        {
        items.map((item)=>(

                <option key={item.id} value={item.id}>{item.nombre}</option>
        
        ))

      }
        </select>

        <button type='button' onClick={load} className="text-lg ml-2"
        title='Recargar ciudades'
        >
            <IoSyncOutline/>
        </button>
      
    </div>
    </div>
  )
}

export default SelectCity
