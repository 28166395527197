import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import {
  AiOutlineUserAdd,
} from "react-icons/ai";
import { FaBullseye,FaUsers } from "react-icons/fa";
import { BiLogOut, } from "react-icons/bi";
import {HiOutlineFlag} from "react-icons/hi"
import { SecurityCtx } from "../../Context/Security";
import {  FiLock,FiCalendar } from "react-icons/fi";
import { ImLocation } from "react-icons/im";
import {BiClinic} from "react-icons/bi"
import {GoDashboard} from "react-icons/go"
import {MdBabyChangingStation} from "react-icons/md"
import {IoIosWarning} from "react-icons/io"
import {TbReportMedical} from "react-icons/tb"
import {BsJournalMedical,BsFillShieldLockFill} from "react-icons/bs"

import classNames from "classnames"
import {
  GROUP_ADMIN,
  GROUP_CLINIC,
  GROUP_DOCTOR,
  GROUP_MANAGER,
  GROUP_TECHNICIAN,
  PERMISO_VER_CLIENTES,
  PERMISO_VER_CONFIGURACION_REGIONAL,
  PERMISO_VER_GRUPOS,
  PERMISO_VER_MEDICAL_PLAN,
  PERMISO_VER_PACIENTE,
  PERMISO_VER_RISK,
  PERMISO_VER_ROP,
  PERMISO_VER_SEDES,
  PERMISO_VER_TAMIZAJES,
  PERMISO_VER_USUARIO_CLINICA,
} from "../../Contants";
import Policy from "../Policy";


const ItemMenu = ({ to, name, Icon,selected }) => {
  const link=classNames({
    "flex items-center lg:justify-start sm:justify-center hover:bg-slate-400/5 focus:bg-[#03062A] focus:outline-none focus:ring focus:ring-[#03062A]  rounded-[20px] py-[4px] px-2  md:text-md lg:text-sm sm:text-lg mb-2":true,
    "bg-teal-500":selected
  })
  return (
    <Link
      to={to}
      className={link}
    >
      <Icon className="lg:text-md sm:text-xl" />
      <span className="ml-2 sm:hidden  lg:inline-block ">{name}</span>
    </Link>
  );
};
const Index = ({ log }) => {
  const location=useLocation()
  const current_page=location.pathname
  const { user,hasRole } = useContext(SecurityCtx);

  if (!user) return null;

  

  return (
    <div className="bg-[#171f3f] lg:w-[200px] xl:w-[200px] md:w-[60px] sm:w-[60px] h-[100vh]">

      <div className='flex items-center justify-center w-full px-4 py-2 bg-[#171f3f]'>
          <img className="lg:w-[30%] sm:w-full" src='/assets/icon.png'/>
          <img className="w-[70%] sm:hidden lg:inline-block" src='/assets/logo.png'/>
      </div>
      <div className="flex flex-col mt-5 lg:px-4 sm:px-2">
   
        <ItemMenu to='/dashboard' Icon={GoDashboard} name="Dashboard"  selected={current_page=='/dashboard'} />
        {hasRole([GROUP_CLINIC,GROUP_DOCTOR,GROUP_MANAGER,GROUP_TECHNICIAN]) && (<Policy show_error={false} policies={[PERMISO_VER_TAMIZAJES]}>
          <ItemMenu selected={current_page=='/manager/tamizajes'}
              to="/manager/tamizajes"
              Icon={TbReportMedical}
              name="Tamizajes"
          />
          <ItemMenu to="/manager/agenda" selected={current_page=='/manager/agenda'} Icon={FiCalendar} name="Agenda" />
        </Policy>)}

        <Policy show_error={false} policies={[PERMISO_VER_CLIENTES]}>
          <ItemMenu selected={current_page=='/admin/clinics'}
              to="/admin/clinics"
              Icon={BiClinic}
              name="Clientes"
          />
        </Policy>

        {hasRole ([GROUP_CLINIC,GROUP_DOCTOR,GROUP_MANAGER,GROUP_TECHNICIAN]) && <Policy show_error={false} policies={[PERMISO_VER_PACIENTE]}>
          <ItemMenu to="/manager/patient" selected={current_page=='/manager/patient'} Icon={MdBabyChangingStation} name="Pacientes" />
        </Policy>}

        {!hasRole ([GROUP_CLINIC,GROUP_DOCTOR,GROUP_MANAGER,GROUP_TECHNICIAN]) && <Policy show_error={false} policies={[PERMISO_VER_PACIENTE]}>
          <ItemMenu to="/admin/patient" selected={current_page=='/admin/patient'} Icon={MdBabyChangingStation} name="Pacientes" />
        </Policy>}
        
        {!hasRole(GROUP_ADMIN) && <Policy show_error={false} policies={[PERMISO_VER_USUARIO_CLINICA]}>
          <ItemMenu to="/manager/team" selected={['/manager/team','/manager/team/create'].includes(current_page)} Icon={FaUsers} name="Equipo de trabajo" />
        </Policy>}

        <Policy show_error={false} policies={[PERMISO_VER_SEDES]}>
          <ItemMenu to="/manager/sites" Icon={ImLocation} selected={current_page=='/manager/sites'} name="Sedes" />
        </Policy>

        

        {hasRole([GROUP_ADMIN]) && <Policy show_error={false} policies={[PERMISO_VER_ROP]}>
          <ItemMenu selected={current_page=='/admin/rop'}
              to="/admin/rop"
              Icon={FaBullseye}
              name="ROP"
          />
        </Policy>}

        {hasRole([GROUP_ADMIN]) && <Policy show_error={false} policies={[PERMISO_VER_RISK]}>
          <ItemMenu selected={current_page=='/admin/risk'}
              to="/admin/risk"
              Icon={IoIosWarning}
              name="Fac. de riesgo"
          />
        </Policy>}
        {hasRole([GROUP_ADMIN]) && <Policy show_error={false} policies={[PERMISO_VER_MEDICAL_PLAN]}>
          <ItemMenu selected={current_page=='/admin/medical-plan'}
              to="/admin/medical-plan"
              Icon={BsJournalMedical}
              name="Tipo de plan médico"
          />
        </Policy>}

        <Policy show_error={false} policies={[PERMISO_VER_CONFIGURACION_REGIONAL]}>
          <ItemMenu selected={current_page=='/admin/regional-settings'} to="/admin/regional-settings" name='Regional' Icon={HiOutlineFlag}/>
        </Policy>

        {hasRole([GROUP_ADMIN]) && <Policy show_error={false} policies={[PERMISO_VER_GRUPOS]}>
          <ItemMenu selected={current_page=='/admin/roles'}
                to="/admin/roles"
                Icon={BsFillShieldLockFill}
                name="Roles y permisos"
            />
        </Policy>}

        

        
        
        <div className="border-t-[1px] py-4 border-t-slate-400/40">
          <ItemMenu selected={current_page=='/change-password'} to='/change-password' Icon={FiLock} name='Actualizar contraseña'/>
        </div>


        <div onClick={log} className="mt-4 px-2 cursor-pointer flex items-center lg:justify-start sm:justify-center hover:bg-indigo-100/5 focus:bg-[#03062A] focus:outline-none focus:ring focus:ring-[#03062A]  rounded-[20px] py-[4px]  md:text-md lg:text-sm sm:text-lg">
          <BiLogOut className="lg:text-md sm:text-xl" />
          <span className="ml-2 sm:hidden  lg:inline-block ">Cerrar sesión</span>
        </div>
      
      
      </div>

      
    </div>
  );
};
export default Index;
