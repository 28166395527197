import { useState } from "react"
import { API } from "API"
import EyeChoice from "Components/EyeChoice"
import RopSelect from "Components/RopSelect"
import ZoneSelect from "Components/ZoneSelect"
import Policy from "Components/Policy"
import { PERMISO_ELIMINAR_HALLAZGOS } from "Contants"

const Hallazgo=({dado_de_alta,permissions,id_impresion,read_only=false,index,id,setHallazgos,observacion,ojo,fk_zona,fk_rop})=>{
    const [loading,setLoading]=useState(false)
    
    const removeConfirmed=()=>{
        setHallazgos(prev=>{
            const temp=[...prev]
            temp.splice(index,1)
            return temp
        })
    }

    const remove=async ()=>{
        if(id){
            if(window.confirm("Se borrara el hallazgo")){
                setLoading(true)
                try{
                    await API.delete('gestion_medico/impresion/borrar-hallazgo',{data:{id_hallazgo:parseInt(id)}})
                    removeConfirmed()
                }catch(error){

                }finally{
                    setLoading(false)
                }
            }
        }else{
            removeConfirmed()
        }
    }

    const setProp=(prop,value)=>{
        setHallazgos(prev=>prev.map((h,i)=>{
            if(i==index){
                return {...h,[prop]:value}
            }
            return h
        }))
    }

    const setObs=(value)=>{
        setProp('observacion',value)
    }
    const setEye=(value)=>{
        setProp('ojo',value)
    }
    const setZone=(e)=>{
       setProp('fk_zona',e.target.value)
    }
    const setRop=(e)=>{
        setProp('fk_rop',e.target.value)
    }

    if(loading){
        return <h1>Cargando...</h1>
    }

    const puede_eliminar=!dado_de_alta && (!id_impresion || (id_impresion && permissions.includes(PERMISO_ELIMINAR_HALLAZGOS)))

    return (<div className="mt-[32px] mb-[42px] ">
        <div className="flex lg:flex-row sm:flex-col items-stretch">
            <div className="flex-1 mr-2">
                <EyeChoice read_only={read_only} label="" initial_value={ojo} onChange={setEye} />
                <div className="flex flex-1 mt-2 lg:flex-row sm:flex-col">
                    <div className="flex-1 lg:mr-2 sm:mb-2">
                        <ZoneSelect register={{disabled:read_only,value:fk_zona,onChange:setZone}} show_label={false}  initial_value={fk_zona} />
                    </div>
                    <div className="flex-1 sm:mb-2">
                        <RopSelect register={{disabled:read_only,onChange:setRop,value:fk_rop}}  show_label={false}  initial_value={fk_rop} onChange={setRop} />
                    </div>
                </div>
            </div>
            <div className="flex-1 items-stretch ">
                <p className="print:block text-slate-700 hidden">Observaciones: {observacion}</p>
                <textarea disabled={read_only} className="print:hidden w-full h-full text-sm bg-slate-500/30 rounded-md p-2" placeholder="Observaciones" value={observacion} onChange={(e)=>setObs(e.target.value)}></textarea>
              
                {puede_eliminar && <div className="flex justify-end print:hidden">
                    <button onClick={remove} className="flex items-center hover:bg-slate-500/30 rounded-full p-[4px]">
                        <span className="bg-slate-100 text-slate-500 rounded-[50%] h-[16px] w-[16px] flex items-center justify-center mr-[2px]">
                            -
                        </span>
                        <span className="text-sm">Eliminar</span>
                    </button>
                </div>}
                
            </div>
            
        </div>
    </div>)
}

export default Hallazgo