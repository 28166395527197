import React, { useState } from "react";
import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { SecurityCtx } from "../../Context/Security";
import NavBar from "../../Components/NavBar"
import Pagination from "../../Components/Pagination";
import NavBarCoordinador from "../../Components/NavBarCoordinador"
import NavBarTecnico from "../../Components/NavBarTecnico"
import NavBarClinica from "../../Components/NavBarClinica"
import Header from "../../Components/Header/index"
import { useRef } from "react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const Main=()=>{
    const {logout} = useContext(SecurityCtx)


    return (
        <div className="flex">
            <NavBar log={logout}/>
            <div className="flex-1 flex flex-col">
                <Header/> 
                <section className="flex-1">
                    <SimpleBar style={{ maxHeight: 'calc(100vh - 76px)' }}>
                    <Outlet/>
                    </SimpleBar>
                    
                </section>
            </div>
        </div>
    )
    

    
}

export default Main