import { useState } from "react"
import {ImSpinner9} from "react-icons/im"
import { API } from "API"

const RopStatus=({id,rop,codigo,sys_active,onChange,style=''})=>{
    const [state,setState]=useState(sys_active)
    const [loading,setLoading] = useState(false)

    const inactivate=async ()=>{
        await API.delete(`/configuracion/rop/${id}/desactivar/`)
        onChange({rop,id,codigo,sys_active:false})
        setState(false)
    }
    const activate=async ()=>{
        await API.patch(`/configuracion/rop/${id}/activar/`)
        onChange({rop,id,codigo,sys_active:true})
        setState(true)
    }

    const toggle=async ()=>{
        if(loading){
            return
        }
        setLoading(true)
        try{
            if(state){
                await inactivate()
            }else{
                await activate()
            }
        }catch(error){

        }finally{
            setTimeout(()=>{
                setLoading(false)
            },800)
        }
       
        
    }

    const state_str = state ? "Habilitado" : "Inhabilitado"
    const clase = state ? "bg-slate-800" : "bg-slate-800/50"
    const clase2 = state ? "bg-green-400" : "bg-red-400"
    const order = state ? "order-1" : "order-2"
    const order2 = state ? "order-2" : "order-1"

    return <div onClick={toggle} className={"rounded-full  cursor-pointer p-[2px] text-center items-center flex justify-between text-sm overflow-hidden  "+clase+' '+style}>
       
            <span className={"ease-in-out flex-1 text-center duration-500 "+order}>{state_str}</span>
            <div className={"ease-in-out duration-500 flex justify-center items-center rounded-full w-[16px] h-[16px] "+clase2+" drop-shadow-md "+order2}>
                {loading && <ImSpinner9 className="animate-spin"/>}
            </div>
        </div>
}

export default RopStatus