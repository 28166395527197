import React,{ useContext, useEffect, useState } from "react"
import { API } from "../../../../API"
import { UiCtx } from "../../../../Context/Ui"
import {BsFillPlusCircleFill} from "react-icons/bs"
import {FaRegEdit} from "react-icons/fa"
import RopSave from "../Save"
import RopStatus from "../Components/Status"
import {ImSpinner9} from "react-icons/im"
import Policy from "Components/Policy"
import {PERMISO_VER_ROP,PERMISO_AGREGAR_ROP,PERMISO_MODIFICAR_ROP} from "Contants"

const RopProtected=()=>{
    const {showAlert} = useContext(UiCtx)
    const [items,setItems]=useState([])
    const [item,setItem]=useState(null)
    const [loading,setLoading] = useState(false)

    const load= async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/rop/')
            setItems(data)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
    }

    const onSave=(_item)=>{
        const exist = items.findIndex(i=>i.id==_item.id)

        if(exist>=0){
            setItems(prev=>(prev.map((i)=>{
                if(i.id==_item.id){
                    return _item
                }
                return i
            })))
        }else{
            setItems(prev=>[...prev,_item])
        }
    }

    useEffect(()=>{
        load()
    },[])

    return <div className="lg:w-[40%] md:w-[70%] sm:w-[90%] m-auto my-4">
        <Policy show_error={false} policies={[PERMISO_AGREGAR_ROP,PERMISO_MODIFICAR_ROP]}>
            <RopSave item={item} setItem={setItem} onSave={onSave}/>
        </Policy>
        <div className="flex items-center justify-end mb-4">
            <button onClick={load} className="hover:bg-slate-400/40 rounded-md p-[4px] flex items-center mr-4">Recargar {loading && <ImSpinner9 className="ml-2 animate-spin"/>}</button>
            <Policy show_error={false} policies={[PERMISO_AGREGAR_ROP]}>
                <button onClick={()=>setItem({})} className="hover:bg-slate-400/40 rounded-md p-[4px] flex items-center">Nuevo estadio <BsFillPlusCircleFill className="ml-2"/></button>
            </Policy>
            
        </div>
        <table className="border-[1px] border-slate-400/40">
            <thead className="bg-slate-400/40">
                <tr>
                    <th>ROP estadio</th>
                    <th>descripción</th>
                    <Policy show_error={false} policies={[PERMISO_MODIFICAR_ROP]}>
                        <th></th>
                    </Policy>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {items.map((_item,index)=>(<tr>
                    <td className="text-center">{_item.codigo}</td>
                    <td>{_item.rop}</td>
                    <Policy show_error={false} policies={[PERMISO_MODIFICAR_ROP]}>
                        <td>  
                            <button className="hover:cursor-pointer hover:bg-slate-400/40 rounded-full p-[4px]">
                                <FaRegEdit size={16} onClick={()=>setItem(_item)}/>
                            </button>
                        </td>
                    </Policy>
                    <td>
                        <RopStatus {..._item} onChange={onSave}/>
                    </td>
                </tr>))}
            </tbody>
        </table>
    </div>
}

const Rop=()=>{
    return <Policy policies={[PERMISO_VER_ROP]}>
        <RopProtected/>
    </Policy>
}

export default Rop