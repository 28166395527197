import dayjs from "dayjs"
import { useState } from "react"
import { useEffect } from "react"
import { API } from "API"
import EyeChoice from "Components/EyeChoice"
import TipoPlanSelect from "Components/TipoPlanSelect"
import Policy from "Components/Policy"
import { PERMISO_ELIMINAR_PLAN } from "Contants"

const Orden=({dado_de_alta,permissions,id_impresion,read_only=false,index,id,observaciones,ojo,medicamento,durante,via,cantidad,semanas,setOrdenamientos})=>{

    const [loading,setLoading]=useState(false)

    const removeConfirmed=()=>{
        setOrdenamientos(prev=>{
            const temp=[...prev]
            temp.splice(index,1)
            return temp
        })
    }

    const remove=async ()=>{
        if(id){
            if(window.confirm("Se borrara el plan")){
                setLoading(true)
                try{
                    await API.delete('gestion_medico/impresion/borrar-plan',{data:{id_plan:parseInt(id)}})
                    removeConfirmed()
                }catch(error){

                }finally{
                    setLoading(false)
                }
            }
        }else{
            removeConfirmed()
        }
    }
    

    const setProp=(prop,value)=>{
        setOrdenamientos(prev=>prev.map((h,i)=>{
            if(i==index){
                return {...h,[prop]:value}
            }
            return h
        }))
    }

 
    const setEye=(value)=>{
        setProp('ojo',value)
    }
    const setMedicamento=(e)=>{
        setProp('medicamento',e.target.value)
    }
    const setCantidad=(e)=>{
        setProp('cantidad',e.target.value)
    }
    const setSemanas=(e)=>{
        setProp('semanas',e.target.value)
    }
    const setDurante=(e)=>{
        setProp('durante',e.target.value)
    }
    const setVia=(e)=>{
        setProp('via',e.target.value)
    }
    const setObs=(e)=>{
        setProp('observaciones',e.target.value)
    }
    

    const puede_eliminar=!dado_de_alta && (!id_impresion || (id_impresion && permissions.includes(PERMISO_ELIMINAR_PLAN)))
    

    if(loading){
        return <h1>Cargando...</h1>
    }
   

    return <div className="mt-[32px]">
        <div className="flex mb-2 lg:flex-row sm:flex-col sm:mb-4">
            <div className="mr-2 flex-1 sm:mb-4">
                <EyeChoice read_only={read_only} label="" initial_value={ojo} onChange={setEye}/>
            </div>
            <div className="flex-1">
                <input className="w-full px-2 rounded-md bg-slate-500/60" placeholder="Medicamento" disabled={read_only} value={medicamento} onChange={setMedicamento} />
            </div>
        </div>
        <div className="flex lg:flex-row sm:flex-col sm:mb-4">
            <div className="flex-1 lg:mr-2 sm:mb-4">
                <input className="w-full px-2 rounded-md bg-slate-500/60" placeholder="Cant." disabled={read_only} value={cantidad} onChange={setCantidad} />
            </div>
            <div className="flex-1">
                <input className="w-full px-2 rounded-md bg-slate-500/60" placeholder="Cada" disabled={read_only} value={semanas} onChange={setSemanas}/>
            </div>
        </div>
        <div className="flex lg:flex-row sm:flex-col sm:mb-4">
            <div className="flex-1 lg:mr-2 sm:mb-4">
                <input className="w-full px-2 rounded-md bg-slate-500/60" placeholder="Durante." disabled={read_only} value={durante} onChange={setDurante} />
            </div>
            <div className="flex-1">
                <input className="w-full px-2 rounded-md bg-slate-500/60" placeholder="Vía" disabled={read_only} value={via} onChange={setVia} />
            </div>
        </div>
        <div className="flex-1 items-stretch">
                <textarea disabled={read_only} className="w-full h-full bg-slate-500/30 text-sm rounded-md p-2" placeholder="Observaciones" value={observaciones} onChange={setObs} ></textarea>
            
                    {puede_eliminar && <div className="flex justify-end">
                        <button onClick={remove} className="flex items-center hover:bg-slate-500/30 rounded-full p-[4px]">
                            <span className="bg-slate-100 text-slate-500 rounded-[50%] h-[16px] w-[16px] flex items-center justify-center mr-[2px]">
                                -
                            </span>
                            <span className="text-sm">Eliminar</span>
                        </button>
                    </div>}
                
            </div>
    </div>
}

export default Orden