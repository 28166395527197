import { GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN } from 'Contants';
import { SecurityCtx } from 'Context/Security';
import { useContext } from 'react';
import { Pie } from 'react-chartjs-2';

const TamizajesPorFecha=({tamizajes})=>{

  const {user,hasRole} = useContext(SecurityCtx)

    if(!tamizajes.length){
        return <p>No hay datos que procesar</p>
    }

   

    const options={
        plugins:{
        
            datalabels:{
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;
                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                      let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                      let percentage = Math.round((value / sum) * 100) + '%';
                      return percentage;
                    } else {
                      return 0;
                    }
                  },
                  color: '#fff',
            }
        
    }
  }





  let data=[]
  let backgroundColor=['#E74C3C','#F1C40F','#28B463']
  let labels = ['Por tamizar', 'Tamizado', 'Terminado']

  if(hasRole([GROUP_CLINIC,GROUP_MANAGER])){

    const por_tamizar = tamizajes.filter(t=>t.estado=='1').length
    const tamizado = tamizajes.filter(t=>t.estado=='2').length
    const terminado = tamizajes.filter(t=>t.estado=='3').length

    data=[por_tamizar,tamizado,terminado]
  }

  if(hasRole(GROUP_DOCTOR)){
    backgroundColor=['#E74C3C','#28B463']
    labels=['Lec. pendiente','Leído']
    const por_leer = tamizajes.filter(t=>t.estado=='2').length
    const leidos = tamizajes.filter(t=>t.estado=='3').length
    data=[por_leer,leidos]
  }

  if(hasRole(GROUP_TECHNICIAN)){
    backgroundColor=['#E74C3C','#28B463']
    labels=['Por tamizar','Tamizado']
    const por_tamizar = tamizajes.filter(t=>t.estado=='1').length
    const tamizado = tamizajes.filter(t=>t.estado=='2').length
    data=[por_tamizar,tamizado]
  }

    return (
            <Pie
        data={{
            labels ,
            datasets: [{
              label: '# de Tamizajes',
              data,
              borderWidth: 1,
              backgroundColor
            }]
        }}    
        options= {options}
        />
    )
}

export default TamizajesPorFecha