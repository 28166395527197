
import React, { useContext } from 'react'
import AsyncSelect from 'react-select/async';
import { REACT_SELECT_STYLES } from 'Contants';
import { API } from 'API';

const SelectClinic=({register,isClearable=false,isDisabled=false})=>{
    const loadOptions= async (query)=>{
        try{
            const {data} = await API('/cliente',{params:{nombre:query}})
            return data.results.map(p=>({value:p.id,label:p.razon_social}))
       }catch(error){
            return Promise.resolve([])
       }
       
    }

    return (
        <AsyncSelect isClearable={isClearable} isDisabled={isDisabled} {...register} placeholder='Buscar cliente' styles={REACT_SELECT_STYLES} loadOptions={loadOptions}/>
      )
}

export default SelectClinic