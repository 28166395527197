import { API } from "API"
import FormError from "Components/FormError"
import Loading from "Components/Loading"
import { GROUP_CLINIC, GROUP_MANAGER, TAMIZAJE_PRIORIDAD } from "Contants"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"
import { useForm  } from 'react-hook-form'

const DarPorTamizado=({estado,fk_tecnico,fotos,onReload,tamizaje_id})=>{
    const {user,hasRole} = useContext(SecurityCtx)
    const { register, handleSubmit, watch,setValue, control,formState: { errors } } = useForm();
    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading] = useState(false)

    if(!fotos.length){
        return null
    }

    if(['-1','0','2','3','4'].includes(estado)){
        return null
    }

    if(!fk_tecnico && !hasRole([GROUP_CLINIC,GROUP_MANAGER])){
        return null
    }

    const save= ()=>{
       
            showAlert({
                title:'Dar por tamizado!',
                html:'No podrás cargar más imágenes. ¿Quieres continuar?',
                showCancelButton:true,
                confirmButtonText:"Sí",
                cancelButtonText:"No"
            }).then(async ({isConfirmed})=>{
                if(isConfirmed){
                    try{
                        setLoading(true)
                        await API.patch(`coordinador/tamizaje/${tamizaje_id}/dar-por-tamizado/`)
                        onReload()
                    }catch(error){
                        showAlert({
                            icon:'error',
                            html:error?.response?.data?.detail || error.toString()
                        })
                    }finally{
                        setLoading(false)
                    }
                }
            })
            
        
    }

    if(loading){
        return <Loading message="Actualizando tamizaje"/>
    }

    return <div className="flex justify-end mt-6 print:hidden">
        <button type="button" onClick={save} className="bg-teal-400 border-[1px] border-salte-400 p-2 rounded-md">Dar por tamizado</button>
    </div>

   
}

export default DarPorTamizado