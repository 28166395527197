import axios from "axios"
import { BASE_URL, TOKEN } from "../Contants"

// console.log({BASE_URL})

export const API=axios.create({
    baseURL:BASE_URL,
})

API.interceptors.request.use(config => {
    
    const token=localStorage.getItem(TOKEN);
    if(token){
        config.headers['Authorization'] = 'Bearer '+token;
    }
    return config;
  });