import { API } from "API"
import FormError from "Components/FormError"
import Loading from "Components/Loading"
import { GROUP_CLINIC, GROUP_MANAGER, TAMIZAJE_PRIORIDAD } from "Contants"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"
import { useForm  } from 'react-hook-form'

const DarAlta=({dado_de_alta,id_impresion,onReload,paciente})=>{
    const {user,hasRole} = useContext(SecurityCtx)

    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading] = useState(false)

    if(dado_de_alta || !id_impresion){
        return null
    }


    const save= ()=>{
       
            showAlert({
                title:'Dar de alta!',
                html:'No podrás cargar modificar el tamizaje. ¿Quieres continuar?',
                showCancelButton:true,
                confirmButtonText:"Sí",
                cancelButtonText:"No"
            }).then(async ({isConfirmed})=>{
                if(isConfirmed){
                    try{
                        setLoading(true)
                        await API.patch(`coordinador/paciente/${paciente}/dar-alta/`)
                        onReload()
                    }catch(error){
                        showAlert({
                            icon:'error',
                            html:error?.response?.data?.detail || error.toString()
                        })
                    }finally{
                        setLoading(false)
                    }
                }
            })
            
        
    }

    if(loading){
        return <Loading message="Dando de alta..."/>
    }

    return <button className="p-2 py-4 rounded-md bg-slate-400/40 " onClick={save}>Dar de alta</button>

   
}

export default DarAlta