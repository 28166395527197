import { API } from 'API';
import { SecurityCtx } from 'Context/Security';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Pie } from 'react-chartjs-2';

const TamizajePorAseguradora=({tamizajes})=>{

    const {user,hasRole} = useContext(SecurityCtx)
    const [aseguradoras,setAseguradoras] = useState([])
    const [loading,setLoading] = useState(false)

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/aseguradora')
            setAseguradoras(data)
        }catch(error){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

    if(!tamizajes.length){
        return <p>No hay datos que procesar</p>
    }

    if(!aseguradoras.length){
        return null
    }

    let data = []
    aseguradoras.forEach((aseguradora)=>{
        const count = tamizajes.filter(t=>t.fk_paciente?.aseguradora == aseguradora.id).length
        data.push(count)
    })
    

    const options={
        plugins:{
        
            datalabels:{
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;
                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                      let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                      let percentage = Math.round((value / sum) * 100) + '%';
                      return percentage;
                    } else {
                      return 0;
                    }
                  },
                  color: '#fff',
            }
        
    }
    }


    return (
            <Pie
        data={{
            labels: aseguradoras.map(s=>s.nombre_aseguradora),
            datasets: [{
              label: '# de Tamizajes por aseguradoras',
              data:data,
              borderWidth: 1,
            }]
        }}    
        options={options}
        
        />
    )
}

export default TamizajePorAseguradora