import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TamizajesPorFecha from "./TamizajesPorFechas"
import {Chart, ArcElement} from 'chart.js'
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { API } from 'API';
import TamizajesPorSede from './TamizajesPorSede';
import TamizajesPorUsuario from './TamizajesPorUsuario';
import { SecurityCtx } from 'Context/Security';
import { GROUP_ADMIN, GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN } from 'Contants';
import PacientesPorCliente from './PacientePorCliente';
import TamizajePorAseguradora from './TamizajesPorAseguradora';
import TamizajeUso from './TamizajeUso';

Chart.register(ArcElement);
Chart.register(ChartDataLabels);

const Dashboard=()=>{
    const {hasRole,user} = useContext(SecurityCtx)
    const [start,setStart]=useState(dayjs().startOf('month').format('YYYY-MM-DD'))
    const [end,setEnd]=useState(dayjs().endOf('month').format('YYYY-MM-DD'))
    const [tamizajes,setTamizajes] = useState([])

    const loadTamizajes=async ()=>{
        try{
            const params={
                // fecha_desde: dayjs(start).startOf('days').toDate(),
                // fecha_hasta: dayjs(end).startOf('days').toDate(),
                fecha_desde:start,
                fecha_hasta:end,
            }
            console.log('Params loadTamizajes =>',params)

            if(hasRole(GROUP_DOCTOR)){
                params.id_medico = user.usuario_clinica
            }
            if(hasRole(GROUP_TECHNICIAN)){
                params.id_tecnico = user.usuario_clinica
            }
            const {data} = await API('coordinador/tamizaje/reporte/',{params})
            console.log('loadTamizajes =>',data)
            setTamizajes(data.detail)
        }catch(error){
            console.error(error)
        }finally{

        }
    }

  
    useEffect(()=>{
        if(user){
            if(hasRole([GROUP_CLINIC,GROUP_TECHNICIAN,GROUP_DOCTOR,GROUP_MANAGER])){
                loadTamizajes()
            }
          
        }
    },[user])

    return (<div className="p-4 mb-8 px-16">

        {hasRole(GROUP_ADMIN) && <PacientesPorCliente/>}

        
        {(hasRole(GROUP_MANAGER) || hasRole(GROUP_CLINIC) || hasRole(GROUP_TECHNICIAN) || hasRole(GROUP_DOCTOR)) && (
            <>
            <div className=' mb-6 border-[1px] border-slate-400/40 p-8'>
            <div className='flex items-center lg:flex-row sm:flex-col md:flex-col'>
                <div className='lg:mr-2 sm:mb-2 md:mb-2 sm:flex-1 md:flex-1'>
                    <label className='text-md mb-2 block'>Fecha inicio</label>
                    <input type="date" onChange={(v) => setStart(v.value)} value={start} className='w-full bg-slate-400/40 rounded-md p-[4px] border-none'/>
                </div>
                <div className='lg:mr-2 sm:mb-2 md:mb-2 sm:flex-1 md:flex-1'>
                    <label className='text-md mb-2 block'>Fecha fin</label>
                    <input type="date" onChange={(v)=>setEnd(v.value)} value={end} className='w-full bg-slate-400/40 rounded-md p-[4px] border-none'/>
                </div>
            </div>
            <div className='flex justify-end'>
            <button onClick={loadTamizajes} className='p-2 bg-slate-400/40 rounded-md border-[1px] border-slate-600'>Recargar</button>
            </div>
        </div>
        <div className=' mb-6 border-[1px] border-slate-400/40 p-8'>
            <div className='h-[200px]'>
                <h3 className='text-center'>Uso</h3>
                <TamizajeUso tamizajes={tamizajes}/>
            </div>
        </div>
        <div className='grid grid-cols-3 grid-rows-1 gap-2 mb-6 border-[1px] border-slate-400/40 p-8 rounded-md'>
            <div>
                <h3 className='text-center'>Tamizajes</h3>
                <TamizajesPorFecha tamizajes={tamizajes}/>
            </div>
            <div>
                <h3 className='text-center'>Por sede</h3>
                <TamizajesPorSede tamizajes={tamizajes}/>
            </div>
            <div>
                <h3 className='text-center'>Por aseguradora</h3>
                {hasRole([GROUP_CLINIC,GROUP_MANAGER]) && <TamizajePorAseguradora tamizajes={tamizajes}/> }
            </div>
            
        </div></>)}

        
        
        {(hasRole(GROUP_CLINIC) || hasRole(GROUP_MANAGER)) && <TamizajesPorUsuario tamizajes={tamizajes}/>}
       
        
        
        
    </div>)
}

export default Dashboard