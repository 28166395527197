import { API } from "API"
import Loading from "Components/Loading"
import { useEffect, useState } from "react"

const PacientesPorCliente=()=>{
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)

    const load= async ()=>{
        try{
            setLoading(true)
            const {data} = await API('coordinador/paciente/pacientes-por-cliente/')
            setItems(data.detail)
        }catch(error){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])
    return (<>
        <h1 className="mb-4 text-lg">Pacientes por cliente</h1>
        {loading && <Loading/>}
        <div className="grid gap-2 grid-cols-[repeat(auto-fit,200px)] place-self-center">
            {items.map((item,i)=><div key={i} className="bg-slate-400/40 p-4 flex flex-col text-center rounded-lg">
                <span className="text-slate-200 text-md font-bold">{item.clinica}</span>
                <span className="text-slate-200 text-2xl font-extrabold">{item.pacientes}</span>
            </div>)}
        </div>
    </>)
}

export default PacientesPorCliente