import React, { useContext } from 'react'
import {IoSyncOutline} from "react-icons/io5" 
import AsyncSelect from 'react-select/async';
import { GROUP_TECHNICIAN, REACT_SELECT_STYLES } from 'Contants';
import { API } from 'API';
import { SecurityCtx } from 'Context/Security';

const SelectUsuarioClinica = ({register={},rol=GROUP_TECHNICIAN}) => {

    const {user} = useContext(SecurityCtx)
    const {id_clinica:clinica_id} = user || {}

    const loadOptions= async (query)=>{
        try{
            const {data} = await API('/clinica/usuario/?nombre='+query+'&clinica_id='+clinica_id+"&rol="+rol)
            return data.map(p=>({value:p.id,label:p.fk_user.first_name+' '+p.fk_user.last_name}))
       }catch(error){
            return Promise.resolve([])
       }
       
    }

  return (
    <AsyncSelect {...register} placeholder='Buscar usuario' styles={REACT_SELECT_STYLES} loadOptions={loadOptions}/>
  )
}

export default SelectUsuarioClinica
