import React, { useContext, useEffect, useState } from 'react'
import {TbBread} from "react-icons/tb"
import {FaUserAlt} from "react-icons/fa"
import { Link, useLocation } from 'react-router-dom'
import { BASE_URL, GROUP_ADMIN, GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN } from '../../Contants'
import { SecurityCtx } from '../../Context/Security'
import FullName from '../FullName'

const Header = ({titulo,fecha_hora,usuario}) => {
  
  const location=useLocation()
  const current_page=location.pathname
  const {user,hasRole} = useContext(SecurityCtx)
  const [photo,setPohoto] = useState(null)

  const onImgError=()=>{
    setPohoto(null)
  }

  useEffect(()=>{
    if(user){
      setPohoto(user?.foto_user)
    }
  },[user])
  

  if (user === null) return null

 
  const roles=[]
  if(hasRole(GROUP_CLINIC)){
    roles.push("Cliente")
  }
  if(hasRole(GROUP_ADMIN)){
    roles.push("Admin")
  }
  if(hasRole(GROUP_DOCTOR)){
    roles.push("Médico")
  }
  if(hasRole(GROUP_TECHNICIAN)){
    roles.push("Técnico")
  }
  if(hasRole(GROUP_MANAGER)){
    roles.push("Coordinador")
  }

 

  const breadCrumb=()=>{
    const crumbs=[]

    if(/\/admin\/clinics\/[0-9]+\/users/.test(current_page)){
      crumbs.push({
        link:'/admin/clinics',
        label:'Clientes'
      })
      crumbs.push({
        label:'Equipo de trabajo'
      })
    }

    if(current_page=='/dashboard'){
      crumbs.push({label:'Panel de control'})
    }

    if(current_page=='/manager/patient/'){
      crumbs.push({label:'Pacientes'})
    }

    //-------------Admin-----------------
    if(current_page=='/admin/country'){
      crumbs.push({label:'Configuración regional'})
    }
    if(current_page=='/admin/clinics'){
      crumbs.push({label:'Clientes'})
    }
    if(current_page=='/admin/rop'){
      crumbs.push({label:'ROP estadios'})
    }
    if(current_page=='/admin/risk'){
      crumbs.push({label:'Factores de riesgos'})
    }
    if(current_page=='/admin/medical-plan'){
      crumbs.push({label:'Planes médicos'})
    }
    if(current_page=='/admin/roles'){
      crumbs.push({label:'Roles y Permisos'})
    }
  
    if(/\/admin\/roles\/[0-9]+/.test(current_page)){
      crumbs.push({
        link:'/admin/roles',
        label:'Roles y Permisos'
      })
      crumbs.push({
        label:'Actualizar'
      })
    }
    if(/\/admin\/roles\/create/.test(current_page)){
      crumbs.push({
        link:'/admin/roles',
        label:'Roles y Permisos'
      })
      crumbs.push({
        label:'Nuevo'
      })
    }


    if(/\/admin\/clinics\/[0-9]+\/edit/.test(current_page)){
      crumbs.push({
        link:'/admin/clinics',
        label:'Clientes'
      })
      crumbs.push({
        label:'Actualziar'
      })
    }
    if('/admin\/clinics/add'==current_page){
      crumbs.push({
        link:'/admin/clinics',
        label:'Clientes'
      })
      crumbs.push({
        label:'Nuevo'
      })
    }
    if(['/admin/patient'].includes(current_page)){
      crumbs.push({label:'Pacientes'})
    }
  
    if(/\/admin\/patient\/[0-9]+\/detail/.test(current_page)){
      console.log(current_page.match(/\/admin\/patient\/[0-9]+\/detail/))
      crumbs.push({
        link:'/admin/patient',
        label:'Pacientes'
      })
      crumbs.push({
        label:'Detalle'
      })
    }

    if(/\/admin\/patient\/[0-9]+\/tamizajes\/[0-9]+\/detail$/.test(current_page)){
      const parts=current_page.match(/\/admin\/patient\/([0-9]+)\/tamizajes\/([0-9]+)\/detail$/)

      crumbs.push({
        label:'Pacientes',
        link:'/admin/patient'
      })

      crumbs.push({
        link:'/admin/patient/'+parts[1]+'/detail',
        label:'Detalle'
      })
      
      crumbs.push({
        label:'Tamizaje'
      })
    }

    //-------------Fin admin-----------

    //---manager
    if(current_page=='/manager/tamizajes'){
      crumbs.push({
        label:'Tamizajes'
      })
    }

    if(/\/manager\/tamizajes\/[0-9]+\/detail/.test(current_page)){
      crumbs.push({
        link:'/manager/tamizajes',
        label:'Tamizajes'
      })
      crumbs.push({
        label:'Detalle'
      })
    }
    if(/\/manager\/agenda/.test(current_page)){
      crumbs.push({
        label:'Agenda'
      })
    }
    if(/\/manager\/patient$/i.test(current_page)){
      crumbs.push({
        label:'Pacientes'
      })
    }
    if(/\/manager\/patient\/[0-9]+\/update$/.test(current_page)){
      crumbs.push({
        label:'Pacientes',link:'/manager/patient'
      })
      crumbs.push({
        label:'Actualizar'
      })
    }
    if('/manager/patient/create'==current_page){
      crumbs.push({
        label:'Pacientes',link:'/manager/patient'
      })
      crumbs.push({
        label:'Nuevo'
      })
    }

    if(/\/manager\/patient\/[0-9]+\/detail$/.test(current_page)){
      crumbs.push({
        link:'/manager/patient',
        label:'Pacientes'
      })
      crumbs.push({
        label:'Detalle'
      })
    }

    if('/manager/tamizaje/create'==current_page){
      crumbs.push({
        link:'/manager/tamizajes',
        label:'Tamizajes'
      })
      crumbs.push({
        label:'Nuevo'
      })
    }

    if(/\/manager\/patient\/[0-9]+\/tamizajes\/[0-9]+\/detail$/.test(current_page)){
      
      const parts=current_page.match(/\/manager\/patient\/([0-9]+)\/tamizajes\/([0-9])+\/detail$/)

      crumbs.push({
        link:'/manager/patient',
        label:'Pacientes'
      })
      crumbs.push({
        link:'/manager/patient/'+parts[1]+'/detail',
        label:'Detalle'
      })
      crumbs.push({
        label:'Tamizaje'
      })
    }
    if(/\/manager\/sites$/.test(current_page)){
      crumbs.push({
        label:'Sedes'
      })
    }
    if(/\/manager\/team$/.test(current_page)){
      crumbs.push({
        label:'Equipo de trabajo'
      })
    }
    if(/\/manager\/team\/[0-9]+\/update$/.test(current_page)){
      crumbs.push({
        label:'Equipo de trabajo',
        link:'/manager/team'
      })
      crumbs.push({
        label:'Actualizar'
      })
    }
    if(/\/manager\/team\/create$/.test(current_page)){
      crumbs.push({
        label:'Equipo de trabajo',
        link:'/manager/team'
      })
      crumbs.push({
        label:'Nuevo'
      })
    }
    
    //---fin manager

    if('/change-password'==current_page){
      crumbs.push({
        label:'Actualizar contraseña'
      })
    }
    

    return <nav  aria-label="Breadcrumb" className='flex'>
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <Link to='/dashboard' className='inline-flex items-center text-md font-medium text-gray-100 hover:text-gray-400 dark:text-gray-400 dark:hover:text-white'>
                  <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                  Inicio
                </Link>
              </li>
            {crumbs.map((c,i)=>(<Crumb {...c} key={i}/>))}
            </ol>
          </nav>
  }

  const Crumb=({link,label})=>{
    if(link){
      return <li className='inline-flex items-center'>
          <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          <Link className='ml-1 text-md font-medium text-gray-100 hover:text-gray-400 md:ml-2 dark:text-gray-400 dark:hover:text-white' to={link}>{label}</Link>
        </li>
    }
    return <li><div class="flex items-center">
      <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
      <span class="ml-1 text-md font-medium text-gray-200 md:ml-2 dark:text-gray-400">{label}</span>
    </div></li>
  }
  
  return (
    <>
    <div className='flex-row flex items-center  justify-between px-4 py-4 border-b-[1px] border-b-slate-800'>
        {breadCrumb()}

        <div className='flex flex-col'>
          <div className='flex-row flex items-center justify-end'>
            <p className='mr-2'>{`${user.first_name} ${user.last_name}`}</p>
            {photo ? <img src={`${photo}`} onError={onImgError} className='w-10 h-10 rounded-full'/> : <FaUserAlt className='mr-2'/>}
            
          </div>
          <small className='text-slate-400'><FullName names={roles}/> {hasRole(GROUP_ADMIN) ? 'VART' : `en ${user.nombre_clinica}`}</small>
        </div>
        
    </div>
    
    </>
  )
}

export default Header
