import React, { useContext, useEffect } from "react";
import Pagination from "Components/Pagination";
import { AiFillCalendar } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { Link, useAsyncError, useNavigate } from "react-router-dom";
import { API } from "API";
import { useState } from "react";
import { SecurityCtx } from "Context/Security";
import { TamizajeCtx } from "Context/Tamizaje";
import dayjs from "dayjs";
import { IoAddCircle } from "react-icons/io5";
import FullName from "Components/FullName";
import { GROUP_CLINIC, GROUP_MANAGER, PERMISO_AGREGAR_TAMIZAJE, PERMISO_VER_TAMIZAJES } from "Contants";
import Filters from "./Filters";
import TamizajeStatus from "Components/TamizajeStatus";
import TamizajePrioridad from "Components/TamizajePrioridad";
import { UiCtx } from "Context/Ui";
import Export from "./Export";
import Policy from "Components/Policy";
import {BiSearchAlt} from "react-icons/bi"

const moment = require("moment");

const Index = () => {
  const navigation = useNavigate();
  
  const [tamizajes, setTamizajes] = useState([]);
  const { user ,hasRole} = useContext(SecurityCtx);
  const { records, count, paginate, error, filter, loading,params } =useContext(TamizajeCtx);


  const onPage = (p) => {
    paginate(p);
  };

  useEffect(()=>{
    filter({t:new Date()})
  },[])

  

 
  

  return (
    <>
      <div className="mt-4 p-4">
        <div className="flex  justify-between">
         
          <Filters/>
          
          <div className=" border-slate-400/40 p-2 border-[1px]">
            <Policy show_error={false} policies={[PERMISO_AGREGAR_TAMIZAJE]}>
              <Link to='/manager/tamizaje/create' className="p-[4px] px-2 w-full block text-center mb-2 bg-teal-500 rounded-md hover:bg-teal-500/90">Agregar Tamizaje</Link>
            </Policy>
            <Export/>
          </div>
          
            
        </div>
<div className="mt-[20px]">

        <table>
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Prioridad</th>
              <th>No. HC</th>
              <th>Paciente</th>
              <th>Acudiente</th>
              <th>Sede</th>
              <th>Cupo adicional</th>
              <th>Fecha</th>
              <th>Médico</th>
              <th>Técnico</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {records.map((tamizaje) => (
              <tr
              className=" hover:bg-slate-400/30"
              
                key={tamizaje.id}
              >
                <td className='cursor-pointer hover:scale-150' onClick={()=>navigation(`/manager/tamizajes/${tamizaje.id}/detail`)}>
                    <BiSearchAlt/>
                </td>
                <td className="text-lg text-teal-400 font-extrabold">{tamizaje.id}</td>
                <td><TamizajePrioridad>{tamizaje.prioridad}</TamizajePrioridad></td>
                <td>{tamizaje.fk_paciente.historia_clinica}</td>
                
                <td>
                  <FullName names={[tamizaje.fk_paciente.primer_nombre_paciente,tamizaje.fk_paciente.segundo_nombre_paciente,tamizaje.fk_paciente.primer_apellido_paciente,tamizaje.fk_paciente.segundo_apellido_paciente]} />
                </td>
                <td>
                  <FullName names={[tamizaje.fk_paciente.primer_nombre_acudiente,tamizaje.fk_paciente.segundo_nombre_acudiente,tamizaje.fk_paciente.primer_apellido_acudiente,tamizaje.fk_paciente.segundo_apellido_acudiente]} />
                  <small className='block'>CC. {tamizaje?.fk_paciente?.id_acudiente}</small>
                </td>
                <td>{tamizaje.fk_sede.nombre}</td>
                <td>{tamizaje.adicional ? <span className="bg-teal-500 shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] w-[32px] h-[32px] flex items-center justify-center  rounded-full text-md">Sí</span>:'No'}</td>
                <td>
                  {moment(tamizaje.fecha_atencion).format("DD/MM/YYYY")}
                </td>
                <td>
                  {tamizaje?.fk_medico?.fk_user?.first_name} {tamizaje?.fk_medico?.fk_user?.last_name}
                </td>
                <td>
                  {tamizaje?.fk_tecnico?.fk_user?.first_name} {tamizaje?.fk_tecnico?.fk_user?.last_name}
                </td>
                <td><TamizajeStatus>{tamizaje.estado}</TamizajeStatus></td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination records={count} onPage={onPage} />
</div>
      </div>
    </>
  );
};

const TamizajeList=()=>{
  return <Policy policies={[PERMISO_VER_TAMIZAJES]}>
    <Index/>
  </Policy>
}

export default TamizajeList;
