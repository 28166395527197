import Policy from "Components/Policy"
import { PERMISO_VER_CONFIGURACION_REGIONAL } from "Contants"
import React, { useEffect, useState } from "react"
import Citys from "./Citys"
import Countries from "./Countries"
import States from "./States"

const Country=()=>{

    const [country_selected,setCountrySelected]=useState(null)
    const [state_selected,setStateSelected]=useState(null)

    

    useEffect(()=>{
        if(!country_selected){
            setStateSelected(null)
        }else{
            setStateSelected(null)
        }
    },[country_selected])

    return <Policy policies={[PERMISO_VER_CONFIGURACION_REGIONAL]}>
            <div className="flex h-[calc(100vh_-_80px)] overflow-hidden">
                <Countries country_selected={country_selected} setCountrySelected={setCountrySelected}/>
                <States state_selected={state_selected} country={country_selected} setStateSelected={setStateSelected}/>  
                <Citys state={state_selected}/>
            </div>
    </Policy>
}

export default Country