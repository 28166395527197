import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import React,{ useContext, useEffect, useRef, useState } from 'react'
import { API } from 'API'
import dayjs from 'dayjs'
import { UiCtx } from 'Context/Ui'
import { Link } from 'react-router-dom'
import { SecurityCtx } from 'Context/Security'
import { GROUP_DOCTOR, GROUP_TECHNICIAN } from '../../Contants'
import TamizajeStatus from '../../Components/TamizajeStatus'
import FullName from '../../Components/FullName'
import Policy from 'Components/Policy'

const AgendaProtected=({initialView='dayGridMonth',initialDate='',sede=''})=>{
    const [events,setEvents] = useState([])
    const {showAlert} = useContext(UiCtx)
    const {user,hasRole} = useContext(SecurityCtx)
    const [params,setParams] = useState({})
    const calendarRef  = useRef()

    const load= async ()=>{
        try{
            const {data} = await API.get(`coordinador/agenda/`,{params})
            setEvents(data.map(d=>{
                const start = dayjs(d.fecha_atencion).toDate()
                const end = dayjs(d.fecha_atencion).add(2,'hours').toDate()
                const paciente=[d.fk_paciente.primer_nombre_paciente,d.fk_paciente.segundo_nombre_paciente,d.fk_paciente.primer_apellido_paciente,d.fk_paciente.segundo_apellido_paciente].filter(p=>p).join(" ")
                return {
                    id:d.id,
                    date:start,
                    title:d.estado+'#'+d.fk_medico?.fk_user?.first_name+' '+d.fk_medico?.fk_user?.last_name+'#'+d.fk_tecnico?.fk_user?.first_name+' '+d.fk_tecnico?.fk_user?.last_name+'#'+paciente
                }
                
            }))
        }catch(er){
            showAlert({html:er?.response?.data?.detail || er.toString()})
        }
    }

    const datesSet=(info)=>{
        const start = dayjs(info.start).startOf('day').toDate()
        const end = dayjs(info.end).endOf('day').toDate()
        setParams(prev=>({...prev,start,end}))
    }

    useEffect(()=>{
        if(calendarRef.current){
            let calendarApi = calendarRef.current.getApi()
     
            if(initialDate!=''){
                calendarApi.gotoDate(initialDate)
            }
        }
   },[calendarRef,initialDate])

   useEffect(()=>{
    setParams(prev=>({...prev,sede}))
   },[sede])

    useEffect(()=>{
        if(user){
            if(hasRole(GROUP_TECHNICIAN)){
                setParams(prev=>({...prev,id_tecnico:user.usuario_clinica,ready:true}))
            }else if(hasRole(GROUP_DOCTOR)){
                setParams(prev=>({...prev,id_medico:user.usuario_clinica,ready:true}))
            }else{
                setParams(prev=>({...prev,ready:true}))
            }

        }
    },[user])

    useEffect(()=>{
        if(params.ready && params.start){
            load()
        }
    },[params])

    return (<div className='p-2'>
        <FullCalendar  
            ref={calendarRef}
            locale='es'
            initialView={initialView}
            plugins={[ dayGridPlugin ]}
            events={events}
            datesSet={datesSet}
            eventContent={renderEventContent}
        />
    </div>)
}

const Agenda=(props)=>{
    return <Policy policies={['view_tamizage']}>
        <AgendaProtected {...props}/>
    </Policy>
}

function renderEventContent(eventInfo) {

    const [estado,medico,tecnico,paciente]=eventInfo.event.title.split("#")
    return (
        
      <Link className={`p-[8px]  flex flex-col break-all rounded-md w-[90%] m-auto break-word bg-slate-400/40`} onClick={()=>window.open(`/tamizajes/${eventInfo.event.id}/detail`,"ModalPopUp",

      "toolbar=no," +
  
      "scrollbars=yes," +
  
      "location=no," +
  
      "statusbar=no," +
  
      "menubar=no," +
  
      "resizable=0," +
  
      "width=800," +
  
      "height=800," +
  
      "left = 490," +
  
      "top=300")}>
        
            <TamizajeStatus>{estado}</TamizajeStatus>
            <small className='whitespace-normal text-teal-300 mb-[4px]'>{paciente}</small>
            <small className='whitespace-normal text-teal-400'>Med. {medico}</small>
            <small className='whitespace-normal text-teal-500'>Tec. {tecnico}</small>
       

      </Link>
    )
}

export default Agenda