import { useMemo } from "react";

const FullName=({names})=>{
    const fullname = useMemo(()=>{
        return names.filter(n=>n).join(" ")
    },[names])

    return fullname
}

export default FullName