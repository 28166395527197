import { useEffect } from "react"
import { useContext } from "react"
import { useRef } from "react"
import { useState } from "react"
import { API } from "../../../API"
import { BASE_URL, GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN, PERMISO_AGREGAR_FOTO } from "../../../Contants"
import {SecurityCtx} from "../../../Context/Security/index"
import {FiChevronLeft,FiChevronRight} from "react-icons/fi"
import { MySwal, UiCtx } from "Context/Ui"
import Loading from "../../Loading"
import Policy from "Components/Policy"

const TamizajeDetailPhotos=({id_tamizaje,photos=[],reload})=>{

    const {user,hasRole} = useContext(SecurityCtx)
    const {showAlert} = useContext(UiCtx)

    const file_left=useRef(null)
    const file_right=useRef(null)
    const [index_left,setIndexLeft]=useState(0)
    const [index_right,setIndexRight]=useState(0)
    const left_photos = photos.filter(p=>(p.ojo=='OI'))
    const right_photos = photos.filter(p=>(p.ojo=='OD'))
    const [loading,setLoading]=useState(false)
    const [loadingAI,setLoadingAI]=useState(false)
    const [loadingAIs,setLoadingAIs]=useState(false)
    const [analisis,setAnalisis] = useState([])
    const [result,setResult] = useState({oi:null,od:null})

    const left_photo = left_photos[index_left]
    const right_photo = right_photos[index_right]
  
   

    const upload= async (ojo,foto_original)=>{
        setLoading(true)
        try{
            const {data} = await API.post('gestion_tecnico/foto/subir/',
            {
                id_tamizaje,
                foto_original,
                ojo,
                ultima_foto:false
            }
            )
            reload()
            showAlert({
                icon:'success',
                title:'Subida completada',
                html:data?.detail
            })
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()        
            })
        }finally{
            setLoading(false)
        }
    }

    const analyzerList  = async ()=>{
        try{
            setLoadingAIs(true)
            const {data} = await API('gestion_medico/impresion/ia-resultados/',{params:{tamizaje_id:id_tamizaje}})
            setAnalisis(data?.resultado)
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoadingAIs(false)
        }
    }

    const analyzer=async ()=>{
        try{
            setLoadingAI(true)
            const {data} = await API.post('gestion_medico/impresion/ia/',{id_oi:left_photo?.id,id_od:right_photo?.id,tamizaje_id:id_tamizaje})
            analyzerList()
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoadingAI(false)
        }
    }

    const nextLeft=()=>{
        if(index_left+1<left_photos.length){
            setIndexLeft(prev=>prev+1)
        }
    }

    const prevLeft=()=>{
        if(index_left-1>=0){
            setIndexLeft(prev=>prev-1)
        }
    }

    const nextRight=()=>{
        setIndexRight(prev=>{
            if(prev+1<right_photos.length){
                return prev+1
            }
            return prev
        })
    }

    const prevRight=()=>{
        setIndexRight(prev=>{
            if(prev-1>=0){
                return prev-1
            }
            return prev
        })
    }

    function getBase64(file) {
       return new Promise((resolve,reject)=>{
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
         
        };
        reader.onerror = function (error) {
          reject(error);
        };
       })
    }

    useEffect(()=>{
        analyzerList()
    },[])

    useEffect(()=>{
        if(analisis.length){
            const analisi = analisis[analisis.length-1]
            setResult({
                oi:analisi.resultadoOI,
                od:analisi.resultadoOD
            })
            const oii=left_photos.findIndex(p=>p.id==analisi.id_oi)
            const odi=right_photos.findIndex(p=>p.id==analisi.id_od)

            if(oii>-1){
                setIndexLeft(oii)
            }
            if(odi>-1){
                setIndexRight(odi)
            }
            console.log({analisi})
        }
    },[analisis])

    useEffect(()=>{
        if(file_left.current){
            file_left.current.onchange = async (e)=>{
                try{
                    const foto_original = await getBase64(e.target.files[0])
                    upload('OI',foto_original)
                }catch(error){
                    showAlert({
                        icon:'error',
                        html:error?.response?.data?.detail || error.toString()
                    })
                }finally{
                    e.target.value=''
                }
            }
        }
    },[file_left])

    useEffect(()=>{
        if(file_right.current){
            file_right.current.onchange = async (e)=>{
                try{
                    const foto_original = await getBase64(e.target.files[0])
                    upload('OD',foto_original)
                }catch(error){
                    showAlert({
                        icon:'error',
                        html:error?.response?.data?.detail || error.toString()
                    })
                }finally{
                    e.target.value=''
                }
            }
        }
    },[file_right])

    if(loading){
        return <Loading height="10vh" message="Subiendo imagen, espere un momento por favor..."/>
    }

    if(loadingAI){
        return <Loading height="10vh" message="Analizando imagenes, espere un momento por favor..."/>
    }



    return <div className="flex-1 ">
   
    <div className="flex lg:flex-row sm:flex-col md:flex-col
    
     items-stretch justify-between">
      <div className="flex-1 mr-2">
        <div className="flex justify-between mb-2">
            <h3  className="print:text-slate-700">Ojo izquierdo</h3>
            <div className="flex items-center">
                <button type="button" className="hover:bg-slate-500/40 p-[4px] rounded-full" onClick={prevLeft}><FiChevronLeft/></button>
                <span className="mx-4">{index_left+1} / {left_photos.length}</span>
                <button  type="button" className="print:hidden hover:bg-slate-500/40 p-[4px] rounded-full" onClick={nextLeft}><FiChevronRight/></button>
            </div>
            <Policy show_error={false} policies={[PERMISO_AGREGAR_FOTO]}>
                <button  type="button" className="text-sm hover:bg-slate-500/40 p-[2px] px-[4px] rounded-md relative cursor-pointer print:hidden">
                    <input type='file' multiple={false} accept="image/*" ref={file_left} className=" absolute w-full h-full top-[0px] left-[0px] opacity-0"/>Subir +
                </button>
            </Policy>
        </div>
        <div className="mb-2">
            {left_photo && <img className="max-w-[100%]" src={`${left_photo.fotos_original}`}/>}
        </div>
        {loadingAIs ? <p>Cargando análisis previos</p> : <p className="flex items-center bg-slate-400/10 rounded-full p-1 print:hidden">
            <span className="w-[32px] h-[32px] justify-center items-center flex bg-teal-500 rounded-full text-sm mr-2">IA</span> {result.oi ? result.oi : 'No ha procesado'}
        </p>}
      </div>

      <div className="flex-1 ml-2">
        <div className="flex justify-between mb-2">
            <h3 className="print:text-slate-700">Ojo derecho</h3>
            <div className="flex items-center">
                <button  type="button" className="hover:bg-slate-500/40 p-[4px] rounded-full print:hidden" onClick={prevRight}><FiChevronLeft/></button>
                <span className="mx-4">{index_right+1} / {right_photos.length}</span>
                <button  type="button" className="hover:bg-slate-500/40 p-[4px] rounded-full print:hidden" onClick={nextRight}><FiChevronRight/></button>
            </div>
            <Policy show_error={false} policies={[PERMISO_AGREGAR_FOTO]}>
                <button  type="button" className="text-sm hover:bg-slate-500/40 p-[2px] px-[4px] rounded-md relative cursor-pointer print:hidden">
                    <input type='file' multiple={false} accept="image/*" ref={file_right} className=" absolute w-full h-full top-[0px] left-[0px] opacity-0"/>Subir +
                </button>
            </Policy>
        </div>
        <div className="mb-2">
            {right_photo && <img className="max-w-[100%]" src={`${right_photo.fotos_original}`}/>}
        </div>

            {loadingAIs ? <p>Cargando análisis previos</p> : <p className="flex items-center bg-slate-400/10 rounded-full p-1 print:hidden">
            <span className="w-[32px] h-[32px] justify-center items-center flex bg-teal-500 rounded-full text-sm mr-2">IA</span> 
            {result.od ? result.od : 'No ha procesado'}
            </p>}
      </div>
    </div>
    {hasRole([GROUP_DOCTOR]) && left_photos.length && right_photos.length ? <div className="print:hidden mt-4 flex items-center justify-end">
        <p className="text-sm text-slate-400">Escoge la mejor imagen de cada ojo para:</p>
        <button onClick={analyzer}  type="button" className="p-[4px] rounded-sm bg-teal-500 ml-2 ease-in-out duration-300 hover:scale-110">Iniciar análisis</button>
    </div> : null}
    
  </div>
}

export default TamizajeDetailPhotos