import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Components/Header";
import Input from "../../../Components/Input";
import { FaUserCircle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API } from "../../../API";
import Card from "../../../Components/Card";
import { UiCtx } from "../../../Context/Ui";
import Policy from "Components/Policy";
import {PERMISO_AGREGAR_CLIENTES, PERMISO_VER_CLIENTES, TIPO_PERSONA, TIPO_SOCIEDAD} from "Contants"
import FormError from "Components/FormError";
import SelectTipoDocumento from "Components/SelectTipoDocumento";
import SelectCountry from "Components/SelectCountry";
import SelectDepartamento from "Components/SelectDepartament";
import SelectCity from "Components/SelectCity";
import SelectCiiu from "Components/SelectCIIU";
import Loading from "Components/Loading";

const Index = () => {

    const { register, handleSubmit,setValue, watch, formState: { errors } } = useForm();
    const {id} = useParams()
    
    const navigate=useNavigate()
    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading] = useState(false)

    const country = watch('fk_pais')
    const state = watch('fk_departamento')
    

    const sendClinic =async(data)=>{

   
        try{
            const method = id ? "PATCH" : "POST"
            const url= id ? `/cliente/${id}/actualizar/` : "/cliente/crear/"
            await API({
              url,
              method,
              data
            })
            navigate('/admin/clinics')
            showAlert({html:"Se ha guardado correctamente",icon:'success'})

        }catch(error){
          showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'})
        }

    }

    const load= async ()=>{
      try{
        setLoading(true)
        const {data} = await API(`cliente/${id}/`)
      
        Object.keys(data).forEach((key)=>{
          let value=data[key]
          if(key=='fk_ciiu'){
            value=value.map(v=>v.toString())
          }
          setValue(key,value)
        })

      
       
 
      }catch(error){

      }finally{
        setLoading(false)
      }
    }

 

    useEffect(()=>{
      if(country==''){
        setValue('fk_departamento','')
        setValue('fk_ciudad','')
      }
    },[country])

    useEffect(()=>{
      if(id){
        load()
      }
    },[id])

    if(loading){
      return <Loading/>
    }


  return (
    <div className="p-4">
      <form onSubmit={handleSubmit(sendClinic)}>

      
            
      <div className="mt-6 border-[1px] border-slate-400/20 rounded-md p-2">
            <div className="flex lg:flex-row sm:flex-col items-center">
              <div className="flex-1 lg:mr-2 sm:mb-2">
              <Input descripcion="Razón social"  register={register("razon_social",{required:true})}/>
              <FormError errors={errors} name='razon_social'/>
              </div>
              <div className="flex-1 lg:mr-2 sm:mb-2">
                <label className="text-md mb-2 block">Tipo de persona *</label>
                <select className="rounded-md  bg-[#2f3855] pb-[5px] p-2 flex-1 w-full " {...register("tipo_persona",{required:true})}>
                  <option value="">Seleccione</option>
                  {TIPO_PERSONA.map((t,i)=>(<option value={t.value} key={i}>{t.label}</option>))}
                </select>
                <FormError errors={errors} name='tipo_persona'/>
              </div>
              <div className="flex-1 lg:mr-2 sm:mb-2">
                <label className="text-md mb-2 block">Tipo de sociedad *</label>
                <select className="rounded-md  bg-[#2f3855] pb-[5px] p-2 flex-1 w-full " {...register("tipo_sociedad",{required:true})}>
                  <option value="">Seleccione</option>
                  {TIPO_SOCIEDAD.map((t,i)=>(<option value={t.value} key={i}>{t.label}</option>))}
                </select>
                <FormError errors={errors} name='tipo_sociedad'/>
              </div>
              <div className="flex-1 lg:mr-2 sm:mb-2">
              <Input descripcion="Número identificación (NIT)"  register={register("nit",{required:true})}/>
              <FormError errors={errors} name='nit'/>
              </div>
            </div>

            <div className="flex lg:flex-row sm:flex-col">
              <div className="flex-1 lg:mr-2 sm:mb-2">
                <SelectCiiu register={register("fk_ciiu",{required:true})}/>
                <FormError errors={errors} name='fk_ciiu'/>
              </div>
              <div className="flex-1 lg:mr-2 sm:mb-2">
                <Input descripcion="Celular" type="number" register={register("celular",{required:true,minLength:{value:10,message:'Mínimo 10 dígitos'}})}/>
                <FormError errors={errors} name='celular'/>
              </div>
            </div>
            </div>
            
            <div className="mt-6 border-[1px] border-slate-400/20 rounded-md p-2">
              <h2 className="text-lg mb-4">Dirección</h2>
              <div className="flex lg:flex-row sm:flex-col">
                <div className="flex-1 lg:mr-2 sm:mb-2">
                  <SelectCountry register={register('fk_pais',{required:true})}/>
                  <FormError errors={errors} name='fk_pais'/>
                </div>
                <div className="flex-1 lg:mr-2 sm:mb-2">
                  <SelectDepartamento pais={country} register={register('fk_departamento',{required:true})}/>
                  <FormError errors={errors} name='fk_departamento'/>
                </div>
                <div className="flex-1 lg:mr-2 sm:mb-2">
                  <SelectCity dep={state} register={register('fk_ciudad',{required:true})}/>
                  <FormError errors={errors} name='fk_ciudad'/>
                </div>
                
              </div>

              <div className="flex lg:flex-row sm:flex-col">
                <div className="flex-1 lg:mr-2 sm:mb-2">
                    <label className="text-md mb-2 block">Barrio *</label>
                    <Input register={register('barrio',{required:true})}/>
                    <FormError errors={errors} name='barrio'/>
                  </div>
                  <div className="flex-1 lg:mr-2 sm:mb-2">
                      <Input descripcion="Dirección *" register={register("direccion",{required:true})}/>
                      <FormError errors={errors} name='direccion'/>
                  </div>
              </div>
            </div>

           

            <div className="mt-6 border-[1px] border-slate-400/20 rounded-md p-2">
              <h2 className="text-lg mb-4">Representante</h2>

              <div className="flex lg:flex-row sm:flex-col">
                <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <Input descripcion="Nombre *"  register={register("nombre_representante",{required:true})}/>
                    <FormError errors={errors} name='nombre_representante'/>
                  </div>
                  <div className="flex-1 lg:mr-2  sm:mb-2 md:mb-2">
                    <Input descripcion="Dirección *"  register={register("direccion_representante",{required:true})}/>
                    <FormError errors={errors} name='direccion_representante'/>
                  </div>
                  <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <SelectTipoDocumento register={register("tipo_documento_representante",{required:true})}/>
                    <FormError errors={errors} name='tipo_documento_representante'/>
                  </div>
              </div>

              <div className="flex lg:flex-row sm:flex-col">
                <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <Input descripcion="Número identificación *"  register={register("numero_identificacion_representante",{required:true})}/>
                    <FormError errors={errors} name='numero_identificacion_representante'/>
                  </div>
                  <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <Input descripcion="Correo *"  register={register("email_representante",{required:true})}/>
                    <FormError errors={errors} name='email_representante'/>
                  </div>
                  <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <Input type="number" descripcion="Celular *"  register={register("celular_representante",{required:true,minLength:{value:10,message:'Mínimo 10 dígitos'}})}/>
                    <FormError errors={errors} name='celular_representante'/>
                  </div>
                  <div className="flex-1 lg:mr-2 sm:mb-2 md:mb-2">
                    <Input descripcion="Whatsapp *"  register={register("whatsapp_representante",{required:true,minLength:{value:10,message:'Mínimo 10 dígitos'}})}/>
                    <FormError errors={errors} name='whatsapp_representante'/>
                  </div>
              </div>

              
            </div>

            
      <div className="flex-row flex justify-end mt-8">
        
        <button className="rounded-md border-white border-[1px] bg-[#2f3855] hover:bg-slate-300 text-white font-bold py-2 px-4"
      type="submit"
        >
         {id ? "Actualizar": "Crear"}
        </button>
      </div>
        
 
        </form>
    </div>
  );
};

const ClinicaGuardar=()=>{
  return <Policy policies={[PERMISO_AGREGAR_CLIENTES]}>
    <Index/>
  </Policy>
}

export default ClinicaGuardar;
