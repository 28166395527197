import Modal from "react-modal"
import {MdClose} from "react-icons/md"
import {ImSpinner9} from "react-icons/im"
import Card from "../../../../Components/Card"
import { useForm } from "react-hook-form";
import Input from "Components/Input";
import FormError from "../../../../Components/FormError";
import { useContext, useEffect, useState } from "react";
import { API } from "../../../../API";
import { UiCtx } from "../../../../Context/Ui";

const RopSave=({item,setItem,onSave})=>{
    const {showAlert} = useContext(UiCtx)
    const { register, handleSubmit,setValue, watch, formState: { errors } } = useForm();
    const [loading,setLoading] = useState(false)

    const close=()=>{
        if(loading){
            return
        }
        setItem(null)
    }

    const save=async (payload)=>{
        try{
            setLoading(true)
            const url = item?.id ? `configuracion/rop/${item.id}/` : 'configuracion/rop/'
            const method = item?.id ? 'patch' : 'post'

            const {data} = await API({
                method,
                url,
                data:payload
            })
            onSave(data)
            showAlert({icon:'success',html:data.detail})
            setItem(null)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(item){
            setValue('codigo',item.codigo)
            setValue('rop',item.rop)
        }else{
            setValue('codigo','')
            setValue('rop','')
        }
    },[item])

    return (<Modal isOpen={item} style={{content:{background:'transparent',border:'none'}}}>
        <Card content_style="w-[40%] m-auto">
            <div className='flex justify-between mb-6'>
                <h2 className='text-lg'>{item?.id ? "Actualizar" :"Registrar"} ROP</h2>
                <button onClick={close} className="rounded-full hover:bg-slate-400/40 p-2">
                    <MdClose />
                </button>
            </div>
            <form onSubmit={handleSubmit(save)}>
                <Input descripcion="ROP estadio" register={register('codigo',{required:true})}/>
                <FormError errors={errors} name='codigo'/>
                <Input descripcion="Descripción" register={register('rop',{required:true})}/>
                <FormError errors={errors} name='nombre'/>
                <button className="bg-slate-400/40 p-2 rounded-md flex items-center">Guardar {loading && <ImSpinner9 className="animate-spin ml-2"/>}</button>
            </form>
        </Card>
    </Modal>)
}

export default RopSave