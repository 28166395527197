import { API } from 'API';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import {AiOutlinePlus,AiOutlineReload,AiOutlineSave} from "react-icons/ai"
import {IoTrashOutline,IoChevronForward,IoArrowBackSharp, IoPencilOutline} from "react-icons/io5"
import { useContext } from 'react';
import { UiCtx } from 'Context/Ui';
import {ImSpinner9} from "react-icons/im"
import {FiEdit} from "react-icons/fi"
import Policy from 'Components/Policy';
import { PERMISO_AGREGAR_CONFIGURACION_REGIONAL, PERMISO_ELIMINAR_CONFIGURACION_REGIONAL, PERMISO_MODIFICAR_CONFIGURACION_REGIONAL } from 'Contants';

const Item=({country,id,temporal_id,nombre,onRemove,onError,onSave,onSelected,state_selected})=>{

    const [mode,setMode] = useState(!id ? 2 : 1)
    const [value,setValue] = useState('')
    const [loading,setLoading] = useState(false)

    const save=async ()=>{
        try{
            setLoading(true)
            const method = id ? "patch" : "post"
            const url = id ? `configuracion/departamento/${id}/` : 'configuracion/departamento/'
            const {data} = await API({
                url,
                method,
                data:{nombre:value,fk_pais:country}
            })
            setMode(1)
            onSave({...data,temporal_id})
        }catch(error){
            onError(error)
        }finally{
            setLoading(false)
        }
    }

    const keyup=(e)=>{
     
        if(e.keyCode==27 && !loading){
            setMode(1)
        }

        if(e.keyCode=='13' && value!=''){
            save()
        }
    }

    useEffect(()=>{
        setValue(nombre)
    },[nombre])

    useEffect(()=>{
        if(!id){
            setMode(2)
        }
    },[id])

    useEffect(()=>{
        if(!id && mode==1){
            onRemove({temporal_id})
        }
        if(mode==2 && state_selected!=id){
            onSelected(null)
        }
    },[mode])

    if(mode==2){
        return (
        <div className='p-2 flex justify-between border-b-[1px] border-slate-400/40'>
            <button onClick={()=>setMode(1)} disabled={loading}>
                <IoArrowBackSharp/>
            </button>
            <input value={value} disabled={loading} onKeyUp={keyup} onChange={(e)=>setValue(e.target.value)} autoFocus className='flex-1 mx-2 border-none w-full outline-none bg-transparent rounded-md' />
            <button onClick={save} disabled={loading}>
                {loading ? <ImSpinner9 className='animate-spin'/> : <AiOutlineSave/>}
            </button>
        </div>
        )
    }

    const selected = state_selected==id ? 'bg-teal-500' : '';

    return <div className={'p-2 flex justify-between border-b-[1px] border-slate-400/40 '+selected}>
        <Policy show_error={false} policies={[PERMISO_ELIMINAR_CONFIGURACION_REGIONAL]}>
            <button onClick={()=>onRemove({id})} className='hover:scale-150 hover:bg-slate-400/40 p-[4px] mr-4 rounded-full'>
                <IoTrashOutline/>
            </button>
        </Policy>
        
        <span className='hover:cursor-pointer px-2 flex-1'  onClick={()=>onSelected(id)}>{nombre}</span>

        <Policy show_error={false} policies={[PERMISO_MODIFICAR_CONFIGURACION_REGIONAL]}>
            <button  className='hover:scale-150 hover:bg-slate-400/40 p-[4px] rounded-full' onClick={()=>setMode(2)}>
                <FiEdit/>
            </button>
        </Policy>
        
    </div>
}

const States=({country,state_selected,setStateSelected})=>{
    
    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const {showAlert} = useContext(UiCtx)

    const onError=(error)=>{
        showAlert({
            icon:'error',
            html:error?.response?.data?.detail || error.toString()
        })
    }

    const remove= async (id)=>{
        try{
            setLoading(true)
            await API.delete(`configuracion/departamento/${id}/`)
            setItems(prev=>prev.filter(p=>p.id!=id))
            if(id==state_selected){
                setStateSelected(null)
            }
        }catch(error){
            onError(error)
        }finally{
            setLoading(false)
        }
    }

    const onRemove=({id,temporal_id})=>{
        if(!id && temporal_id){
            setItems(prev=>prev.filter(p=>p.id))
        }else if(id){
            showAlert({
                icon:'question',
                title:'Advertencia',
                html:'Se eliminarán el depaetamento y todas sus ciudades',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:'Borrar',
                showCancelButton:'Cancelar'
            }).then(({isConfirmed})=>{
                if(isConfirmed){
                    remove(id)
                }
            })
        }
    }

    const onSave=(item)=>{
        
        setItems(prev=>prev.map((i)=>{
            if(i.id==item.id || (item?.temporal_id && i?.temporal_id==item?.temporal_id)){
                return item
            }
            return i
        }))
    }

    const add = ()=>{
        setItems(prev=>([...prev,{temporal_id:Date.now(),id:null,codigo:'',nombre:''}]))
    }

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/departamento/',{params:{id_pais:country}})
            setItems(data)
        }catch(error){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(country){
            load()
        }else{
            setItems([])
        }
    },[country])


    const w = items.length ? "flex-1" : ""

    return (<div className={" border-r-[1px] border-slate-400/40  transition-all ease-out duration-500 "+w}>
    <div className='py-4 px-2 border-b-[1px] border-slate-400/40 flex justify-between items-center'>
                <span className='text-md font-bold'>Departamentos / Estados</span> 
                {country && <div className='flex'>
                    <Policy show_error={false} policies={[PERMISO_AGREGAR_CONFIGURACION_REGIONAL]}>
                        <button onClick={add} className='mr-4'><AiOutlinePlus/></button>
                    </Policy>
                    {loading ? <ImSpinner9 className='animate-spin'/> : <button onClick={load}><AiOutlineReload/></button>}
                </div>}
            </div>
            <SimpleBar autoHide={false} style={{maxHeight:'92%'}}>
                {items.map((item,index)=><Item country={country} onSelected={setStateSelected} state_selected={state_selected} onRemove={onRemove} onError={onError} onSave={onSave} key={index} {...item} />)}
            </SimpleBar>
        </div>)

    
}

export default States