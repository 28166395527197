import Policy from 'Components/Policy'
import SelectSedes from 'Components/SelectSedes'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { ImSpinner9 } from 'react-icons/im'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from 'API'
import FormError from 'Components/FormError'
import Input from "Components/Input"
import { GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN, PERMISO_AGREGAR_USUARIO_CLINICA, PERMISO_MODIFICAR_USUARIO_CLINICA } from 'Contants'
import { SecurityCtx } from 'Context/Security'
import { TablasMaestrasContext } from 'Context/TablasMaestras'
import { UiCtx } from 'Context/Ui'
import { getBase64 } from 'Utilidades'
import SelectTipoDocumento from 'Components/SelectTipoDocumento'

const CrearUsuarioProtected = () => {

    const { register, handleSubmit,setValue, watch, formState: { errors } } = useForm();
    const {showAlert} = useContext(UiCtx)
    const {especialidades,grupos} = useContext(TablasMaestrasContext)
    const {user} = useContext(SecurityCtx)
    const [loading,setLoading]=useState(false)
    const [detail,setDetail] = useState(null)
    const [foto,setFoto] = useState('')
    const [foto_actual,setFotoActual] = useState('')
    const {id} = useParams()

    const navigation = useNavigate()

    const createTecnico =async(data)=>{
        try {
          console.log({data})
      
           setLoading(true)
            if (detail) {
              const payload={
                first_name:data.nombre,
                last_name:data.apellido,
                email:data.email,
                group:detail.fk_user.groups[0].id,
                numero_identificacion:data.numero_identificacion,
                id_especialidad:data.especialidades,
                id_identificacion:data.documentos,
                id_clinica:user.id_clinica,
                cupos_por_dia:data.cupos_por_dia,
                sede:data.sede,
                foto_user:foto
                
            }
              const response = await API.patch(`clinica/usuario/${detail.id}/actualizar/?clinica_id=${user.id_clinica}`,payload)
            showAlert({html:"Se actualizó correctamente",icon:'success'})
            navigation("/manager/team")
            return
            } 

            await API.post("clinica/usuario/crear/?clinica_id="+user.id_clinica,{
                first_name:data.nombre,
                last_name:data.apellido,
                email:data.email,
                group:data.grupos,
                numero_identificacion:data.numero_identificacion,
                id_especialidad:data.especialidades,
                id_identificacion:data.documentos,
                id_clinica:user.id_clinica,
                cupos_por_dia:data.cupos_por_dia,
                sede:data.sede,
                foto_user:foto
            })
            showAlert({html:"Se registró correctamente",icon:'success'})
            navigation("/manager/team")
            
        } catch (error) {
          showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
        } finally {
          setLoading(false)
        }
    }

    const load=async ()=>{
      try{
        setLoading(true)
        const {data}=await API.get(`clinica/usuario/${id}/?clinica_id=${user.id_clinica}`)
        setDetail(data)
        setFotoActual(data?.foto_user)
      }catch(error){
        showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
      }finally{
        setLoading(false)
      }
    }

    const fotoSeleccionada=async (e)=>{
      const photo = await getBase64(e.target.files[0])
      setFoto(photo)
    }

    useEffect(()=>{
      if(id){
        load()
      }
    },[id])

    useEffect(()=>{
      if(detail){
        setValue('email',detail?.fk_user?.email)
        setValue('nombre',detail?.fk_user?.first_name)
        setValue('apellido',detail?.fk_user?.last_name)
        setValue('documentos',detail?.fk_identiicacion?.id)
        setValue("grupos",detail?.fk_user?.groups[0].id)
        setValue("numero_identificacion",detail?.numero_identificacion)
        setValue("especialidades",detail?.fk_especialidad?.id)
        setValue("sede",detail?.sede.map(s=>""+s.id))
        setValue('cupos_por_dia',detail?.cupos_por_dia || 0)
        
      }
    },[detail])

  return (
    <div className='flex justify-center my-6'>
    <form onSubmit={handleSubmit(createTecnico)}>
      <h1 className='text-2xl mb-8 mt-4'>{id ? "Actualizar integrante":"Nuevo integrante"}</h1>

      <div className='flex justify-center mb-2'>
        <div className='relative w-[100px] h-[100px] rounded-full overflow-hidden bg-teal-600 '>
          <img src={foto || foto_actual} className='w-full h-full'/>
          <input type='file' onChange={fotoSeleccionada} className='absolute top-0 left-0 w-full h-full opacity-0 hover:cursor-pointer'/>
        </div>
      </div>
      <div className='flex lg:flex-row md:flex-col sm:flex-col'>
        <div className='flex-1 lg:mr-2'>
          <Input desactivar={loading} placeholder="" size={40} descripcion="Nombre" register={register("nombre",{required:"El nombre es requerido."})}/>
          <FormError errors={errors} name="nombre"/>
        </div>
        <div className='flex-1 lg:mb-2'>
          <Input desactivar={loading} placeholder="" size={40} descripcion="Apellido"
          register={register("apellido",{required:"El apellido es requerido"})}
          />
          <FormError errors={errors} name="apellido"/>  
        </div>
      </div>

          <div className='flex lg:flex-row md:flex-col sm:flex-col'>
            
            <div className='flex-1 lg:mr-2'>
              <Input type='email' desactivar={loading}  size={40} descripcion="Email *"
              register={register("email",{required:"El email es requerido con este se inicia sesión",pattern:{value:/\S+@\S+\.\S+/,message:'Ingrese un email valido'}})}
              />
              <FormError errors={errors} name="email"/>
            </div>
          </div>
          

          <div className='flex lg:flex-row md:flex-col sm:flex-col'>
            <div className='flex-1 lg:mr-2 md:mb-2 sm:mb-2'>
              <SelectTipoDocumento register={register("documentos",{required:"El tipo de documento es requerido"})}/>
              <FormError errors={errors} name="documentos"/>
            </div>
            <div className='flex-1'>
            <Input desactivar={loading} placeholder="" size={40} descripcion="Número de identificación *"
          register={register("numero_identificacion",{required:"El número de identificación es requerido"})}
          />
          <FormError errors={errors} name="numero_identificacion"/>
            </div>
            
          </div>

          <div className='flex lg:flex-row md:flex-col sm:flex-col'>
               <div className='flex-1'>
            <Input desactivar={loading} placeholder="" size={40} descripcion="Cupos por día *"
          register={register("cupos_por_dia",{required:"Ingrese un número"})}
          type="number"
          />
          <FormError errors={errors} name="cupos_por_dia"/>
            </div>
           
            

          
          </div>

          <div className='flex lg:flex-row md:flex-col sm:flex-col mb-4'>
            {!id && <div className='flex-1 lg:mr-2'>
                <label className='text-sm'>Grupo *</label>
                <select disabled={loading} className="rounded-md border-[#2f3855] bg-[#2f3855] pb-[5px]t-[5px] p p-2 flex-1 mt-1 w-full "
                {...register("grupos",{required:"El grupo es requerido"})}
                >
                  <option value="">Seleccione</option>
                {
                grupos.filter((grup)=>[GROUP_DOCTOR,GROUP_MANAGER,GROUP_TECHNICIAN].includes(parseInt(grup.codigo))).map((grupo)=>(

                        <option key={grupo.id} value={grupo.id}>{grupo.name}</option>
                
                ))

              }
              
                
                </select>  
                <FormError errors={errors} name="grupos"/> 
              </div>}

            <div className='flex-1'>
              <label className='text-sm'>Especialidad</label>
              <select disabled={loading} className="rounded-md border-[#2f3855] bg-[#2f3855] pb-[5px]t-[5px]  p-2 flex-1 w-full mt-1"
              {...register("especialidades")}
              >
                <option value="">Seleccione</option>
                {especialidades.map((especialidad)=>(<option key={especialidad.id} value={especialidad.id}>{especialidad.tipo}</option>))}
              </select>
            </div>
        </div>

        <div>
        
          <SelectSedes multiple={true} register={register("sede",{required:"Especifique las sedes a la que tiene acceso el usuario"})}/>
        </div>
      
        <div className="flex justify-end mt-8 items-center justify-end">
          {!loading && <Link className='mr-8 rounded-md bg-salte-400/40 hover:bg-slate-300 text-slate-200 hover:text-slate-900 border-[1px] py-2 px-4' to='/manager/team'>
          Cancelar
          </Link>}
            <button className="rounded-md border-white border-[1px] bg-slate-400/40 hover:bg-slate-300 text-slate-200 hover:text-slate-900 font-bold py-2 px-4" type="submit" disabled={loading}>{id ? "Actualizar":"Registrar"}
            </button>
            {loading && <ImSpinner9 className="ml-2 animate-spin"/>}
          </div>
    </form>
    </div>
  )
}


const CrearUsuario=()=>{

  const {id} = useParams()

  if(id){
    return <Policy policies={[PERMISO_MODIFICAR_USUARIO_CLINICA]}>
      <CrearUsuarioProtected/>
    </Policy>
  }
  
  return <Policy policies={[PERMISO_AGREGAR_USUARIO_CLINICA]}>
    <CrearUsuarioProtected/>
  </Policy>
}

export default CrearUsuario
