import { API } from "API"
import Loading from "Components/Loading"
import Policy from "Components/Policy"
import { PERMISO_VER_GRUPOS,PERMISO_AGREGAR_GRUPOS } from "Contants"
import { UiCtx } from "Context/Ui"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"

const RolesProtected=()=>{
    const {showAlert} = useContext(UiCtx)
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/grupos/')
            setItems(data)
        }catch(error){
            showAlert({
                    icon:'error',
                    html:error?.response?.data?.detail || error.toString()
            } )
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

    return (<div className="p-16">
        <div className="flex items-center justify-end">
            <Policy show_error={false} policies={[PERMISO_AGREGAR_GRUPOS]}>
                <Link className="p-2 bg-slate-400/40 rounded-md" to='/admin/roles/create'>Nuevo rol</Link>
            </Policy>
        </div>
        {loading && <Loading/>}
    <table>
        <thead>
            <tr>
                <th>Rol</th>
                <th>Código</th>
            </tr>
        </thead>
        <tbody>
            {items.map((item,index)=>(<tr key={index}><td><Link className="hover:bg-slate-400/40 p-[4px] rounded-sm" to={`/admin/roles/${item.id}`}>{item.name}</Link></td><td>{item.codigo}</td></tr>))}
        </tbody>
    </table>
    </div>)
}

const Roles=()=>{
    return <Policy policies={[PERMISO_VER_GRUPOS]}>
        <RolesProtected/>
    </Policy>
}

export default Roles