import React,{ useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { API } from "../../../../API"
import UserGroup from "../../../../Components/UserGroup"
import {FaEdit,FaPlus} from "react-icons/fa"
import Pagination from "../../../../Components/Pagination"
import AdminUserSave from "../Save"
import UserState from "../../../../Components/UserState"
import Loading from "../../../../Components/Loading"
import { PERMISO_VER_USUARIO_CLINICA,PERMISO_AGREGAR_USUARIO_CLINICA,PERMISO_MODIFICAR_USUARIO_CLINICA,PERMISO_ELIMINAR_USUARIO_CLINICA } from "Contants"
import Policy from "Components/Policy"

const ClinicUsersProtected=()=>{

    const {id:clinica_id} = useParams()
    const [record,setRecord]=useState(null)
    const [count,setCount] = useState(0)
    const [page,setPage] = useState(null)
    const [clinic,setClinic]=useState({})
    const [records,setRecords]=useState([])
    const [loading_clinic,setLoadingClinic]=useState(false)
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState(null)

    const loadClinic=async ()=>{
        if(loading_clinic){
            return
        }
        setLoadingClinic(true)
        
        try{
            const {data} = await API('cliente/'+clinica_id+'/')
            setClinic(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoadingClinic(false)
        }
    }


    const loadUser=async ()=>{
        if(loading){
            return
        }
        setLoading(true)
        try{
            const {data} = await API('clinica/usuario/',{params:{clinica_id,page}})
            setRecords(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
       
    }

    const onPage=(p)=>{
        setPage(p)
    }

    const reload=()=>{
        setPage(null)
        setTimeout(()=>{
            setPage(1)
        },800)
    }

    const load=()=>{
        loadClinic()
        loadUser()
    }

    useEffect(()=>{
        load()
    },[])

    useEffect(()=>{
        if(page){
            loadUser()
        }
    },[page])

    if(error){
        return <div className="flex w-full h-[40%] items-center justify-center flex-col">
          <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>
          <button onClick={load}>Volver ha cargar</button>
          </div>
      }

    return <>
        {loading && <Loading/>}
        <div className="flex">
        <div className="min-h-[100vh] w-[20%] p-4 border-r-[1px] border-r-slate-800">
            
            <label className="text-slate-400">Razón social</label>
            <p className="text-sm mb-2">{clinic?.razon_social}</p>
            <label className="text-slate-400">Dirección</label>
            <p className="text-sm mb-2">{clinic?.direccion}</p>
            <label className="text-slate-400">NIT</label>
            <p className="text-sm mb-2">{clinic?.nit}</p>
            <Policy show_error={false} policies={[PERMISO_AGREGAR_USUARIO_CLINICA]}>
            <button className="rounded-full px-2 py-[2px] hover:bg-slate-400 bg-slate-400/40 flex items-center" onClick={()=>setRecord({})}><FaPlus className="mr-[4px]"/> Nuevo usuario</button>
            </Policy>
            
        </div>
        <div className="flex-1">
            <Policy show_error={false} policies={[PERMISO_AGREGAR_USUARIO_CLINICA,PERMISO_MODIFICAR_USUARIO_CLINICA]}>
                <AdminUserSave record={record} setRecord={setRecord} reload={reload} clinica_id={clinica_id}/>
            </Policy>
            
            <table>
                <tr className="bg-slate-400/10 border-b-[2px] border-slate-400/40">
                    <th></th>
                    <th>Nombre</th>
                    <th>Usuario</th>
                    <th>Rol</th>
                    <th>Estado</th>
                    <Policy show_error={false} policies={[PERMISO_MODIFICAR_USUARIO_CLINICA]}>
                        <th></th>
                    </Policy>
                </tr>
                <tbody>
                    {records.map((record,index)=>(<tr key={index}>
                        <td>
                            <img className="m-auto w-10 h-10 rounded-full bg-teal-500" src={record.foto_user}/>
                        </td>
                        <td>{record?.fk_user?.first_name} {record?.fk_user?.last_name}</td>
                        <td>{record?.fk_user?.username}</td>
                        <td>
                            {record?.fk_user?.groups?.map((group,index)=>(<UserGroup {...group} key={index}/>))}
                        </td>
                        <td>
                            <UserState clinica_id={clinica_id} sys_active={record.sys_active} id={record.id}/>
                        </td>
                        <Policy show_error={false} policies={[PERMISO_MODIFICAR_USUARIO_CLINICA]}>
                            <td>
                                <button type="button" className="hover:bg-slate-400/40 rounded-full p-[4px]"><FaEdit onClick={()=>setRecord(record)}/></button>
                            </td>
                        </Policy>
                       
                        </tr>))}
                </tbody>
            </table>
            <div className="p-4">
                <Pagination records={count} onPage={onPage}/>
            </div>
        </div>
    </div>
    </>
}

const ClinicUsers=()=>{
    return <Policy policies={[PERMISO_VER_USUARIO_CLINICA]}>
        <ClinicUsersProtected/>
    </Policy>
}

export default ClinicUsers