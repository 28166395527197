import { API } from "API"
import Loading from "Components/Loading"
import { UiCtx } from "Context/Ui"
import { useContext, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import {BsFillShieldLockFill} from "react-icons/bs"
import { PERMISO_MODIFICAR_GRUPOS,PERMISO_AGREGAR_GRUPOS,PERMISO_AGREGAR_CLIENTES, PERMISO_AGREGAR_MEDICAL_PLAN, PERMISO_AGREGAR_PACIENTE, PERMISO_AGREGAR_RISK, PERMISO_AGREGAR_ROP, PERMISO_AGREGAR_SEDES, PERMISO_AGREGAR_TAMIZAJE, PERMISO_AGREGAR_USUARIO_CLINICA, PERMISO_ELIMINAR_CLIENTES, PERMISO_ELIMINAR_MEDICAL_PLAN, PERMISO_ELIMINAR_PACIENTE, PERMISO_ELIMINAR_RISK, PERMISO_ELIMINAR_ROP, PERMISO_ELIMINAR_SEDES, PERMISO_ELIMINAR_USUARIO_CLINICA, PERMISO_MODIFICAR_CLIENTES, PERMISO_MODIFICAR_MEDICAL_PLAN, PERMISO_MODIFICAR_PACIENTE, PERMISO_MODIFICAR_RISK, PERMISO_MODIFICAR_ROP, PERMISO_MODIFICAR_SEDES, PERMISO_MODIFICAR_TAMIZAJE, PERMISO_MODIFICAR_USUARIO_CLINICA, PERMISO_VER_CLIENTES, PERMISO_VER_MEDICAL_PLAN, PERMISO_VER_PACIENTE, PERMISO_VER_RISK, PERMISO_VER_ROP, PERMISO_VER_SEDES, PERMISO_VER_TAMIZAJES, PERMISO_VER_USUARIO_CLINICA, GROUP_MANAGER, GROUP_CLINIC, GROUP_TECHNICIAN, GROUP_DOCTOR, GROUP_ADMIN, PERMISO_VER_IMPRESION_MEDICA, PERMISO_AGREGAR_IMPRESION_MEDICA, PERMISO_MODIFICAR_IMPRESION_MEDICA, PERMISO_ELIMINAR_IMPRESION_MEDICA, PERMISO_ELIMINAR_HALLAZGOS, PERMISO_VER_HALLAZGOS, PERMISO_AGREGAR_HALLAZGOS, PERMISO_MODIFICAR_HALLAZGOS, PERMISO_VER_PLAN, PERMISO_AGREGAR_PLAN, PERMISO_MODIFICAR_PLAN, PERMISO_ELIMINAR_PLAN, PERMISO_VER_FOTO, PERMISO_AGREGAR_FOTO, PERMISO_MODIFICAR_FOTO, PERMISO_ELIMINAR_FOTO, PERMISO_VER_CONFIGURACION_REGIONAL, PERMISO_AGREGAR_CONFIGURACION_REGIONAL, PERMISO_MODIFICAR_CONFIGURACION_REGIONAL, PERMISO_ELIMINAR_CONFIGURACION_REGIONAL, PERMISO_VER_ORDENAMIENTO, PERMISO_AGREGAR_ORDENAMIENTO, PERMISO_MODIFICAR_ORDENAMIENTO, PERMISO_ELIMINAR_ORDENAMIENTO } from "Contants"
import Policy from "Components/Policy"

const RoleSaveProtected=()=>{
    const {id} = useParams()
    const {showAlert} = useContext(UiCtx)
    const [loading,setLoading] = useState(false)
    const [rol,setRol] = useState(null)
    const [all_permissions,setAllPermissions] = useState([])

    const navigate = useNavigate()

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/grupos/'+id+'/')
            setRol(data)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
    }

    const loadAllPermissions=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/permissions/')
            setAllPermissions(data)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
    }

    const permisos=[
        {
            name:'Configuración regional (País, Departamento y ciudad)',
            actions:[
                {codename:PERMISO_VER_CONFIGURACION_REGIONAL,app:'Configuraciones',model:'pais'},
                {codename:PERMISO_AGREGAR_CONFIGURACION_REGIONAL,app:'Configuraciones',model:'pais'},
                {codename:PERMISO_MODIFICAR_CONFIGURACION_REGIONAL,app:'Configuraciones',model:'pais'},
                {codename:PERMISO_ELIMINAR_CONFIGURACION_REGIONAL,app:'Configuraciones',model:'pais'},
            ]
        },
        {
            name:'Clientes',
            actions:[
                {codename:PERMISO_VER_CLIENTES,name:'Ver clientes'},
                {codename:PERMISO_AGREGAR_CLIENTES,name:'Agregar clientes'},
                {codename:PERMISO_MODIFICAR_CLIENTES,name:'Modificar clientes'},
                {codename:PERMISO_ELIMINAR_CLIENTES}
            ]
        },
       
       {
        name:'Planes médicos',
        actions: [
            {codename:PERMISO_VER_MEDICAL_PLAN,name:'Ver planes médicos'},
            {codename:PERMISO_AGREGAR_MEDICAL_PLAN,name:'Agregar planes médicos'},
            {codename:PERMISO_MODIFICAR_MEDICAL_PLAN,name:'Modificar planes médicos'},
            {codename:PERMISO_ELIMINAR_MEDICAL_PLAN,name:'Eliminar planes médicos'}
        ]
       },
       {
        name:'Factores de riesgo',
        actions:[
            {codename:PERMISO_VER_RISK},
            {codename:PERMISO_AGREGAR_RISK},
            {codename:PERMISO_MODIFICAR_RISK},
            {codename:PERMISO_ELIMINAR_RISK},
        ]
       },
       {
        name:'ROP estadios',
        actions:[
            {codename:PERMISO_VER_ROP},
            {codename:PERMISO_AGREGAR_ROP},
            {codename: PERMISO_MODIFICAR_ROP},
            {codename:PERMISO_ELIMINAR_ROP},
        ]
    },


        {
            name:'Pacientes',
            actions:[
                {codename:PERMISO_VER_PACIENTE,name:'Ver pacientes'},
                {codename:PERMISO_AGREGAR_PACIENTE,name:'Agregar pacientes'},
                {codename:PERMISO_MODIFICAR_PACIENTE,name:'Modificar pacientes'},
                {codename:PERMISO_ELIMINAR_PACIENTE},
            ]
        },
     
  
    {
        name:'Sedes',
        actions:[
            {codename:PERMISO_VER_SEDES,app:'Gestion_Clinica',model:'sede'},
            {codename:PERMISO_AGREGAR_SEDES,app:'Gestion_Clinica',model:'sede'},
            {codename:PERMISO_MODIFICAR_SEDES,app:'Gestion_Clinica',model:'sede'},
            {codename:PERMISO_ELIMINAR_SEDES,app:'Gestion_Clinica',model:'sede'},
        ]
    },
    {
        name:'Equipo de trabajo',
        actions:[
            {codename: PERMISO_VER_USUARIO_CLINICA},
                {codename:PERMISO_AGREGAR_USUARIO_CLINICA},
                {codename:PERMISO_MODIFICAR_USUARIO_CLINICA},
               {codename: PERMISO_ELIMINAR_USUARIO_CLINICA}
        ]
    },
    {
        name:'Tamizajes',
        actions:[
            {codename:PERMISO_VER_TAMIZAJES},
            {codename:PERMISO_AGREGAR_TAMIZAJE},
            {codename:PERMISO_MODIFICAR_TAMIZAJE},
        ]
    },
    {
        name:'Fotos de tamizaje',
        actions:[
            {codename:PERMISO_VER_FOTO,app:'Gestion_Tecnico',model:'foto'},
            {codename:PERMISO_AGREGAR_FOTO,app:'Gestion_Tecnico',model:'foto'},
            {codename:PERMISO_MODIFICAR_FOTO,app:'Gestion_Tecnico',model:'foto'},
            {codename:PERMISO_ELIMINAR_FOTO,app:'Gestion_Tecnico',model:'foto'},
        ]
    },
    {
        name:'Impresión médica de tamizaje',
        actions:[
            {codename:PERMISO_VER_IMPRESION_MEDICA,app:'Gestion_Medico',model:'imprension_medica'},
            {codename:PERMISO_AGREGAR_IMPRESION_MEDICA,app:'Gestion_Medico',model:'imprension_medica'},
            {codename:PERMISO_MODIFICAR_IMPRESION_MEDICA,app:'Gestion_Medico',model:'imprension_medica'},
        ]
    },
    {
        name:'Hallazgos de tamizaje',
        actions:[
            {codename:PERMISO_VER_HALLAZGOS,app:'Gestion_Medico',model:'hallazgo'},
            {codename:PERMISO_AGREGAR_HALLAZGOS,app:'Gestion_Medico',model:'hallazgo'},
            {codename:PERMISO_MODIFICAR_HALLAZGOS,app:'Gestion_Medico',model:'hallazgo'},
            {codename:PERMISO_ELIMINAR_HALLAZGOS,app:'Gestion_Medico',model:'hallazgo'},
        ]
    },
    {
        name:'Planes médicos de tamizaje',
        actions:[
            {codename:PERMISO_VER_PLAN,app:'Gestion_Medico',model:'plan'},
            {codename:PERMISO_AGREGAR_PLAN,app:'Gestion_Medico',model:'plan'},
            {codename:PERMISO_MODIFICAR_PLAN,app:'Gestion_Medico',model:'plan'},
            {codename:PERMISO_ELIMINAR_PLAN,app:'Gestion_Medico',model:'plan'},
        ]
    }
    ,
    {
        name:'Ordenamiento',
        actions:[
            {codename:PERMISO_VER_ORDENAMIENTO,app:'Gestion_Medico',model:'ordenamientomodel'},
            {codename:PERMISO_AGREGAR_ORDENAMIENTO,app:'Gestion_Medico',model:'ordenamientomodel'},
            {codename:PERMISO_MODIFICAR_ORDENAMIENTO,app:'Gestion_Medico',model:'ordenamientomodel'},
            {codename:PERMISO_ELIMINAR_ORDENAMIENTO,app:'Gestion_Medico',model:'ordenamientomodel'},
        ]
    }
    ]

    const remove = (permission_id)=>{
        setRol(prev=>({
            ...prev,
            permissions:prev.permissions.filter(p=>p!=permission_id)
        }))
    }
    const add = (permission_id)=>{
        setRol(prev=>({
            ...prev,
            permissions:[...prev.permissions,permission_id]
        }))
    }

    const PermissionCol=({codename,app,model})=>{

        if(!codename){
            return <td></td>
        }
       
        const permission = all_permissions.find((ap)=>(ap.codename==codename) && ((app && model && ap.content_type.app_label==app && ap.content_type.model==model) || (!app && !model))) || {}
        const enable = rol?.permissions.includes(permission?.id)

        const toggle=(e)=>{
            if(enable){
             remove(permission.id)
            }else{
             add(permission.id)
            }
        }

        return (
            <td className="text-center">
                <input disabled={loading} className="m-auto" type="checkbox" checked={enable} onChange={toggle} />
            </td>
        )
    }

    const Permission=({p})=>{
        return <tr className="hover:bg-slate-400/40">
                    <td>{p.name}</td>
                    <PermissionCol codename={p.actions[0]?.codename} app={p.actions[0]?.app} model={p.actions[0]?.model}/>
                    <PermissionCol codename={p.actions[1]?.codename} app={p.actions[1]?.app} model={p.actions[1]?.model}/>
                    <PermissionCol codename={p.actions[2]?.codename} app={p.actions[2]?.app} model={p.actions[2]?.model}/>
                    <PermissionCol codename={p.actions[3]?.codename} app={p.actions[3]?.app} model={p.actions[3]?.model}/>
                </tr>
    }

    const edit=async ()=>{
        try{
            await API.patch('configuracion/grupos/'+id+'/modificar/',rol)
            navigate(-1)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
        
    }

    const create=async ()=>{
        try{
            setLoading(true)
            await API.post('configuracion/grupos/crear/',rol)
            navigate(-1)
        }catch(error){
            showAlert({icon:'error',html:error?.response?.data?.detail || error.toString()})
        }finally{
            setLoading(false)
        }
    }

    const save= async ()=>{
            if(id){
                edit()
            }else{
                create()
            }   
       
    }


    useEffect(()=>{
        if(id){
            load()
        }else{
            setRol({name:'',codigo:'',permissions:[]})
        }

        loadAllPermissions()
    },[id])

    if(loading){
        return <Loading/>
    }

    if(!rol){
        return <p className="p-2 m-4 bg-red-500 border-[1px] border-red-700">Rol no encontrado</p>
    }

    return <div className="p-6">
        <div className="py-4 px-4 flex items-center border-b-[1px] border-slate-400/40">
            
            <div>
                <label className="text-md mb-2 block">Nombre:</label>
                <input value={rol?.name} onChange={(e)=>setRol(prev=>({...prev,name:e.target.value}))} placeholder="Nombre" className="text-2xl bg-slate-400/40 rounded-md px-2 border-none mr-2"/>
            </div>
            <div>
                <label className="text-md mb-2 block">Comportamiento:</label>
                <select value={rol?.codigo} onChange={(e)=>setRol(prev=>({...prev,codigo:e.target.value}))}  className="text-2xl bg-slate-400/40 rounded-md px-2 border-none">
                <option value={GROUP_ADMIN} className="text-slate-900">Admin</option>
                <option value={GROUP_CLINIC} className="text-slate-900">Cliente</option>
                <option value={GROUP_MANAGER} className="text-slate-900">Coordinador</option>
                <option value={GROUP_TECHNICIAN} className="text-slate-900">Técnico</option>
                <option value={GROUP_DOCTOR} className="text-slate-900">Médico</option>
                
            </select>
            </div>
            
           
        </div>
        <div >
            <table className="px-4">
                <thead>
                    <tr>
                        <th>Funcionalidad</th>
                        <th className="text-center">Ver</th>
                        <th className="text-center">Registrar</th>
                        <th className="text-center">Actualizar</th>
                        <th className="text-center">Habilitar / inhabilitar</th>
                    </tr>
                </thead>
                <tbody>
                {permisos.map((p,i)=>(<Permission p={p} key={i}/>))}
                </tbody>
            </table>
            <div className="p-4 flex items-center justify-end">
                <button className="p-2 bg-slate-400/40 rounded-md" onClick={save}>Guardar</button>
            </div>
        </div>
        
    </div>
}

const RoleSave=()=>{
    const {id} = useParams()

    if(id){
        return <Policy policies={[PERMISO_MODIFICAR_GRUPOS]}>
            <RoleSaveProtected/>
        </Policy>
    }
    return <Policy policies={[PERMISO_AGREGAR_GRUPOS]}>
            <RoleSaveProtected/>
        </Policy>
}

export default RoleSave