import { useContext, useEffect } from "react"
import Card from "Components/Card"
import Hallazgo from "./Hallazgo"
import Policy from "Components/Policy"
import { PERMISO_AGREGAR_HALLAZGOS, PERMISO_MODIFICAR_HALLAZGOS } from "Contants"
import { SecurityCtx } from "Context/Security"

const Hallazgos=({dado_de_alta,id_impresion,hallazgos,setHallazgos})=>{

    const {permissions} = useContext(SecurityCtx)
    let read_only = false;
    
    if(id_impresion && !permissions.includes(PERMISO_MODIFICAR_HALLAZGOS)){
        read_only=true
    }else if(!id_impresion && !permissions.includes(PERMISO_AGREGAR_HALLAZGOS)){
        read_only=true
    }

    if(dado_de_alta){
        read_only=true
    }

    const can_add = !dado_de_alta && ((!id_impresion && permissions.includes(PERMISO_AGREGAR_HALLAZGOS)) || (id_impresion && permissions.includes(PERMISO_MODIFICAR_HALLAZGOS)))

    const add=()=>{
        setHallazgos(prev=>([...prev,{
            ojo:'',
            fk_zona:'',
            fk_rop:'',
            observacion:'',

        }]))
    }




    return <div className='my-4'>
        <h3 className="text-lg mb-4 print:text-slate-700">Hallazgos</h3>
        {hallazgos.map((hallazgo,i)=>(<Hallazgo dado_de_alta={dado_de_alta} permissions={permissions} id_impresion={id_impresion} read_only={read_only} key={i} {...hallazgo} setHallazgos={setHallazgos} index={i}/>))}
        {can_add && <div className="flex justify-start items-center my-5 print:hidden" onClick={add}><button className="text-center hover:bg-slate-500/30 rounded-full px-[16px] py-[8px]">Agregar hallazgo +</button></div>}
            
        
    </div>
}

export default Hallazgos