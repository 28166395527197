import React, {  useEffect, useState } from 'react'
import {IoSyncOutline} from "react-icons/io5" 
import { API } from 'API'
import Loading from 'Components/Loading'

const ZonaSelect = ({register={},multiple=false,initial_value}) => {

    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const [error,setError] = useState(null)
    const label='Zona'

    const load=async ()=>{
        try{
            setError(null)
            setLoading(true)
            const {data} = await API('/configuracion/zona/')
            setItems(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

   

    if(loading){
        return <Loading/>
    }
    if(error){
        return <p className='print:hidden bg-red-500 text-slate-200 p-2 rounded-md'>{label}: {error}. <button type='button' onClick={load} className="cursor-pointer bg-red-800 p-2 rounded-md">Recargar</button></p>
    }

    

    const item = items.find(i=>""+i.id==register?.value)

  return (
    <>
        <p className='hidden print:block text-slate-700'>Zona: {item?.nombre}</p>
        <div className='print:hidden'>
        <label className='text-sm block mb-2'>{label} {register?.require && "*"}</label>
            <div className='flex  items-center '>
                <select multiple={multiple} className="rounded-md  bg-[#2f3855] pb-[5px] p-2 flex-1 "
                {...register}
                >
                    {!multiple && <option value="">Seleccione</option>}
                    {
                    items.map((item)=>(

                            <option key={item.id} value={item.id}>{item.nombre}</option>
                    
                    ))

                }
                </select>

            <button type='button' onClick={load} className="text-lg ml-2" title='Recargar'>
                <IoSyncOutline/>
            </button>
        
        </div>
        </div>
    </>
  )
}

export default ZonaSelect
