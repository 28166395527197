import { API } from "API"
import FormError from "Components/FormError"
import Loading from "Components/Loading"
import { GROUP_CLINIC, GROUP_MANAGER } from "Contants"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"
import { useForm  } from 'react-hook-form'

const TamizajeAprobar=({tamizaje_id,onReload})=>{
    const { register, handleSubmit, watch,setValue, control,formState: { errors } } = useForm();
    const {showAlert} = useContext(UiCtx)
    const {hasRole} = useContext(SecurityCtx)
    const [loading,setLoading] = useState(false)
   
    if(!hasRole([GROUP_CLINIC,GROUP_MANAGER])){
        return null
    }

    const save= async (payload)=>{
        try{
            setLoading(true)
            await API.patch(`coordinador/tamizaje/${tamizaje_id}/aprobar-rechazar/`,payload)
            onReload()
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    if(loading){
        return <Loading message="Actualizando tamizaje"/>
    }

    return <form onSubmit={handleSubmit(save)} className="border-slate-400/30 border-[1px] rounded-md p-2">
        <div className="flex lg:flex-row md:flex-col">
            <div className="flex-1 lg:mr-2 md:mb-2">
                <label className="text-md mb-2 block">Justificación *</label>
                <textarea {...register('justificacion',{required:'Especifique por que rechaza o aprueba'})} rows={4} className="w-full bg-slate-400/40 p-2 rounded-md"></textarea>
                <FormError errors={errors} name='justificacion'/>
            </div>
            <div className="flex-1 md:mb-2">
                <label className="text-md mb-2 block">Estado *</label>
                <select {...register('estado',{required:'Seleccione si aprueba o rechaza'})} className="w-full bg-slate-400/40 p-2 rounded-md">
                    <option value="" className="text-slate-800">Seleccione</option>
                    <option value="1" className="text-slate-800">Aprobado</option>
                    <option value="-1" className="text-slate-800">Rechazado</option>
                </select>
                <FormError errors={errors} name='estado'/>
            </div>
        </div>
        <button className="bg-slate-400/40 border-[1px] border-salte-400 p-2 rounded-md">Guardar</button>
    </form>
}

export default TamizajeAprobar