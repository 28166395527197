import { API } from 'API';
import { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import {AiOutlinePlus,AiOutlineReload,AiOutlineSave} from "react-icons/ai"
import {IoTrashOutline,IoChevronForward,IoArrowBackSharp} from "react-icons/io5"
import { useContext } from 'react';
import { UiCtx } from 'Context/Ui';
import {ImSpinner9} from "react-icons/im"
import {FiEdit} from "react-icons/fi"
import Policy from 'Components/Policy';
import { PERMISO_AGREGAR_CONFIGURACION_REGIONAL, PERMISO_ELIMINAR_CONFIGURACION_REGIONAL, PERMISO_MODIFICAR_CONFIGURACION_REGIONAL } from 'Contants';

const Item=({id,temporal_id,nombre,onRemove,onError,onSave,state})=>{

    const [mode,setMode] = useState(!id ? 2 : 1)
    const [value,setValue] = useState('')
    const [loading,setLoading] = useState(false)

    const keyup=(e)=>{
     
        if(e.keyCode==27 && !loading){
            setMode(1)
        }

        if(e.keyCode=='13' && value!=''){
            save()
        }
    }

    const save=async ()=>{
        try{
            setLoading(true)
            const method = id ? "patch" : "post"
            const url = id ? `configuracion/ciudad/${id}/` : 'configuracion/ciudad/'
            const {data} = await API({
                url,
                method,
                data:{
                    nombre:value,
                    fk_departamento:state
                }
            })
            setMode(1)
            onSave({...data,temporal_id})
        }catch(error){
            onError(error)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        setValue(nombre)
    },[nombre])

    useEffect(()=>{
        if(!id){
            setMode(2)
        }
    },[id])

    useEffect(()=>{
        if(!id && mode==1){
            onRemove({temporal_id})
        }
    },[mode])

    if(mode==2){
        return (
        <div className='p-2 flex justify-between border-b-[1px] border-slate-400/40'>
            <button onClick={()=>setMode(1)} disabled={loading}>
                <IoArrowBackSharp/>
            </button>
            <input value={value} onKeyUp={keyup} disabled={loading} onChange={(e)=>setValue(e.target.value)} autoFocus className='flex-1 mx-2 border-none w-full outline-none bg-transparent rounded-md' />
            <button onClick={save} disabled={loading}>
                {loading ? <ImSpinner9 className='animate-spin'/> : <AiOutlineSave/>}
            </button>
        </div>
        )
    }


    return <div className='p-2 flex justify-between border-b-[1px] border-slate-400/40 '>
        <Policy show_error={false} policies={[PERMISO_ELIMINAR_CONFIGURACION_REGIONAL]}>
            <button onClick={()=>onRemove({id})} className='hover:scale-150 hover:bg-slate-400/40 p-[4px] rounded-full mr-4'>
                <IoTrashOutline/>
            </button>
        </Policy>
        
        <span className='hover:cursor-pointer flex-1 px-2' >{nombre}</span>
        <Policy show_error={false} policies={[PERMISO_MODIFICAR_CONFIGURACION_REGIONAL]}>
            <button onClick={()=>setMode(2)}>
                <FiEdit/>
            </button>
        </Policy>
      
        
    </div>
}

const Citys=({state})=>{
    
    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const {showAlert} = useContext(UiCtx)

    const onError=(error)=>{
        showAlert({
            icon:'error',
            html:error?.response?.data?.detail || error.toString()
        })
    }

    const remove= async (id)=>{
        try{
            setLoading(true)
            await API.delete(`configuracion/ciudad/${id}/`)
            setItems(prev=>prev.filter(p=>p.id!=id))
        }catch(error){
            onError(error)
        }finally{
            setLoading(false)
        }
    }

    const onRemove=({id,temporal_id})=>{
        if(!id && temporal_id){
            setItems(prev=>prev.filter(p=>p.id))
        }else if(id){
            showAlert({
                icon:'question',
                title:'Acción peligrosa',
                html:'Se eliminara la ciudad',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:'Borrar',
                showCancelButton:'Cancelar'
            }).then(({isConfirmed})=>{
                if(isConfirmed){
                    remove(id)
                }
            })
        }
    }

    const onSave=(item)=>{
        
        setItems(prev=>prev.map((i)=>{
            if(i.id==item.id || i?.temporal_id==item?.temporal_id){
                return item
            }
            return i
        }))
    }

    const add = ()=>{
        setItems(prev=>([...prev,{temporal_id:Date.now(),id:null,codigo:'',nombre:''}]))
    }

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('configuracion/ciudad/',{params:{id_departamento:state}})
            setItems(data)
        }catch(error){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(state){
            load()
        }else{
            setItems([])
        }
    },[state])

    const w = items.length ? "flex-1" : ""

    return (<div className={'transition-all ease-out duration-500 '+w}>
    <div className='py-4 px-2 border-b-[1px] border-slate-400/40 flex justify-between items-center'>
                <span className='text-md font-bold'>Ciudades / Provincias</span> 
                {state && <div className='flex'>
                    <Policy show_error={false} policies={[PERMISO_AGREGAR_CONFIGURACION_REGIONAL]}>
                        <button onClick={add} className='mr-4'><AiOutlinePlus/></button>
                    </Policy>
                    {loading ? <ImSpinner9 className='animate-spin'/> : <button onClick={load}><AiOutlineReload/></button>}
                </div>}
            </div>
            <SimpleBar autoHide={false} style={{maxHeight:'92%'}}>
                {items.map((item,index)=><Item  state={state} onRemove={onRemove} onError={onError} onSave={onSave} key={index} {...item} />)}
            </SimpleBar>
        </div>)

    
}

export default Citys