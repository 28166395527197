import React,{ useState,createContext } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import { API } from "../../API"
import { GROUP_DOCTOR, GROUP_MANAGER, GROUP_TECHNICIAN } from "../../Contants"
import { SecurityCtx } from "../Security"

export const TamizajeCtx=createContext(null)

const TamizajeProvider=({children})=>{
    const {user,hasRole} = useContext(SecurityCtx)
    const [params,setParams]=useState(null)
    
    const [records,setRecords]=useState([])
    const [count,setCount]=useState(0)
    const [error,setError] = useState(null)
    const [loading,setLoading]=useState(false)

    const load= async ()=>{
        try{
            setError(null)
            setLoading(true)
            const {patient,tecnico,medico,...f}=params;
            const {data} = await API.get(`coordinador/tamizaje/`,{params:f})
            setRecords(data.results)
            setCount(data.count)

        }catch(error){
            setError(error.response.data.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    const reset=()=>{
        setCount(0)
        setParams(null)
        setError(null)
        setRecords([])
    }

    const filter=(filters)=>{
       
        setParams(prev=>({...prev,...filters}))
    }

    const paginate=(page)=>{
        setParams(prev=>({...prev,page}))
    }

    useEffect(()=>{
        if(user){
            
            if(hasRole(GROUP_TECHNICIAN)){
                setParams({page:1,id_tecnico:user.usuario_clinica,t:new Date()})
            }else if(hasRole(GROUP_DOCTOR)){
                console.log("Doctor ",user)
                setParams({page:1,id_medico:user.usuario_clinica,t:new Date()})
            }else if(hasRole(GROUP_MANAGER)){
                //id_coordinador:user.usuario_clinica
                setParams({page:1,t:new Date()})
            }else{
                setParams({page:1,t:new Date()})
            }
           
        }else{
            reset()
        }
    },[user])

    useEffect(()=>{
        if(params){
            load()
        }
    },[params])



    return <TamizajeCtx.Provider value={{paginate,records,filter,loading,error,count,params}}>{children}</TamizajeCtx.Provider>
}

export default TamizajeProvider