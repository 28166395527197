import React,{ useState } from "react";
import "./style.css"
import classNames from "classnames"
import { useEffect } from "react";

const Back=({page,change})=>{
    if(page==1){
        return null
    }

    return <li><a className="cursor-pointer px-3 py-2 ml-0 leading-tight text-gray-500 bg-slate-400/40 border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>change(page-1)}><span aria-hidden="true">Anterior</span></a></li>
}

const Next=({page,total_pages,change})=>{
    if(page==total_pages || page>=total_pages){
       return null
    }
 

    return <li><a onClick={()=>change(page+1)}  className="cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-slate-400/40 border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"><span aria-hidden="true">Siguiente</span></a></li>
}

const Page=({page_number,page,change})=>{



    if(page==page_number){
        return  <li>
            <a  aria-current="page" class="px-3 py-2 text-slate-200 border border-gray-300 bg-teal-500 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{page_number}</a>
        </li>
    }

    return <li>
            <a className="cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-slate-400/40 border border-gray-100 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={()=>change(page_number)}>{page_number}</a>
        </li>
}

const Pagination=({records,record_per_page=10,links=5,initial_page=1,onPage})=>{
    const [page,setPage]=useState(initial_page)
    const [initial_render,setInitialRender]=useState(true)

    const pages=[]
    const total_pages=Math.ceil(records/record_per_page);
    const start      = (  page - links  > 0 ) ? page - links : 1;
    const end        = (  page + links  < total_pages ) ? page + links :total_pages;


    for(let i=start;i<=end;i++){
        pages.push(i)
    }


    useEffect(()=>{
        console.log("Pagina cambio ",page)
        if(onPage && !initial_render){
            onPage(page)
        }
    },[page])

    useEffect(()=>{
        if(initial_render){
            setInitialRender(false)
        }
    },[initial_render])

    
    if(records<=0){
        return null
    }

    return <div className="mt-6 flex flex-col items-center">
                <span class="text-sm text-slate-200 dark:text-gray-400">
                    Mostrando <span class="font-semibold text-slate-100 dark:text-white">{page}</span> a <span class="font-semibold text-slate-100 dark:text-white">{record_per_page}</span> de <span class="font-semibold text-slate-100 dark:text-white">{records}</span> Registros
                </span>
                <div class="inline-flex mt-4 xs:mt-0">
                    <nav aria-label="Page navigation">
                        <ul class="inline-flex -space-x-px">
                            <Back page={page} change={setPage}/>
                            {pages.map((page_number,index)=>(<Page key={index} page_number={page_number} page={page} change={setPage}/>))}
                            <Next total_pages={total_pages} page={page} change={setPage}/>
                        </ul>
                    </nav>
                </div>
            </div>
}

export default Pagination