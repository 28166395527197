import React, { useContext, useEffect, useState } from "react";
import {  HiPencilAlt } from "react-icons/hi";
import { API } from "API";
import { useNavigate } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import SiteSave from "../Save";
import Filters from "./Filters";
import SedeState from "../Components/State";
import Policy from "Components/Policy";
import {PERMISO_VER_SEDES,PERMISO_AGREGAR_SEDES,PERMISO_MODIFICAR_SEDES, GROUP_ADMIN} from "Contants"
import { SecurityCtx } from "Context/Security";

const SedeProtected = () => {
  
  const {hasRole,user} = useContext(SecurityCtx)
  const [sedes,setSedes] = useState([])


  const [loading,setLoading]=useState(false)
  const [error,setError]=useState(null)
  const [record,setRecord]=useState(null)
  const [params,setParams] = useState({})


  const add = () =>{
    if(hasRole(GROUP_ADMIN)){
      setRecord({cliente:params.cliente})
    }else{
      setRecord({})
    }
  }

  const load=async ()=>{
    try{
      setLoading(true)
      const {data} = await API(`clinica/sede/`,{params})
      setSedes(data)
    }catch(error){
      setError(error?.response?.data?.detail || error.toString())
    }finally{
      setLoading(false)
    }
  }


  const onChange=(id,sys_active)=>{
    setSedes(current_clinics=>{
      return current_clinics.map(c=>{
        if(c.id == id){
          return {...c,sys_active}
        }
        return c
      })
    })
  }

 

  const onFilter=filters=>{
    setParams(prev=>({...prev,cliente:user?.id_clinica,...filters}))
  }
  
  useEffect(()=>{
    if(user){
      load()
    }
  },[user,params])


  return (
    <>
    <Policy show_error={false} policies={[PERMISO_AGREGAR_SEDES,PERMISO_MODIFICAR_SEDES]}>
      <SiteSave onReload={load} record={record} setRecord={setRecord}/>
    </Policy>
    

    <div className="flex items-start justify-between p-4">
      <Filters onFilter={onFilter}/>

      <Policy show_error={false} policies={[PERMISO_AGREGAR_SEDES]}>
        {(hasRole(GROUP_ADMIN) && params.cliente || !hasRole(GROUP_ADMIN)) &&  <div className=" hover:bg-slate-500 rounded-md cursor-pointer flex px-2" onClick={add}>
            <h3>Agregar Sede</h3>
            <IoAddCircle size={25} />
        </div>}
      </Policy>
    </div>
  
   
    <div className="p-4">
        <table>
          <thead>
            <tr>
              {hasRole(GROUP_ADMIN) && <th>Cliente</th>}
              <th>Nombre</th>
              <th>Camas</th>
              <th>País</th>
              <th>Departamento</th>
              <th>Ciudad</th>
              <th>Codigo</th>
              <th>Dirección</th>
              <th>Estado</th>
              <Policy show_error={false} policies={[PERMISO_MODIFICAR_SEDES]}>
                <th></th>
              </Policy>
            </tr>
          </thead>
          <tbody>
            {sedes.map((sede) => (
              <tr key={sede.id}>
                {hasRole(GROUP_ADMIN) && <td>{sede?.fk_cliente?.razon_social}</td>}
                <td>{sede?.nombre}</td>
                <td>{sede?.numero_cama}</td>
                <td>{sede?.fk_pais?.nombre}</td>
                <td>{sede?.departamento?.nombre}</td>
                <td>{sede?.ciudad?.nombre}</td>
                
                <td>{sede?.codigo}</td>
                <td>{sede?.direccion}</td>
                <td>
                <SedeState onChange={onChange} {...sede}/>
                </td>
                <Policy show_error={false} policies={[PERMISO_MODIFICAR_SEDES]}>
                <td>                 
                  <HiPencilAlt
                      size={20}
                      className="cursor-pointer hover:scale-125 ml-4"
                      title="Click para actualizar"
                      onClick={() =>setRecord(sede)}
                    />


                  </td>
                </Policy>
                
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  </>
  );
};

const Sede=()=>{
  return <Policy policies={[PERMISO_VER_SEDES]}>
    <SedeProtected/>
  </Policy>
}

export default Sede;
