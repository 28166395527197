import ReactModal from "react-modal"
import SimpleBar from 'simplebar-react';
import Card from "Components/Card"
import {MdClose} from "react-icons/md"
import { useContext, useState } from "react";
import {TbDatabaseImport} from "react-icons/tb"
import { UiCtx } from "Context/Ui";
import dayjs from "dayjs";
import Policy from "Components/Policy";
import {PERMISO_AGREGAR_PACIENTE} from "Contants"
import { API } from "API";
import Loading from "Components/Loading";

const Import=({cliente,onReload})=>{
    const {showAlert} = useContext(UiCtx)
    const [visible,setVisible] = useState(false)
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)
    

    const close=()=>{
        setVisible(false)
    }

    if(!cliente){
        return null
    }

    const selected=(e)=>{
        var fr=new FileReader();
        fr.onload=function(){
            const rows = fr.result.split("\n")
            try{
            if(!rows.length){
                throw('No hay nada que importar')
            }
            const allColumn=[...columns,...columnsLegal]

            if(rows[0]!=allColumn.join(",")){
                throw('La primera fila debe contener las siguientes columnas <b>'+allColumn.join(",")+'</b>')
            }
            const data=[]
            for(let [key,row] of Object.entries(rows)){
                const r = parseInt(key)+1
                if(r>1){
                    const columns = row.split(",")
                    console.log(columns.length,allColumn.length)
                    if(columns.length!=allColumn.length){
                        throw('Número de columnas incorrectas. Fila '+r)
                    }
                    const d={clinica_id:cliente}
                    columnsKey.forEach((c,i)=>{
                        if(c=='sexo' && !['M','F'].includes(columns[i])){
                            throw('Fila '+r+' columna sexo <b>'+columns[i]+'</b> debe contener alguno de los siguientes valores <b>M</b> o <b>F</b>')
                        }
                        if(c=='fecha_nacimiento' && dayjs(columns[i]).format('YYYY-MM-DD')!=columns[i]){
                            throw('Fila '+r+' columna Fecha nacimiento <b>'+columns[i]+'</b> debe contener el siguiente formato <b>YYYY-MM-DD</b> ejemplo: <b>1992-04-10</b>')
                        }
                        if(c=='boolean_tamizaje' && !['Sí','No'].includes(columns[i])){
                            throw('Fila '+r+' columna requiere tamizaje <b>'+columns[i]+'</b> debe contener  <b>Sí</b> o <b>No</b>')
                        }
                        if(c=='boolean_tamizaje' ){
                            d[[c]]=columns[i]=='Sí' ? 1 : 0;
                        }else{
                            d[[c]]=columns[i]
                        }
                        
                    })
                    data.push(d)
                }
            }
            setItems(data)
               
            }catch(error){
                showAlert({
                    icon:'error',
                    html:error?.response?.data?.detail || error.toString()
                })
                return 
            }

            e.target.value=null
        }
        fr.readAsText(e.target.files[0])
        
    }

    const importData=async ()=>{
        try{
            setLoading(true)
            const {data} = await API.post('coordinador/paciente/cargue-masivo-paciente/',{pacientes:items})
            showAlert({
                icon:'success',
                html:data?.detail || data.toString()
            })
            setVisible(false)
            setItems([])
            onReload()
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    const columnsKey=[
        'registro',
        'historia',
        'primer_nombre_paciente',
        'segundo_nombre_paciente',
        'primer_apellido_paciente',
        'segundo_apellido_paciente',
        'fecha_nacimiento',
        'edad_gestacional',
        'edad_gestacional_corregida',
        'peso_nacimiento',
        'sexo',
        'boolean_tamizaje',
        'primer_nombre_acudiente',
        'segundo_nombre_acudiente',
        'primer_apellido_acudiente',
        'segundo_apellido_acudiente',
        'numero_documento',
        'correo',
        'telefono'
    ]

    const columns=[
        'Registro civil',
        'Historia',
        'Primer nombre',
        'Segundo Nombre',
        'Primer apellido',
        'Segundo apellido',
        'Fecha nacimiento',
        'Edad gestacional',
        'Edad gestacional corregida',
        'Peso',
        'Sexo',
        'Requiere tamizaje'
    ]

    const columnsLegal=[
        'Primer nombre',
        'Segundo nombre',
        'Primer apellido',
        'Segundo apellido',
        'Número documento',
        'Correo',
        'Teléfono'
    ]

    

    return <Policy policies={[PERMISO_AGREGAR_PACIENTE]}>
    <ReactModal isOpen={visible} style={{content:{background:'transparent',border:'none'}}}>
        <Card content_style="w-[90%] m-auto">

       {loading ? <Loading message="Importando pacientes"/> : <>
       <div className='flex justify-between mb-6'>
                <h2 className='text-lg'>Importar pacientes</h2>
                <button onClick={close} className="rounded-full hover:bg-slate-400/40 p-2">
                    <MdClose />
                </button>
            </div>
            <label className="mb-2 text-md">Seleccione un CSV</label>
           
            
            <input onChange={selected} type="file"/>
            <SimpleBar style={{maxHeight:400}}>
                <table className="text-sm">
                    <thead>
                        <tr>
                            {columns.map((c,i)=>(<th  rowSpan={2} key={i}>{c}</th>))}
                            <th className="border-none">Acudiente</th>
                        </tr>
                        <tr>
                            {columnsLegal.map((c,i)=>(<th key={i}>{c}</th>))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item,i)=>{
                            const tds= columnsKey.map((td,i2)=>(<td key={i2}>{item[td]}</td>))
                            return <tr>{tds}</tr>
                        })}
                    </tbody>
                </table>
            </SimpleBar>
            <div className="p-2 flex justify-end">
                {items.length && <button onClick={importData} className="bg-teal-500 p-2">Importar</button>}
            </div>
            </>}
            
        </Card>
    </ReactModal>
    <button onClick={()=>setVisible(true)} className=" my-3 bg-teal-500 p-2 rounded-md items-center grid grid-cols-[16px,auto] gap-[2px]"><TbDatabaseImport/> Importar</button>
    </Policy>
}

export default Import