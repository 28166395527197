import React,{ useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "API";
import { GROUP_DOCTOR, GROUP_TECHNICIAN } from "Contants";
import Card from "Components/Card";
import {ImSpinner9} from "react-icons/im"
import { UiCtx } from "../../../../../../Context/Ui";

const TamizajeDetailTeam=({tamizaje_id,clinica_id,id_medico,id_tecnico})=>{

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
      } = useForm();
    
    const {showAlert} = useContext(UiCtx)
    const [team,setTeam] = useState([])
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState(null)

    const doctors = team.length ? team.filter((tec)=>parseInt(tec.fk_user.groups[0].codigo) === GROUP_DOCTOR) : []
    const technical =team.length ? team.filter((tec)=>parseInt(tec.fk_user.groups[0].codigo) === GROUP_TECHNICIAN) : []

    const loadTeam =async()=>{
        try {
            setError(null)
            setLoading(true)
          const response = await API.get(`/clinica/usuario/?clinica_id=${clinica_id}`)
          setTeam(response.data)
  
        }catch(error) {
          setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    const save =async(payload)=>{
        try {
            setError(null)
            setLoading(true)
            const {data} = await API.patch(`/coordinador/tamizaje/${tamizaje_id}/asignar/`,payload)
            showAlert({
                icon:'success',
                html:data?.detail || data.toString()
            })
        } catch(error) {
            const e=error?.response?.data?.detail || error.toString()
            setError(e)
            showAlert({
                icon:'error',
                html:e
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(clinica_id){
            loadTeam()
        }
    },[clinica_id])

    useEffect(()=>{
        console.log({id_tecnico,id_medico})
        
        if(team.length){
            setValue("id_medico",id_medico)
            setValue("id_tecnico",id_tecnico)
        }
       
    },[id_tecnico,id_medico,team])



   

    return (<div className="p-4 print:hidden">
        <form onSubmit={handleSubmit(save)}>
            <h1 className="text-lg mb-4">Profesionales responsables</h1>
        
            {error && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>}

        <div className="flex lg:flex-row md:flex-col sm:flex-col">
            <div className="flex-1 lg:mr-2">
                <label className="text-md block mb-4">Técnico</label>
                <select   disabled={loading} {...register("id_tecnico")} disabled={loading} className="w-full rounded-md bg-slate-400/40 py-2 px-4">
                    <option value="">Sin asignar</option>
                    {technical.map((t,i)=>(<option key={t.id} value={parseInt(t.id)}>{t.fk_user.first_name} {t.fk_user.last_name}</option>))}
                </select>
            </div>
            <div className="flex-1 lg:mr-2">
            <label className="text-md block mb-4">Médico</label>
                <select disabled={loading}   {...register("id_medico")} className="w-full rounded-md bg-slate-400/40 py-2 px-4">
                    <option value="">Sin asignar</option>
                    {doctors.map((t,i)=>(<option key={t.id} value={parseInt(t.id)}>{t.fk_user.first_name} {t.fk_user.last_name}</option>))}
                </select>
            </div>
        </div>

        <div className="flex justify-end my-8">
            <button className="bg-slate-400/10 hover:bg-slate-400/40 p-2 rounded-md flex items-center">Asignar profesionales {loading && <ImSpinner9 className="animate-spin ml-2"/>}</button>
        </div>

    </form></div>)
}

export default TamizajeDetailTeam