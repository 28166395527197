import React, { useEffect, useState } from "react";
import {  HiPencilAlt } from "react-icons/hi";
import { API } from "API";
import { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { SecurityCtx } from "Context/Security";
import UserGroup from "Components/UserGroup";
import UserState from "Components/UserState";
import { GROUP_CLINIC, PERMISO_VER_USUARIO_CLINICA,PERMISO_AGREGAR_USUARIO_CLINICA, PERMISO_MODIFICAR_USUARIO_CLINICA, GROUP_ADMIN } from "../../../../Contants";
import Policy from "Components/Policy";
import Loading from "Components/Loading";

const TeamListProtected=()=>{
    const [error,setError] = useState(null)
    const {user,hasRole} = useContext(SecurityCtx)
    const [users,setUsers] = useState([])
    const [loading,setLoading] = useState(false)
  
    const {id_clinica:clinica_id} = user || {}

  

    const load =async()=>{
        try {
          setLoading(true)
            const {data} = await API.get(`/clinica/usuario/?clinica_id=${user.id_clinica}`)
            setUsers(data?.results || data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
          setLoading(false)
        }
    }

    const canEdit=(u)=>{

      if(!u?.fk_user?.groups?.length){
        return false
      }

      if(u?.fk_user?.groups[0].codigo==GROUP_CLINIC && user?.usuario_clinica!=u?.id){
        return false
      }

      return true

    }

    useEffect(()=>{
      if(clinica_id){
        load()
      }
    },[clinica_id])

    if(hasRole(GROUP_ADMIN)){
      return <Navigate to='/dashboard' replace/>
    }

    return (<div className="p-4">
        <Policy show_error={false} policies={[PERMISO_AGREGAR_USUARIO_CLINICA]}>
        <div className="flex justify-end mb-6">
            <Link to='/manager/team/create' className="p-2 bg-slate-400/40 rounded-md hover:bg-slate-200/10">Nuevo usuario</Link>
        </div>
        </Policy>
        {error && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>}

        {loading && <Loading/>}
    <table>
        <thead>
          <tr>
            <th></th>
            <th>Rol</th>
            <th>Sede</th>
            <th>Cupos por día</th>
            <th>Nombre</th>
            <th>Usuario</th>
            <th>Email</th>
            <th>Identificación</th>
            <th>Estado</th>
            
            <Policy show_error={false} policies={[PERMISO_MODIFICAR_USUARIO_CLINICA]}>
            <th>Actualizar</th>
            </Policy>
            
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u?.id} className="hover:bg-slate-400/40">
              <td className="w-[32px] h-[32px] ">
                <img className="w-[32px] h-[32px] rounded-full bg-teal-500" src={u.foto_user}/>
              </td>
              <td><UserGroup {...u?.fk_user?.groups[0]}/></td>
              <td>
                {u?.sede.map((s,i)=><span key={i} className="bg-slate-400/40 rounded-sm p-[2px] px-[4px] m-[1px]">{s.nombre}</span>)}
              </td>
              <td className="text-center">{u.cupos_por_dia}</td>
              <td>{u?.fk_user?.first_name} {u?.fk_user?.last_name}</td>
              <td>{u?.fk_user?.username}</td>
              <td>{u?.fk_user?.email}</td>
              <td>{u?.numero_identificacion}</td>
              <td>
              {parseInt(u?.fk_user?.groups[0].codigo)!=GROUP_CLINIC ? <UserState sys_active={u.sys_active} id={u.id} clinica_id={clinica_id} /> : null} 
              </td>
              <Policy show_error={false} policies={[PERMISO_MODIFICAR_USUARIO_CLINICA]}>
                <td>
                  {canEdit(u) && <Link title="Click para actualizar" to={`/manager/team/${u.id}/update`}>
                    <HiPencilAlt
                      size={20}
                      className="cursor-pointer hover:scale-125 ml-4"
                    />
                  </Link>}
                </td>
              </Policy>
              
            </tr>
          ))}
        </tbody>
    </table>
  </div>)

}

const TeamList=()=>{
  return <Policy policies={[PERMISO_VER_USUARIO_CLINICA]}>
    <TeamListProtected/>
  </Policy>
}

export default TeamList