import { API } from "API"
import Input from "Components/Input/index"
import { SecurityCtx } from "Context/Security"
import { UiCtx } from "Context/Ui"
import { useContext, useState } from "react"
import {ImSpinner9} from "react-icons/im"

const ChangePassword=()=>{
    const {showAlert} = useContext(UiCtx)
    const {user,setUser} = useContext(SecurityCtx)
    const [pass,setPass]=useState('')
    const [pass2,setPass2]=useState('')
    const [loading,setLoading] = useState(false)

    const update=async ()=>{
        try{
            if(pass=='' || pass2==''){
                throw('Ingrese las contraseñas')
            }
            if(pass!=pass2){
                throw('Las contraseñas no coinciden')
            }
            setLoading(true)
            await API.patch("clinica/usuario/contrasena/",{contrasena:pass,id_user:user.usuario_clinica})
            setUser(prev=>({...prev,actualizar_clave:false}))
            showAlert({
                icon:'success',
                html:'Contraseña actualizada correctamente'
            })
        }catch(error){
            showAlert({
                icon:'error',
                html:error?.response?.data?.detail || error.toString()
            })
        }finally{
            setLoading(false)
        }
        //setUser(prev=>({...prev,actualizar_clave:false}))
    }

    return (<div className="w-[60%] m-auto mt-6">
        <h2 className="text-lg mb-4">Actualizar contraseña</h2>
        {user?.actualizar_clave && <p className="p-2 my-4 bg-blue-500 text-slate-300 rounded-md">Se requiere actualizar la clave</p>}
        <Input type="password" desactivar={loading} descripcion="Contraseña" register={{onChange:(e)=>setPass(e.target.value)}}/>
        <Input type="password" desactivar={loading} descripcion="Confirmar contraseña" register={{onChange:(e)=>setPass2(e.target.value)}}/>
        <button className="p-2 mt-4 bg-slate-400/40 rounded-md flex" onClick={update}>{loading && <ImSpinner9 className="mr-2 animate-spin"/>} Actualizar</button>
    </div>)
}

export default ChangePassword