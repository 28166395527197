import ReactModal from "react-modal"
import Card from "../../../../Components/Card"
import { useForm } from 'react-hook-form'
import Input from "../../../../Components/Input"
import SelectDepartament from "../../../../Components/SelectDepartament"
import SelectCity from "../../../../Components/SelectCity"
import {ImSpinner9} from "react-icons/im"
import {MdClose} from "react-icons/md"
import { useState } from "react"
import { API } from "../../../../API"
import { useEffect } from "react"
import SimpleBar from 'simplebar-react';
import SelectCountry from "../../../../Components/SelectCountry"
import FormError from "../../../../Components/FormError"

const SiteSave=({onReload,record,setRecord})=>{
    const [loading,setLoading]=useState(false)
    const { register, handleSubmit,setValue, watch, formState: { errors } } = useForm();
    const [error,setError] = useState(null)

    const dep=watch('departamento_sede')
    const pais=watch("pais_sede")

    const close=()=>{
        if(loading) return

        setRecord(null)
    }

    const create=async (payload)=>{
        if(record.id){
            return
        }
        const {data} = await API.post(`clinica/sede/crear/`,payload)
    }

    const update=async (payload)=>{
        if(!record.id){
            return
        }
        const {data} = await API.patch(`clinica/sede/${record.id}/actualizar/?cliente=${payload.cliente}`,payload)
    }

   
    const save=async (payload)=>{
      
        try{
            setError(null)
            setLoading(true)
            await create(payload)
            await update(payload)
            setRecord(null)
            onReload()
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(!record){
            setError(null)
        }else if(record?.id){
            setValue('nombre_sede',record.nombre)
            setValue('direccion',record.direccion)
            setValue('codigo_sede',record.codigo)
            setValue('pais_sede',record?.fk_pais?.id)
            setValue('departamento_sede',record?.departamento?.id)
            setValue('ciudad_sede',record?.ciudad?.id)
            setValue('responsable_sede',record?.responsable_sede)
            setValue('telefono_responsable',record?.telefono_responsable)
        }else{
            setValue('nombre_sede',"")
            setValue('direccion',"")
            setValue('codigo_sede',"")
            setValue('departamento_sede',"")
            setValue('ciudad_sede',"")
            setValue('pais_sede','')
            setValue('responsable_sede','')
            setValue('telefono_responsable','')
        }
        if(record?.cliente){
            setValue('cliente',record.cliente)
        }

    },[record])

    return <ReactModal onRequestClose={close} isOpen={record} style={{content:{background:'transparent',border:'none'}}}>
        <Card content_style="w-[70%] m-auto">
            <div className='flex justify-between mb-6'>
                <h2 className='text-lg'>{record?.id ? "Actualizar" :"Registrar"} sede</h2>
                <button onClick={close} className="rounded-full hover:bg-slate-400/40 p-2">
                <MdClose />
                </button>
            </div>

            <form onSubmit={handleSubmit(save)}>
                <SimpleBar>
                    <div className="flex mb-4">
                        <div className="flex-1 lg:mr-2">
                            
                        <Input descripcion="Nombre *" register={register("nombre_sede",{required:true})}/>
                        <FormError errors={errors} name="nombre_sede"/>
                        </div>
                        <div className="flex-1 lg:mr-2">
                            <Input descripcion="Dirección *" register={register("direccion",{required:true})}/>
                            <FormError errors={errors} name="direccion"/>
                        </div>
                        <div className="flex-1 lg:mb-2">
                            <Input descripcion="Código *" register={register("codigo_sede",{required:true})}/>
                            <FormError errors={errors} name="codigo_sede"/>
                        </div>
                        <div className="flex-1 lg:mb-2">
                            <Input descripcion="Número de camas (neonatos) *" register={register("numero_cama",{required:true})}/>
                            <FormError errors={errors} name="numero_cama"/>
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="flex-1 lg:mr-2">
                            <Input descripcion="Encargado *" register={register("responsable_sede",{required:true})}/>
                            <FormError errors={errors} name="responsable_sede"/>
                        </div>
                        <div className="flex-1 lg:mr-2">
                            <Input descripcion="Email *" register={register("email_responsable",{required:"El email es requerido con este se inicia sesión",pattern:{value:/\S+@\S+\.\S+/,message:'Ingrese un email valido'}})}/>
                            <FormError errors={errors} name="email_responsable"/>
                        </div>
                        <div className="flex-1 lg:mr-2">
                            <Input descripcion="Teléfono *" register={register("telefono_responsable",{required:true})}/>
                            <FormError errors={errors} name="telefono_responsable"/>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex-1 lg:mr-2">
                            <SelectCountry register={register("pais_sede",{required:true})}/>
                            <FormError errors={errors} name="pais_sede"/>
                        </div>
                        <div className="flex-1 lg:mr-2">
                            <SelectDepartament pais={pais} register={register("departamento_sede",{required:true})}/>
                            <FormError errors={errors} name="departamento_sede"/>
                        </div>
                        <div className="flex-1 lg:mb-2">
                            <SelectCity dep={dep} register={register("ciudad_sede",{required:true})}/>
                            <FormError errors={errors} name="ciudad_sede"/>
                        </div>
                    </div>
                    
                    
                    
                    
                    
                   
                </SimpleBar>
                {error && <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>}
                <div className="flex justify-end my-4">
                    <button disabled={loading} className="flex bg-slate-400/40 rounded-md p-2">Guardar {loading && <ImSpinner9 className="ml-2 animate-spin"/>}</button>
                </div>
            </form>
        </Card>
    </ReactModal>
}

export default SiteSave