import React, { createContext } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export const MySwal = withReactContent(Swal)



export const UiCtx = createContext(null)


const Ui=({children})=>{

    const showAlert=(opts)=>{
        return MySwal.fire({
          background: "#03062A",
          color: "rgb(226 232 240)",
          confirmButtonColor: "#14b8a6",
          title: "",
          ...opts,
        });
    }

 

    return (<UiCtx.Provider value={{showAlert}}>{children}</UiCtx.Provider>)
}

export default Ui