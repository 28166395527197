import { API } from 'API';
import { SecurityCtx } from 'Context/Security';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Pie } from 'react-chartjs-2';

const TamizajesPorSede=({tamizajes})=>{
    
    const {user,hasRole} = useContext(SecurityCtx)
    const [sedes,setSedes] = useState([])
    const [loading,setLoading] = useState(false)

    const loadSites=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('clinica/sede')
            setSedes(data)
        }catch(error){

        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        loadSites()
    },[])


    if(!tamizajes.length){
        return <p>No hay datos que procesar</p>
    }

    if(!sedes.length){
        return null
    }

    let data=[]
    sedes.forEach((sede)=>{
        const count = tamizajes.filter(t=>t.fk_sede.id == sede.id).length
        data.push(count)
    })

    const options={
        plugins:{
        
            datalabels:{
                formatter: (value, ctx) => {
                    let datasets = ctx.chart.data.datasets;
                    if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                      let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                      let percentage = Math.round((value / sum) * 100) + '%';
                      return percentage;
                    } else {
                      return 0;
                    }
                  },
                  color: '#fff',
            }
        
    }
    }


    return (
            <Pie
        data={{
            labels: sedes.map(s=>s.nombre),
            datasets: [{
              label: '# de Tamizajes por sede',
              data:data,
              borderWidth: 1,
            }]
        }}    
        options={options}
        
        />
    )
}

export default TamizajesPorSede