import { API } from "API";
import Loading from "Components/Loading";
import TamizajeStatus from "Components/TamizajeStatus";
import { GROUP_MANAGER,GROUP_CLINIC,GROUP_DOCTOR,GROUP_TECHNICIAN } from "Contants";
import { SecurityCtx } from "Context/Security";
import { UiCtx } from "Context/Ui";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

const { default: Pagination } = require("Components/Pagination")

const Atenciones=({paciente})=>{

    const navigation = useNavigate()
    const {showAlert} = useContext(UiCtx)
    const {hasRole} = useContext(SecurityCtx)

    const [page,setPage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [count,setCount] = useState(0)
    const [items,setItems] = useState([])

    const getAtenciones = async () => {
        try {
            setLoading(true)
          const {data} = await API.get(`/coordinador/tamizaje/?id_paciente=${paciente}`);
          setCount(data.count)
          setItems(data.results);
        } catch (error) {
          showAlert({
            icon:'error',
            html:error?.response?.data?.detail || error.toString()
          })
        }finally{
            setLoading(false)
        }
    };

    const onPage=(p)=>{
        setPage(p)
    }

      useEffect(()=>{
        getAtenciones()
      },[page])


      const modo_admin = !hasRole([GROUP_MANAGER,GROUP_CLINIC,GROUP_DOCTOR,GROUP_TECHNICIAN])

    return (
        <>
        {loading && <Loading message="Cargando historial de atenciones"/>}
        <table className="">
            <thead>
              <tr className='bg-slate-600/40'>
                <th>Estado</th>
                <th>Autorizado por</th>
                <th>Médico</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
            
            {items.map((atencion)=>(
                <tr key={atencion.id} className="cursor-pointer hover:bg-slate-400/40"
                onClick={()=>navigation(modo_admin ? `/admin/patient/${paciente}/tamizajes/${atencion.id}/detail` : `/manager/patient/${paciente}/tamizajes/${atencion.id}/detail`)}
                >
                    <td>
                        <TamizajeStatus>{atencion.estado}</TamizajeStatus>
                    </td>
                  <td>{atencion.autorizado_por}</td>
                  <td>{atencion.fk_medico.fk_user.first_name} {atencion.fk_medico.fk_user.last_name}</td>
                  <td>{dayjs(atencion.sys_FechaCreacion).format("DD/MM/YYYY")}</td>
                </tr>
            ))}
            
            </tbody>
          </table>
          <Pagination count={count} onPage={onPage}/>
          </>
    )
}

export default Atenciones