import React, { useContext,useEffect } from 'react'
import {IoSyncOutline} from "react-icons/io5" 
import { useState } from 'react'
import { API } from '../../API'

const SelectCiiu = ({multiple=true,register={}}) => {
 
    const [items,setItems] = useState([])
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)

    const load= async ()=>{
   
        try{
            setError(null)
            setLoading(true)
            const {data} = await API(`/configuracion/ciiu/`)
            setItems(data)
        }catch(error){
            setError(error?.response?.data?.detail || error.toString())
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])


   
    if(loading){
        return <span className='text-center'>Cargando CIIU...</span>
    }

    if(error){
        return <p className='bg-red-500 text-slate-200 p-2 rounded-md'>CIIU no cargados: {error}. <button type='button' onClick={load} className="cursor-pointer bg-red-800 p-2 rounded-md">Recargar</button></p>
    }


  return (
    <div>
        <label className='text-sm block mb-2 px-[8px]'>CIIU {register?.required ? "*":""}</label>
    <div className='flex  items-center '>
        <select multiple={multiple} className="rounded-md border-[#2f3855] bg-[#2f3855] pb-[5px]t-[5px] p p-2 flex-1 px-[8px] "
        {...register}
        >
            {!multiple && <option value="">Seleccione</option>}
        {
        items.map((item)=>(

                <option key={item.id} value={item.id}>{item.nombre}</option>
        
        ))

      }
        </select>

        <button type='button' onClick={load} className="text-lg ml-2"
        title='Recargar CIIU'
        >
            <IoSyncOutline/>
        </button>
      
    </div>
    </div>
  )
}

export default SelectCiiu
