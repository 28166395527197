import React, { useEffect, useState } from "react";
import Pagination from "../../../Components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import { API } from "../../../API";
import { HiOutlineUsers} from "react-icons/hi";
import {FiEdit} from "react-icons/fi"
import Loading from "../../../Components/Loading";
import { useContext } from "react";
import { SecurityCtx } from "../../../Context/Security";
import { UiCtx } from "../../../Context/Ui";
import ClinicStatus from "./Components/Status";
import Policy from "Components/Policy";
import {PERMISO_AGREGAR_CLIENTES, PERMISO_MODIFICAR_CLIENTES, PERMISO_VER_CLIENTES, PERMISO_VER_USUARIO_CLINICA} from "Contants"

const Index = () => {
  const navigation = useNavigate();
  const {page_customer,setPageCustomer} = useContext(SecurityCtx)
  const {showAlert} = useContext(UiCtx)

  const [loading,setLoading]=useState(false)
  const [clinics, setClinics] = useState([]);
  const [count, setCount] = useState([]);

  const {page,query}=page_customer 

  const onChangeStatus=(id,status)=>{
    setClinics(current_clinics=>{
      return current_clinics.map(c=>{
        if(c.id == id){
          return {...c,sys_active:status}
        }
        return c
      })
    })
  }

  const listClinics = async () => {
    try {
      setLoading(true)
      const response = await API.get("/cliente/",{params:{page:page,nombre:query}});
      setCount(response.data);
      setClinics(response.data.results);
    } catch (error) {
      showAlert({html:error?.response?.data?.detail || error.toString(),icon:'error'});
    }finally{
      setLoading(false)
    }
  };

  const onPage=(p)=>{
    setPageCustomer(prev=>({...prev,page:p}))
  }
  const filter=()=>{
    setPageCustomer(prev=>({...prev,page:1}))
  }



  useEffect(() => {
    listClinics();
  }, [page,query]);



  return (
    <>
    {loading && <Loading/>}
      <div className="my-4 mx-4">
        <div className="flex-row flex items-center justify-between mb-4">
          <div className="">
            <input value={query} onChange={(e)=>setPageCustomer(prev=>({...prev,query:e.target.value}))}
              type="text"
              placeholder="Buscar"
              className="rounded-md border-[#2f3855] bg-[#2f3855] py-[5px] pl-2"
              size={35}
            />
            <button className="ml-2 hover:bg-slate-400/40 p-2 rounded-md" onClick={filter}>Buscar</button>
          </div>
         
        
          
          <Policy show_error={false} policies={[PERMISO_AGREGAR_CLIENTES]}>
          <div
            className="ml-2  hover:bg-slate-500 rounded-[20px]  cursor-pointer"
            onClick={() => navigation("/admin/clinics/add")}
          >
            <div className="flex items-center">
              <h3 className="ml-2 text-md">Agregar Cliente</h3>
           
                <IoAddCircle size={25} />
             
            </div>
          </div>
          </Policy>
        </div>

        <table>
          <thead>
            <tr>
              <th>Razón social</th>
              <th>Dirección</th>
              <th>Celular</th>
              <th>NIT</th>
              <th>CIIU</th>
              <th>País</th>
              <th>Activo</th>
              <Policy show_error={false} policies={[PERMISO_MODIFICAR_CLIENTES]}>
              <th>Actualizar</th>
              </Policy>
              <Policy show_error={false} policies={[PERMISO_VER_USUARIO_CLINICA]}>
                <th>Equipo de trabajo</th>
              </Policy>
            </tr>
          </thead>
          <tbody>
            {clinics.map((clinic) => (
              <tr key={clinic.id} className="cursor-pointer hover:bg-slate-400/40">
                <td>{clinic.razon_social}</td>
                <td>{clinic.direccion}</td>
                <td>{clinic.celular}</td>
                <td>{clinic.nit}</td>
                <td>{clinic.ciiu}</td>
                <td>
                  {clinic?.fk_pais?.nombre}
                </td>
                <td className="text-center">
                  <ClinicStatus onChange={onChangeStatus} id={clinic.id} sys_active={clinic.sys_active}/>
                </td>
                <Policy show_error={false} policies={[PERMISO_MODIFICAR_CLIENTES]}>
                  <td className="text-center">
                    <Link to={`/admin/clinics/${clinic.id}/edit`}><FiEdit/></Link>
                  </td>
                </Policy>
                <Policy show_error={false} policies={[PERMISO_VER_USUARIO_CLINICA]}>
                  <td>
                    <Link to={`/admin/clinics/${clinic.id}/users`}><HiOutlineUsers/></Link>
                  </td>
                </Policy>
                
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination records={count.count} key={1} onPage={onPage} initial_page={page}/>
      </div>
    </>
  );
};

const ClinicList=()=>{
  return <Policy policies={[PERMISO_VER_CLIENTES]}>
    <Index/>
  </Policy>
}

export default ClinicList;
