import React, { useEffect, useState} from 'react'
import Pagination from '../../../Components/Pagination'
import { HiPencilAlt} from "react-icons/hi"
import {BiSearchAlt} from "react-icons/bi"
import { Link, useNavigate } from 'react-router-dom'
import { API } from '../../../API'
import { useContext } from 'react'


import { SecurityCtx } from '../../../Context/Security'
import PatientFilters from './Filters'
import FullName from '../../../Components/FullName'
import {  GROUP_CLINIC, GROUP_DOCTOR, GROUP_MANAGER,GROUP_TECHNICIAN,PERMISO_AGREGAR_PACIENTE,PERMISO_MODIFICAR_PACIENTE,PERMISO_VER_PACIENTE } from '../../../Contants'
import Export from './Export'
import Policy from 'Components/Policy'
import { UiCtx } from 'Context/Ui'
import Loading from 'Components/Loading'
import Import from './Import'
import { useForm} from 'react-hook-form'
const Index = () => {

 
  const navigation = useNavigate()
  const [loading,setLoading] = useState(false)
  const {page_patient,setPagePatient,user,hasRole}=useContext(SecurityCtx)
  const {showAlert} = useContext(UiCtx)

  const [pacientes,setPacientes] = useState([])
  const [count,setCount]=useState(0)
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const cliente_del_usuario = user?.id_clinica
  const clinica_id = watch('clinica_id')
  

  const onPage=(p)=>{
    setPagePatient(prev=>({...prev,page:p}))
  }




  const getPacientes =async()=>{
    try {
        setLoading(true)
        const {clinica_id:_id_clinica,...params} = page_patient
        const id_clinica = _id_clinica?.value || user?.id_clinica || null

        const {data} = await API.get(`/coordinador/paciente/`,{params:{clinica_id:id_clinica,...params}})
        setPacientes(data.results)
        setCount(data.count)
    } catch(error) {
      showAlert({icon:'error',html:error?.response?.data || error.toString()})
    }finally{
      setLoading(false)
    }
  }

  const onFilter=(filters)=>{
    setPagePatient(prev=>({...prev,...filters}))
  }

  const reload=()=>{
    onFilter({t:Date.now()})
  }

  useEffect(()=>{
    if(user){
      getPacientes()
    }
  },[page_patient,user])

  const modo_admin = !hasRole([GROUP_MANAGER,GROUP_CLINIC,GROUP_DOCTOR,GROUP_TECHNICIAN])

  const getLink=(paciente)=>{
    return  modo_admin ? `/admin/patient/${paciente.id}/detail` : `/manager/patient/${paciente.id}/detail`
  }

  return (
<>
    <div className='flex justify-between p-4'>
      <PatientFilters clinica_id={cliente_del_usuario || clinica_id} control={control} register={register} setValue={setValue} handleSubmit={handleSubmit} modo_admin={modo_admin} loading={loading} page_patient={page_patient} onFilter={onFilter}/>
      <div className='border-slate-400/40 border-[1px] p-2 rounded-md flex flex-col ml-2'>
        <Policy policies={[PERMISO_AGREGAR_PACIENTE]} show_error={false}>
          <Link to='/manager/patient/create' className='w-full text-center bg-teal-500 p-[2px] px-[4px] rounded-md mb-2'>
          Nuevo paciente
          </Link>
        </Policy>
        <Export cliente={cliente_del_usuario || clinica_id?.value}/>
        <Import cliente={cliente_del_usuario || clinica_id?.value} onReload={reload}/>
      </div>
    </div>
    {loading && <Loading/>}
    <div className='p-4'>
      <table>
        <thead>
          <tr>
            <th></th>
            {modo_admin && <th>Cliente</th>}
            <th>Alta médica</th>
            <th>No. HC</th>
            <th>Nombre</th>
            <th>Fac. riesgo</th>
            <th>Entidad</th>
            <th>Aseguradora</th>
            <th>Acudiente</th>
            <th>Estado</th>
            <Policy show_error={false} policies={[PERMISO_MODIFICAR_PACIENTE]}>
              <th>Actualizar</th>
            </Policy>
          </tr>
        </thead>
        <tbody>
          {
            pacientes.map((paciente)=>(

          <tr
          key={paciente.id}
          className="hover:bg-slate-400/40"
          >
            <td className='cursor-pointer hover:scale-150' onClick={()=>navigation(getLink(paciente))}>
             
                <BiSearchAlt/>
            </td>
             {modo_admin && <td onClick={()=>navigation(getLink(paciente))}>{paciente?.fk_clinica?.razon_social}</td>}
            <td>{paciente?.dado_de_alta ? "Sí":"No"}</td>
            <td>{paciente?.historia_clinica}</td>
            <td>
              <FullName names={[paciente?.primer_nombre_paciente,paciente?.segundo_nombre_paciente,paciente?.primer_apellido_paciente,paciente?.segundo_apellido_paciente]}/>
            </td>
            <td>
              {paciente?.factores_riesgo?.map((f,i)=>(<span key={i} className='bg-slate-400/40 rounded-full px-[4px] p-[2px] m-[2px]'>{f.factor_riesgo}</span>))}
            </td>
            <td>{paciente?.entidad?.nombre_entidad}</td>
            <td>{paciente?.aseguradora?.nombre_aseguradora}</td>
            <td>
              <FullName names={[paciente?.primer_nombre_acudiente,paciente?.segundo_nombre_acudiente,paciente?.primer_apellido_acudiente,paciente?.segundo_apellido_acudiente]}/>
            </td>
            <td>{paciente?.estado_paciente?.estado}</td>
            <Policy show_error={false} policies={[PERMISO_MODIFICAR_PACIENTE]}>
              <td>
                <Link to={"/manager/patient/"+paciente.id+"/update"}>
                  <HiPencilAlt
                  size={20}
                  className="cursor-pointer hover:scale-125 ml-4"
                  title="Click para actualizar"
                />
                </Link> 
              </td>
            </Policy>
                  
            
          </tr>)) 
          }
        </tbody>
      </table>
      <Pagination records={count} onPage={onPage}/>
    </div>
</>
  )
}

const PacienteList=()=>{
  return <Policy policies={[PERMISO_VER_PACIENTE]}>
    <Index/>
  </Policy>
}

export default PacienteList

