const Card=({content_style='',children})=>{
    return (
    <div className={"relative z-1 min-w-[30%] w-full "+content_style}>
    <div className="absolute z-[-1] shadow-2xl left-[-2px] top-[-2px] w-[20%] h-[20%] shadow-black">&nbsp;</div>
    <div className="absolute z-[-1] shadow-2xl right-1 bottom-5 w-[20%] h-[20%] shadow-white/40">&nbsp;</div>
    <div  className="p-[1px] rounded-[24px] bg-gradient-to-b from-slate-200 via-slate-800 via-slate-500 to-emerald-100">
        <div className="shadow-inner p-[32px] w-full rounded-[24px] bg-gradient-to-b from-primary/70 to-primary">
       
            {children}
       
        </div>
    </div>
</div>)
}

export default Card