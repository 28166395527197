import { API } from "API"
import { GROUP_DOCTOR, GROUP_TECHNICIAN } from "Contants"
import { SecurityCtx } from "Context/Security"
import { useContext, useEffect, useState } from "react"

const TamizajesPorUsuario=({tamizajes})=>{
    const {user} = useContext(SecurityCtx)
    const [team,setTeam] = useState([])

    console.log({user})

    const loadTeam= async ()=>{
        try{
            const {data} = await API('clinica/usuario/?clinica_id='+user.id_clinica)
            setTeam(data)
        }catch(error){
            
        }
    }

    useEffect(()=>{
        if(user){
            loadTeam()
        }
    },[user])

    if(!team.length){
        return null
    }
    if(!tamizajes.length){
        return null
    }

    

    const tecnicos = team.filter(t=>t?.fk_user?.groups?.some(g=>parseInt(g.codigo)==GROUP_TECHNICIAN)).map((tecnico)=>{
        const _tamizajes = tamizajes.filter(t=>t?.fk_tecnico?.id==tecnico.id && t?.estado!='1').length
        const tamizajes_realizados = tamizajes.filter(t=>t?.fk_tecnico?.id==tecnico.id && t?.estado!='1').length
        return {
            ...tecnico,
            tamizajes:_tamizajes,
            tamizajes_realizados
        }
    })

    const medicos = team.filter(t=>t?.fk_user?.groups.some(g=>parseInt(g.codigo)==GROUP_DOCTOR)).map((medico)=>{
        const _tamizaje = tamizajes.filter(t=>t?.fk_medico?.id == medico.id && t?.estado!='1')
        const tamizajes_realizados = _tamizaje.filter(t=> t?.estado == '2' || t?.estado == '4').length
        return{
            ...medico,
            tamizajes: _tamizaje.length,
            tamizajes_realizados,
        }
        
    })
  
    return (<div className='border-[1px] border-slate-400/40 p-8 rounded-md'>
        <h2 className="mb-4 text-slate-100 text-lg">Número de tamizajes realizados por técnico</h2>
        <div className="grid gap-2 grid-cols-[repeat(auto-fit,200px)] place-self-center">
            {tecnicos.map((t,i)=>(<div className="w-[200px] flex flex-col items-center" key={i}>
                <img className="w-[100px] h-[100px] bg-teal-500 rounded-full" src={t.foto_user}/>
                <span className="text-slate-400" style={{textAlign:'center'}}>{t?.fk_user?.first_name} {t?.fk_user?.last_name}</span>
                <span className="text-slate-500">{t?.tamizajes_realizados || 0} / {t?.tamizajes || 0}</span>
            </div>))}
        </div>

        <h2 className="mb-4 text-slate-100 text-lg mt-6">Número de tamizajes leídos por médico</h2>
        <div className="grid gap-2 grid-cols-[repeat(auto-fit,200px)] place-self-center">
        {medicos.map((t,i)=>(<div className="w-[200px] flex flex-col items-center" key={i}>
                <img className="w-[100px] h-[100px] bg-teal-500 rounded-full" src={t.foto_user}/>
                <span className="text-slate-400" style={{textAlign:'center'}}>{t?.fk_user?.first_name} {t?.fk_user?.last_name}</span>
                <span className="text-slate-500">{t?.tamizajes_realizados || 0} / {t?.tamizajes || 0}</span>
            </div>))}
        </div>
    </div>)
}

export default TamizajesPorUsuario