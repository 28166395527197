import { useContext } from "react"
import { SecurityCtx } from "../../Context/Security"

const TamizajeStatus=({children})=>{

    const {getTamizajeStates} = useContext(SecurityCtx)
    const states = getTamizajeStates()

    const state = states?.find(s=>s.value == parseInt(children))
    if(!state){
        return children
    }

    const c1=state?.color
    const c2=state?.color

        return <div  style={{'var(--c1)': c1}}  className={`${c1} rounded-md  flex items-center justify-start px-[4px] py-[2px]`}>
                <span className={`${c2} w-[8px] h-[8px] rounded-full mr-[4px]`}></span>
                <small className="text-sm whitespace-normal">{state?.label}</small>
            </div>
    
   

}

export default TamizajeStatus