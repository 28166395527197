import { useState } from "react"
import {ImSpinner9} from "react-icons/im"
import { API } from "../../../../../API"

const SedeState=({clinica_id,id,sys_active,onChange})=>{
    const [state,setState]=useState(sys_active)
    const [loading,setLoading] = useState(false)

    const inactivate=async ()=>{
        await API.delete(`clinica/sede/${id}/eliminar/`)
        setState(false)
        onChange(id,false)
    }
    const activate=async ()=>{
        await API.patch(`/clinica/sede/${id}/restaurar/`)
        setState(true)
        onChange(id,true)
    }

    const toggle=async ()=>{
        if(loading){
            return
        }
        setLoading(true)
        try{
            if(state){
                await inactivate()
            }else{
                await activate()
            }
        }catch(error){

        }finally{
            setTimeout(()=>{
                setLoading(false)
            },800)
        }
       
        
    }

    const state_str = state ? "Activa" : "Inactiva"
    const clase = state ? "bg-slate-800" : "bg-slate-800/50"
    const clase2 = state ? "bg-green-400" : "bg-red-400"
    const order = state ? "order-1" : "order-2"
    const order2 = state ? "order-2" : "order-1"

    return <div onClick={toggle} className={"rounded-full cursor-pointer p-[2px] text-center items-center flex justify-between text-sm overflow-hidden  "+clase}>
       
            <span className={"ease-in-out flex-1 text-center duration-500 "+order}>{state_str}</span>
            <div className={"ease-in-out duration-500 flex justify-center items-center rounded-full w-[16px] h-[16px] "+clase2+" drop-shadow-md "+order2}>
                {loading && <ImSpinner9 className="animate-spin"/>}
            </div>
        </div>
}

export default SedeState