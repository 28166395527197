import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../Components/Pagination";
import useModal from "../../../hooks/useModal";
import Modal from "react-modal";
import InfoPaciente from "../../../Components/InfoPaciente";
import { API } from "../../../API";
import TamizajeStatus from "../../../Components/TamizajeStatus";
import FullName from "../../../Components/FullName";
import { TamizajeCtx } from "../../../Context/Tamizaje";
import Atenciones from "./Atenciones";

const moment = require("moment")


const DetailsPacientes = () => {
  const navigation = useNavigate();
  const { openModal, modalVisble, setModalVisible, customStyles, closeModal } =
    useModal();
  const location = useLocation();
  const {id:idPaciente} = useParams()
  const [atenciones, setAtenciones] = useState([]);
  const {idTamizaje} = useParams()
  const [loading,setLoading]=useState(false)
  const [error,setError]=useState(null)
  const [detail,setDetail]=useState(null)
  const [detail_patient,setDetailPatient]=useState(null)



  const getDetailPatient = async () => {
    try {
      const response = await API.get(`coordinador/paciente/${idPaciente}`);
      setDetailPatient(response.data);
    } catch (error) {
      alert(error?.response?.data);
    }
  };

   

  const load =async()=>{
    getDetailPatient()
  }

  useEffect(() => {
    load()
   
  }, []);

  if(loading){
    return <div className="flex w-full h-full items-center justify-center"><h1 className="text-2xl">Cargando...</h1></div>
  }

  if(error){
    return <div className="flex w-full h-[40%] items-center justify-center flex-col">
      <div className='p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800' role='alert'><span className='font-medium'></span> {error}</div>
      <button onClick={load}>Volver ha cargar</button>
      </div>
  }

  return (
    <>
     <div className="flex flex-row">
      <div className="min-h-[100vh] w-[25%] p-4 border-r-[1px] border-r-slate-800">
          <h3 className="text-sm">Información del paciente</h3>
          <label className="text-slate-400">Entidad</label>
          <p className="text-sm mb-2">{detail_patient?.entidad?.nombre_entidad}</p>
          <label className="text-slate-400">Sede</label>
          <p className="text-sm mb-2">{detail_patient?.sede?.nombre}</p>
          <label className="text-slate-400">Historia clínica UCIN</label>
          <p className="text-sm mb-2">{detail_patient?.historia_clinica}</p>
          <label className="text-slate-400">Fecha nacimiento</label>
          <p className="text-sm mb-2">{detail_patient?.fecha_nacimiento}</p>
          <label className="text-slate-400">Edad gestacional</label>
          <p className="text-sm mb-2">{detail_patient?.edada_gestional} semanas</p>
          <label className="text-slate-400">Edad gestacional corregida</label>
          <p className="text-sm mb-2">{detail_patient?.edada_gestional_corregida} semanas</p>
          <label className="text-slate-400">Peso de nacimiento</label>
          <p className="text-sm mb-2">{detail_patient?.peso_nacimiento} gramos</p>
          <label className="text-slate-400">Sexo</label>
          <p className="text-sm mb-2">{detail_patient?.sexo_paciente}</p>
          <label className="text-slate-400">Estado</label>
          <p className="text-sm mb-2">{detail_patient?.estado_paciente?.estado}</p>
          <div className="mt-4 mb-3 border-t-[1px] border-t-slate-500 border-dashed"></div>
          <label className="text-slate-400">ID. del acudiente</label>
          <p className="text-sm mb-2">{detail_patient?.id_acudiente}</p>
          <label className="text-slate-400">Nombre del acudiente</label>
          <p className="text-sm mb-2"><FullName names={[detail_patient?.primer_nombre_acudiente,detail_patient?.primer_apellido_acudiente]}/></p>
          <label className="text-slate-400">Teléfono</label>
          <p className="text-sm mb-2">{detail_patient?.telefono_acudiente}</p>
          <label className="text-slate-400">Correo electrónico</label>
          <p className="text-sm mb-2">{detail_patient?.gmail_acudiente}</p>
          <div className="mt-4 mb-3 border-t-[1px] border-t-slate-500 border-dashed"></div>
          
        </div>
      <div className="flex-1 p-6">
        <div className="">
          <text className="text-[22px] font-bold">Historial de Atenciones</text>
          <Atenciones paciente={idPaciente}/>
        </div>
      </div>
      </div>
    </>
  );
};

export default DetailsPacientes;
