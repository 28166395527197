import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';

const TamizajeUso=({tamizajes})=>{

    if(!tamizajes.length){
        return <p>No hay datos que procesar</p>
    }
 

    const _data=tamizajes.map((t)=>({x:dayjs(t.fecha_atencion).format('YYYY-MM-DD'),y:1}))

    const dataD3=window.d3.group(_data, d => d.x)
  
    const data = [],labels=[]
    dataD3.forEach((groups)=>{
        const x = groups[0].x
        const y = groups.reduce((a,b)=>a+b.y,0)
        data.push({x,y})
        labels.push(x)
    })

    const config={
        data:{
            labels,
            datasets:[
                {
                    label:'Tamizajes',
                    data:data
                }
            ]
        },
        options:{
            plugins:{
                datalabels:{
                    formatter: (value, ctx) => {
                        let datasets = ctx.chart.data.datasets;
                        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                          return value.y;
                        } else {
                          return 0;
                        }
                      },
                      color: '#fff',
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    type: 'time',
                    time: {
                        unit: 'month',
                        round: 'month'
                      }
                }]
            }
        }
    }

    return (<Line {...config}/>)
}

export default TamizajeUso