import React, { useContext } from 'react'
import AsyncSelect from 'react-select/async';
import { REACT_SELECT_STYLES } from 'Contants';
import { API } from 'API';
import { SecurityCtx } from 'Context/Security';

const SelectPacientes = ({register={}}) => {

    const {user} = useContext(SecurityCtx)
    const {id_clinica:clinica_id} = user || {}

    const loadOptions= async (query)=>{
        try{
            const {data} = await API('/coordinador/paciente/?nombre='+query+'&clinica_id='+clinica_id)
            return data.results.map(p=>({value:p.id,label:p.primer_nombre_paciente+' '+p.primer_apellido_paciente}))
       }catch(error){
            return Promise.resolve([])
       }
       
    }

  return (
    <AsyncSelect {...register} placeholder='Buscar paciente' styles={REACT_SELECT_STYLES} loadOptions={loadOptions}/>
  )
}

export default SelectPacientes
