import classNames from "classnames"
import EyeChoice from "Components/EyeChoice"
import { PERMISO_AGREGAR_IMPRESION_MEDICA, PERMISO_MODIFICAR_IMPRESION_MEDICA } from "Contants"
import { SecurityCtx } from "Context/Security"
import { useContext } from "react"

const ImpresionMedica=({dado_de_alta,id_impresion,impresion,setImpresion})=>{

    const {dilatacion_vasos_iris ,enfermedad_plus ,hemorragia_vitrea } = impresion

    const {permissions} = useContext(SecurityCtx)
    let read_only = false;

   
    
    if(id_impresion && !permissions.includes(PERMISO_MODIFICAR_IMPRESION_MEDICA)){
        read_only=true
    }else if(!id_impresion && !permissions.includes(PERMISO_AGREGAR_IMPRESION_MEDICA)){
        read_only=true
    }
    if(dado_de_alta){
        read_only=true
    }

    return <div className='my-4'>
        <h3 className="text-xl mb-4 print:text-slate-700">Impresión diagnóstica</h3>
        <div className="flex lg:flex-row sm:flex-col justify-between">
        <div className="flex-1 lg:mr-2 sm:mb-2">
            <EyeChoice read_only={read_only} label="Dilatación vasos del iris" initial_value={dilatacion_vasos_iris} onChange={(v)=>setImpresion(prev=>({...prev,dilatacion_vasos_iris:v}))}/>
        </div>
        <div className="flex-1 lg:mr-2 sm:mb-2">
            <EyeChoice read_only={read_only} label="Hemorragia vitrea" initial_value={hemorragia_vitrea} onChange={(v)=>setImpresion(prev=>({...prev,hemorragia_vitrea:v}))}/>
        </div>
        <div className="flex-1">
            <EyeChoice read_only={read_only} label="Enfermedad plus" initial_value={enfermedad_plus} onChange={(v)=>setImpresion(prev=>({...prev,enfermedad_plus:v}))}/>
        </div>
        
    </div>
    </div>
}

export default ImpresionMedica