import React, { useState } from 'react'

const useModal = () => {

    const [modalVisble, setModalVisible] = useState(false)

    const openModal =()=>{
         setModalVisible(true)
    }

    const closeModal =()=>{
         setModalVisible(false)
    }

    const customStyles = {
         content: {
         
          backgroundColor:"#2f3855",
          opacity:20,
          borderRadius:40
          
         },
       };

  return {
        openModal,
        closeModal,
        modalVisble,
        setModalVisible,
        customStyles
  }
}

export default useModal
