import React from 'react'

const index = ({type='text',descripcion,placeholder,size,register={},desactivar}) => {
  return (
    <>
    
    <label className='text-sm mb-2 block'>{descripcion}</label>
      <input 
      type={type} {...register}
      placeholder={placeholder}
        className="rounded-md w-full border-[#2f3855] bg-[#2f3855]  py-[8px] px-[8px]"
    
        disabled={desactivar}
      />
    
    
    </>
  )
}

export default index
